<template>
  <div class="w-full text-white bg-white">
    <img
      src="@/assets/images/chargingPage/topBanner-min.png"
      class="w-px h-px opacity-0 fixed -z-10"
      alt=""
      ref="heroPic"
      @load="lhero = true"
    />
    <div
      v-if="!lhero"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen"
    >
      <div
        class="bg-[#1B1B1B] absolute load1 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div
        class="bg-[#1B1B1B] absolute load2 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div class="img1">
        <img
          src="@/assets/images/miniLogo.png"
          class="z-[1] zoom img1"
          alt="Logo"
        />
      </div>
    </div>
    <div v-else class="w-full">
      <section
        class="relative w-full max-h-screen overflow-hidden bak md:min-h-0 min-h-screen"
      >
        <img
          src="@/assets/images/chargingPage/topBanner-min.png"
          alt="Charging Station"
          class="w-full md:block hidden"
        />
      </section>
      <section class="w-full md:min-h-screen min-h-0 bg-white">
        <section
          class="w-full md:py-0 py-10 lg:py-[72px] container_fluid flex items-center"
        >
          <div class="max-w-xl w-full">
            <div class="w-full flex items-center md:flex-row flex-col">
              <div class="md:w-1/2 w-full">
                <h2
                  class="w-full font-belgiano font-medium md:text-[2.6rem] md:leading-[3rem] text-2xl text-black"
                >
                  Power up your electric vehicles at our flagship charge site
                </h2>
                <p class="lg:w-[66%] text-black mt-7">
                  <!-- Our charging station boasts a compact design, easily mountable
            indoors or outdoors, and is Wi-Fi-enabled for seamless over-the-air
            updates. Similar to the cutting-edge technologies embraced by Tesla
            and Lucid Motors, Qoray's charging station represents the pinnacle
            of efficiency and connectivity. -->
                  Visit: 300, Adeola Odeku Street, Victoria Island, Lagos,
                  Nigeria.
                </p>
                <button
                  class="black-btn font-inter mt-7 !py-4 tracking-[0.5px] leading-[16px]"
                  @click="scheduleCharge"
                >
                  Schedule Charge
                </button>
              </div>
              <div class="md:w-1/2">
                <img
                  src="@/assets/images/chargingPage/chg.png"
                  alt=""
                  class="w-full"
                />
              </div>
            </div>
          </div>
        </section>
      </section>

      <section class="w-full pb-10 lg:pb-[72px] bg-white">
        <img
          src="@/assets/images/chargingPage/adeolaOdeku-min.png"
          class="w-full opacity-100"
          alt=""
        />

        <div class="container_fluid">
          <!-- lg:center -->
          <div
            class="lg:w-[87%] mx-auto py-[20vh] lg:py-[72px] font-nunito-sans"
          >
            <h3
              class="font-medium text-2xl lg:text-3xl x:text-[42px] x:leading-[52.8px] font-belgiano mb-2 md:mb-4 text-black"
            >
              Charge with Confidence
            </h3>
            <p
              class="font-normal text-black text-sm md:text-base md:leading-[24px] font-inter xl:w-1/2 md:w-2/3 w-full"
            >
              Embark on your electric adventure knowing that the Qoray Charge
              Station delivers unrivaled performance. Our flagship station
              charges up to 100 miles of range in just 15 minutes, ensuring you
              spend less time waiting and more time exploring the open road.
            </p>
            <a href="#partner">
              <button
                class="black-btn font-inter mt-7 tracking-[0.5px] leading-[16px]"
              >
                Become a Qoray Charge Partner
              </button>
            </a>
          </div>
        </div>
      </section>

      <section class="w-full py-10 lg:py-[72px] container_fluid bg-white">
        <div class="grid w-full gap-4 grid-cols-2">
          <img
            src="@/assets/images/chargingPage/Picture-2-min.png"
            class="w-full opacity-100 col-span-2"
            alt=""
          />
          <img
            src="@/assets/images/chargingPage/Picture-min.png"
            class="w-full opacity-100"
            alt=""
          /><img
            src="@/assets/images/chargingPage/Picture-1-min.png"
            class="w-full opacity-100"
            alt=""
          />
          <!-- <img
          src="@/assets/images/chargingPage/Picture-3-min.png"
          class="w-full opacity-100"
          alt=""
        /><img
          src="@/assets/images/chargingPage/Picture-4-min.png"
          class="w-full opacity-100"
          alt=""
        /> -->
          <img
            src="@/assets/images/chargingPage/Picture-4-min.png"
            class="w-full opacity-100 col-span-2"
            alt=""
          />
        </div>
      </section>

      <section class="w-full py-10 lg:py-[72px] container_fluid bg-white">
        <div class="mx-auto max-w-sm md:max-w-[none]">
          <h3
            class="font-medium text-center text-2xl lg:text-3xl x:text-[42px] x:leading-[52.8px] font-belgiano w-full text-black"
          >
            We Empower Your Journey Beyond Limits
          </h3>
        </div>
      </section>
      <section class="w-full bg-white border-y border-y-[#555354] mb-12">
        <div
          class="mx-auto container_fluid max-w-sm md:max-w-[none] lg:w-[87%]"
        >
          <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 py-[8vh]"
          >
            <div class="space-y-2 text-center">
              <h6 class="text-xl leading-[28.8px] font-belgiano text-black">
                Rapid Charging
              </h6>
              <p
                class="text-black leading-[24px] font-inter font-light text-sm"
              >
                Experience unparalleled charging speed with the Qoray Charge
                Station, delivering up to 100 miles of range in just 15 minutes.
              </p>
            </div>
            <div class="space-y-2 text-center">
              <h6 class="text-xl text-black leading-[28.8px] font-belgiano">
                Compact Design
              </h6>
              <p
                class="font-light text-black leading-[24px] font-inter text-sm"
              >
                The Qoray Charge Station boasts a sleek and compact design,
                suitable for both indoor and outdoor charging.
              </p>
            </div>
            <div class="space-y-2 text-center">
              <h6 class="text-xl text-black leading-[28.8px] font-belgiano">
                Advanced Tech
              </h6>
              <p
                class="font-light text-black leading-[24px] font-inter text-sm"
              >
                Qoray ensures that your charging experience evolves with the
                latest advancements.
              </p>
            </div>
            <div class="space-y-2 text-center">
              <h6 class="text-xl text-black leading-[28.8px] font-belgiano">
                Sustainability Integration
              </h6>
              <p
                class="font-light text-black leading-[24px] font-inter text-sm"
              >
                Embrace an eco-friendly approach to electric mobility with our
                station, aligning with the commitment to environmental
                responsibility
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        class="w-full pt-[10vh] bg-form_bg bg-cover bg-center min-h-screen"
        id="partner"
      >
        <section class="py-12 lg:py-[92px] md:container_fluid">
          <div
            class="p-6 lg:p-[36px] border border-white/50 md:w-1/2 lg:w-[42%] bgr"
          >
            <h5
              class="x:leading-[43.2px] text-xl md:text-2xl x:text-4xl font-normal font-belgiano"
            >
              Become a Qoray <br />
              Charge Partner
            </h5>

            <form class="mt-4 md:mt-6">
              <input
                class="block placeholder:text-white w-full font-inter md:leading-[19.5px] font-light px-4 md:px-6 py-4 md:py-5 bg-[transparent] text-xs md:text-base mt-1.5 md:mt-2.5 border border-white/50"
                placeholder="Organization name"
                v-model="organization"
              />
              <p class="mt-1 !text-red text-xs">{{ errs.organization }}</p>
              <input
                class="block placeholder:text-white w-full font-inter md:leading-[19.5px] font-light px-4 md:px-6 py-4 md:py-5 bg-[transparent] text-xs md:text-base mt-1.5 md:mt-2.5 border border-white/50"
                placeholder="Contact name"
                v-model="name"
              />
              <p class="mt-1 !text-red text-xs">{{ errs.name }}</p>
              <input
                class="block placeholder:text-white w-full font-inter md:leading-[19.5px] font-light px-4 md:px-6 py-4 md:py-5 bg-[transparent] text-xs md:text-base mt-1.5 md:mt-2.5 border border-white/50"
                placeholder="Email address"
                type="email"
                v-model="email"
              />
              <p class="mt-1 !text-red text-xs">{{ errs.email }}</p>
              <input
                class="block placeholder:text-white w-full font-inter md:leading-[19.5px] font-light px-4 md:px-6 py-4 md:py-5 bg-[transparent] text-xs md:text-base mt-1.5 md:mt-2.5 border border-white/50"
                placeholder="Phone number"
                v-model="phone_number"
              />
              <p class="mt-1 !text-red text-xs">{{ errs.phone_number }}</p>
              <!-- 
            <select
              class="block w-full font-inter md:leading-[19.5px] font-light px-4 md:px-6 py-4 md:py-5 bg-[transparent] text-xs md:text-base mt-1.5 md:mt-2.5 border border-white/50"
            >
              <option class="text-black bg-white" value="Abia">CITY</option>
            </select> -->

              <button
                class="w-full mt-6 black-btn font-inter md:leading-[16px] tracking-[0.5px] rounded-none py-3 md:py-5"
                @click.prevent="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </section>
      </section>

      <section class="w-full pb-10 lg:pb-[72px]">
        <!-- <img
        src="@/assets/images/chargingPage/steling_qore_tab-min.png"
        class="w-full opacity-100"
        alt=""
      /> -->

        <div
          class="container_fluid mt-[47px] py-8 md:items-end flex-col flex md:flex-row md:justify-between text-left"
        >
          <div class="w-full">
            <h6
              class="text-2xl font-normal font-belgiano leading-[28.8px] text-black"
            >
              Join the Charging Revolution
            </h6>

            <button
              class="hidden md:block mt-3 black-btn font-inter tracking-[0.5px] md:leading-[16px]"
              @click="scheduleCharge"
            >
              Schedule Charge
            </button>
          </div>

          <p
            class="text-black md:leading-[24px] font-inter md:w-[71%] mt-4 md:mt-0"
          >
            Qoray is setting a new standard for charging infrastructure. Your
            journey begins at our flagship station, where power, convenience,
            and sustainability converge to redefine your electric adventure.
          </p>
          <div class="md:hidden block">
            <button
              class="md:hidden mt-6 black-btn font-inter tracking-[0.5px] w-auto md:leading-[16px]"
              @click="scheduleCharge"
            >
              Schedule Charge
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lhero: false,
      organization: "",
      name: "",
      email: "",
      phone_number: "",
      errs: {
        organization: "",
        name: "",
        email: "",
        phone_number: "",
      },
    };
  },
  computed: {
    payload() {
      return {
        organization: this.organization,
        name: this.name,
        email: this.email,
        phone_number: this.phone_number,
      };
    },
  },
  methods: {
    scheduleCharge() {
      this.$store.dispatch("scheduleNow");
    },
    success() {
      this.$store.dispatch("modalSuccess", {
        title: "Request Submitted",
        top: `Your submission has been received.`,
        bottom: "A sales rep will reach out to you in 72 hours",
      });
    },
    validateForm() {
      this.errs = {
        organization: "",
        name: "",
        email: "",
        phone_number: "",
      };
      var rege = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (!this.organization.length) {
        this.errs.organization = "Field is required";
        return false;
      } else if (this.name.length < 2) {
        this.errs.name = "Name is too short";
        return false;
      } else if (!this.email.length || !this.email.match(rege)) {
        this.errs.email = "Invalid Email";
        return false;
      } else if (this.phone_number.length < 10) {
        this.errs.phone_number = "Invalid Phone Number";
        return false;
      } else {
        return true;
      }
    },
    async submit() {
      if (this.validateForm() === true) {
        try {
          fetch(
            process.env.VUE_APP_API_URL + "/charge-partnerships/data.json",
            {
              method: "POST",
              body: JSON.stringify({ ...this.payload, created_at: new Date() }),
            }
          )
            .then((res) => res.json())
            .then(async (response) => {
              // console.log(response);
              var body = {
                form: "charge_partner",
                payload: {
                  organization: this.payload.organization,
                  user_name: this.payload.name,
                  phone: this.payload.phone_number,
                  email: this.payload.email,
                },
              };
              await this.$store.dispatch("sendMail", body);
              this.success();
            })
            .catch((err) => {
              alert("something went wrong, please try again");
            });
        } catch (error) {
          alert("something went wrong, please try again");
          console.log(error);
        }
      }
    },
  },
};
</script>

<style>
.bg1 {
  background-image: url("@/assets/images/chargingPage/adeolaOdeku-min.jpg");
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
}
.bgr {
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.05) 100%
  );
}
.bak {
  background-image: url("@/assets/images/chargingPage/topBanner-min.png");
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
}
</style>
