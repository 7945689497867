<template>
  <page-loader v-if="!lhero">
    <img
      src="@/assets/images/sh-bg.png"
      class="w-px h-px opacity-0 fixed -z-10"
      alt=""
      ref="heroPic"
      @load="lhero = true"
    />
  </page-loader>

  <div v-else class="w-full max-h-screen min-h-[100vh] bg-white">
    <div
      class="md:min-h-[85vh] min-h-screen md:max-h-[85vh] overflow-hidden bgg"
    >
      <div class="w-[92%] mx-auto pt-8 flex justify-start">
        <router-link :to="{ name: 'home' }">
          <img src="@/assets/svgs/logo.svg" class="w-[115px]" alt="" />
        </router-link>
      </div>
      <div class="w-[90%] max-w-xl mx-auto xl:mt-[12vh] lg:mt-[9vh] mt-[13vh]">
        <h1
          class="text-center w-full text-realBlack font-belgiano sm:text-5xl text-4xl font-medium"
        >
          Electrify Your <br class="sm:hidden block" />
          <span class="text-[#0A2C4D] font-semibold">Adventure!</span>
        </h1>
        <p class="text-realBlack text-center mt-3 font-inter">
          Unplug the Norm. Drive the Future.
        </p>
      </div>
      <sh-links class="md:hidden flex" />
      <!-- <img src="@/assets/images/sh-bg.png" class="w-full" alt="" /> -->
    </div>
    <div class="min-h-[15vh] max-h-[15vh] w-full hidden md:block">
      <sh-links class="md:flex hidden" />
    </div>
  </div>
</template>

<script>
import ShLinks from "@/components/ShLinks.vue";
import PageLoader from "@/components/PageLoader.vue";
export default {
  components: { ShLinks, PageLoader },
  data() {
    return {
      lhero: false,
    };
  },
};
</script>
    

<style lang="scss" scoped>
body {
  @apply bg-white;
}
.bgg {
  background-image: url("@/assets/images/sh-bg.png");
  background-size: cover;
  background-position: center 65%;
}
@media (max-width: 768px) {
  .bgg {
    background-position: center bottom;
  }
}
</style>