<template>
  <div class="w-full">
    <div
      class="w-full flex items-center justify-between gap-4 py-3 cursor-pointer"
      @click="toggle"
    >
      <h3 class="font-medium font-inter md:text-2xl text-base w-auto">
        {{ header }}
      </h3>
      <div
        class="md:min-w-[20px] md:max-w-[20px] min-w-[30px] max-w-[30px] cursor-pointer"
      >
        <div class="md:w-[20px] md:h-[20px] w-[30px] h-[30px] relative">
          <span
            class="inline-block movr w-full rounded-sm overflow-hidden"
            :class="[open ? 'rote' : '']"
          >
            <img src="@/assets/images/chevron.png" alt="Open" />
          </span>
          <!-- <span
            class="inline-block movr w-full h-[3px] bg-realBlack rounded-sm overflow-hidden absolute top-1/2 right-0"
            :class="[open ? 'opacity-0' : 'rot2']"
          ></span> -->
        </div>
        <!-- <img
          v-if="open"
          class="w-full"
          src="@/assets/images/faq/min.png"
          alt="-"
        />
        <img v-else class="w-full" src="@/assets/images/faq/plus.png" alt="+" /> -->
      </div>
    </div>
    <div class="w-full movr overflow-hidden font-inter" v-if="open">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ["header", "body", "htm"],
  data() {
    return {
      open: false,
      hgt: 0,
    };
  },
  methods: {
    toggle() {
      // if (this.open) {
      //   this.$refs.mvr.style.height = 0;
      // } else {
      //   this.$refs.mvr.style.height = this.hgt + 50 + "px";
      // }
      this.open = !this.open;
    },
  },
  mounted() {
    // this.hgt = this.$refs.mvr.offsetHeight;
    // this.$refs.mvr.style.height = 0;
  },
};
</script>

<style>
.movr {
  transition: all 0.5s ease-out;
}
ol {
  list-style-type: lower-roman !important;
  list-style: lower-roman !important;
  padding-left: 35px !important;
}
.rote {
  transform: rotate(-90deg);
}
</style>