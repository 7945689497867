<template>
  <div class="w-full">
    <section class="w-full bg-[#F6F6F7] pt-[7vh] md:pb-[14vh] pb-[8vh]">
      <div class="flex items-center justify-between w-[90%] mx-auto max-w-xl gap-4">
        <div class="w-5/12 md:block hidden">
          <img src="@/assets/images/sable.png" class="w-full" alt="" />
        </div>
        <div class="md:w-7/12 w-full">
          <h6 class="font-inter font-medium text-base tracking-[1.5px]">
            FAQS
          </h6>
          <h1
            class="md:text-6xl text-4xl font-semibold uppercase md:leading-[4.5rem] leading-[3rem] font-inter"
          >
            Got questions? <br />
            Let's help you.
          </h1>
          <p class="mt-6 font-inter">
            Everything you need to know about anything Qoray.
          </p>
        </div>
      </div>
    </section>
    <section class="w-full md:py-20 py-10">
      <div class="w-[90%] mx-auto max-w-xl">
        <h1
          class="md:text-6xl text-4xl leading-[3rem] font-normal md:leading-[4.5rem] font-inter mb-20"
        >
          Let's talk about Qoray
        </h1>
        <div class="w-full">
          <FAQDrop
            class="mb-12"
            v-for="(q, index) in faqs"
            :header="q.header"
            :body="q.body"
            :htm="q.htm"
            :key="index"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FAQDrop from "@/components/FAQDrop.vue";
export default {
  components: { FAQDrop },
  data() {
    return {
      faqs: [
        {
          header: "What is Qoray? ",
          body: "Qoray is a clean energy-powered mobility solution that offers quality, cost-effective, reliable products with the primary mission to deliver innovative solutions through communities andpartnerships.",
        },
        {
          header: "What are the products of Qoray? ",
          body: `  <ol>
                      <li>
                        <strong>Qoray Charging Station;</strong>
                        Electric Vehicle Supply Equipment (EVSE) is the basic unit of EV
                        charging infrastructure. The EVSE accesses power from the local
                        electricity supply and utilizes a control system and wired connection to
                        safely charge EVs.
                      </li>
                      <li>
                        <strong>Mobility as a service;</strong>
                        An E-mobility product for closed-user communities where the users only
                        pay for the service rendered.
                      </li>
                      <li>
                        <strong>Qoray Switch;</strong>
                        for converting vehicles with Internal Combustion Engine (ICE) into EVs.
                      </li>
                      <li>
                        <strong>Qoray Swap;</strong>
                        product that offer battery swapping services for 2 & 3 wheeled vehicles
                        like Tricycles and E-bikes.
                      </li>
                      <li>
                        <strong>Qoray Financing;</strong>
                        Financing solution for fleet procurement and EV purchase.
                      </li>
                    </ol>`,
          htm: true,
        },
        {
          header: "After I pre-order my EV when will it arrive?",
          body: "It will take a minimum of 60 days.",
        },
        {
          header: "Do I need to pay in full to own a Qoray EV?",
          body: "No. Qoray offers financing up to 60 months (5years), so, you pay in installments to own our vehicle ",
        },
        {
          header: "What is an EV Charging Station?",
          body: "It is a facility used to charge an EV to ensure that an appropriate and safe flow of electricity is supplied; just as Petrol cars have petrol stations.",
        },
        {
          header: "Where can I find a Qoray charging station?",
          body: "Our first Qoray charging station is in Adeola Odeku which is now fully functional. Subsequent roll-out of charging infrastructure across urban centers will be accelerated as more EVs are adopted",
        },
        {
          header: "Where can I find a Qoray swap station?",
          body: "Our first swap station is in Nasarawa Kano. Subsequent swap stations will be rolled out in the coming months.",
        },
        {
          header: "Does the Charge Station Charge Only Qoray vehicles?",
          body: "No, we designed our charging equipment in such a way that it can effectively charge other brands of EVs.",
        },
        {
          header: "Is it safe to charge?",
          body: `<i>If it's sunny or rainy, am I in danger of being electrocuted?</i> <br /> EV charging is completely safe, both in sunny and rainy conditions. Electricity only flows between the charger and the car once the cables are locked in place. However, it is important to use caution and ensure that the charging cables and connectors are kept dry during the charging process.`,
          htm: true,
        },
        {
          header: "What is our source of electricity?",
          body: "The energy source for our charge station is a power mix which includes renewable energy (our sister product Imperium).",
        },
        {
          header:
            "Do I have to stay with my car while it's charging? Can I pop off and do my",
          body: "shopping? Yes, your car would be safe while you pop off to do other things during the charging period. However, you should only leave your EV in the charging space for the time required to charge.",
        },
        {
          header: "How do I pay for charging?",
          body: "Charging operates on a pay-before-use basis. There are several ways to pay, using QR code or debit card. The price is the same for each payment method. The software interface is a self-service-system.",
        },
        {
          header: "How Long Does It Take To Charge An EV.",
          body: "The length of time to charge your EV depends on the state of charge of the battery, battery capacity and acceptance rate of the EV. Typically, it would take most EVs 1hr to charge to full capacity.",
        },
        {
          header: "So, do all EVs charge at the same rate?",
          body: "No, we understand that all EVs have different battery capacity and acceptance rate, and this would determine how fast each vehicle will be charged.",
        },
        {
          header: "What happens if my battery runs out of charge?",
          body: "Most electric vehicles will give you a warning when your battery is running low so you can find your way to the nearest charging station to recharge.",
        },
        {
          header: "What happens if I am in traffic?",
          body: "We understand that in a city like Lagos, traffic is unavoidable but be rest assured that the electric vehicle system is more energy efficient than the ICE vehicle. You are most unlikely to run out of charge if you've never run out of fuel.",
        },
        {
          header:
            "Do I have to charge for the full time, can I just top up for five minutes? Charging with Qoray is completely",
          body: "flexible, so customers can charge for the amount of time they require – there is no minimum charge time.",
        },
        {
          header: "Can I take my EV to the car wash?",
          body: "Electric vehicles can go through car washes without any issues, similar to ICE vehicles. However, it is advisable to check with the car wash facility to ensure they are equipped to handle electric vehicles, especially if the wash involves high-pressure water on specific areas like the charging port.",
        },
        {
          header: "Does the load put on the vehicle deplete the battery?",
          body: "EVs have a provision for load just as the Internal combustion engine vehicles (ICE) and so carrying the recommended amount of load is just fine.",
        },
        {
          header: "I heard EVs are Silent, is that safe?",
          body: "EVs are relatively quieter than ICE vehicles and so do not contribute to noise pollution but it is a misconception that they are silent as they produce noise when the tyres rub the road and there is the whistling noise with the wind. The noise difference is only noticeable at low speeds",
        },
        {
          header: "Can I save more money driving an EV?",
          body: "Yes, you can as EVs do not have many parts like the ICE vehicles that need regular servicing or changing and electricity is way cheaper than using fossil fuels.",
        },
        {
          header: "What are other advantages of driving an EV?",
          body: "Some advantages of EVs include lower operating costs, greater energy efficiency, reduced greenhouse gas emissions which promotes a sustainable future for us all, quieter operation, and potentially reduced dependence on fossil fuels. They also offer a smoother driving experience and require less maintenance compared to ICE vehicles.",
        },
        {
          header: "What is the driving range of EVs?",
          body: "The average EV can do 340km per charge...that means about 3 times the distance from Ikeja to Ibadan.",
        },
        {
          header: "What is the lifespan of an EV battery?",
          body: "On average an EV battery can last 8 years, but the lifespan depends on a number of factors like usage patterns, number of charging cycles and battery chemistry.",
        },
        {
          header: "Are electric vehicles safe to drive?",
          body: "Yes, they are. Electric vehicles undergo the same rigorous safety testing and standards as ICE vehicles. In fact, EVs often have additional safety features due to their design, such as a lower centre of gravity (due to battery placement) that improves stability.",
        },
        {
          header: "Does temperature or the weather affect my EV Battery?",
          body: "Yes, EVs operate favorably at an optimum temperature but provisions have been made with the battery management system to see to it that the temperature is controlled.",
        },
        {
          header: "Our e-mail and website, in case you want more information",
          body: `<a href="mailto:info@qoraymobility.com">info@qoraymobility.com</a>, <a href="https://Qoraymobility.com/">https://Qoraymobility.com/</a>.`,
          htm: true,
        },
      ],
    };
  },
};
</script>

<style>
</style>