<template>
  <div class="w-full md:py-[10vh] py-[10vh] *:font-inter">
    <div class="w-[90%] max-w-[68.3rem] mx-auto">
      <section>
        <h3 class="font-bold text-4xl no-underline mb-8">
          TERMS OF USE
        </h3>
        <p>
          Welcome to Qoray!
          <br />
          <br />
          The following Terms and Conditions (these "<strong>Terms</strong> "),
          govern your access to and use of the QORAY website and mobile
          application (the "<strong>Platform</strong>" or
          “<strong>QORAY</strong>”), powered by STERLING LIMITED with its
          registered office at 20, Marina, Lagos referred hereto as “we” or “us”
          “STERLING” and “you” or “User” means you as a user of the Platform.
          <br />
          <br />
          By accepting these Terms, you agree to be bound by these Terms. If you
          do not agree to these Terms, you will be unable to access all or
          certain parts or features of the Platform.
        </p>
      </section>
      <section>
        <h3 class="hdr">ABOUT QORAY</h3>

        <p>
          QORAY is a clean energy-powered mobility solution (the “service”) that
          offers quality, cost-effective, reliable products with the primary
          mission to deliver innovative solutions through communities and
          partnerships.
        </p>
      </section>
      <section>
        <h3 class="hdr">ELIGIBILITY</h3>

        <p>
          To access and use the QORAY platform on web or mobile, in any manner,
          you must be 18 years of age or older, or the age of majority as
          defined in your jurisdiction. By visiting and continuing to use QORAY,
          you represent to us that you have reached the age of majority in your
          jurisdiction and that you have the right, authority, and capacity to
          agree to and abide by these Terms. You also represent and warrant that
          you are not accessing QORAY for other purposes or gathering
          statistical data. If you are accessing the Platform on behalf of your
          business, you represent that you have the authorization of other
          partners of your business (if any), to access and operate the Platform
          on behalf of all partners.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">VERIFYING YOUR IDENTITY</h3>
        <p>
          You authorize us, directly or through third parties, to make any
          inquiries we consider necessary to validate your identity. You must,
          at our request:
        </p>
        <ul>
          <li>
            provide further information to us, which may include your date of
            birth and or other information that will allow us to reasonably
            identify you;
          </li>
          <li>
            take steps to confirm ownership of your email address or financial
            information; or
          </li>
          <li>
            verify your information against third-party databases or through
            other sources.
          </li>
        </ul>
        <p>
          We reserve the right to close, suspend, or limit access to your
          account, in the event we are unable to obtain or verify to our
          satisfaction the information which we request under this section.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">REGISTERING YOUR ACCOUNT</h3>
        <p>
          To fully access the QORAY site, Users have to register for an account,
          provide accurate and complete information, and keep their account
          information updated. If you create an account on behalf of a company,
          you represent and warrant that you are authorized to enter into
          binding contracts such as these Terms of Service, on behalf of
          yourself and the company.
        </p>

        <p>
          You must complete a user profile registration (“Profile”) before you
          can access the services or use the Platform. You agree to provide
          true, accurate, and complete information on your Profile and all
          registration and other forms you access on the Platform. You agree not
          to provide any false or misleading information about your identity or
          location, your business, your skills, or the services your business
          provides and to correct any such information that is or becomes false
          or misleading. To create an account, you will be required to provide
          your legally identified first name, legally identified last name, date
          of birth, email, phone number etc. To access certain features on the
          Platform you'd be required to provide your Bank Verification number
          (BVN) or other financial details. On the Platform, you will be asked
          to generate a Username, Password, Passcode for a wallet, transaction
          PIN, security questions etc.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">MANAGING YOUR ACCOUNT</h3>
        <p>
          We reserve the right to restrict your access, temporarily or
          indefinitely block your account, stop any projects you have in
          progress, and/or issue you a warning if:
        </p>
        <ul>
          <li>
            you breach the letter or spirit of these Terms and conditions or the
            referenced policies;
          </li>
          <li>
            we are unable to verify or authenticate any information you provide
            to us;
          </li>
          <li>
            we believe that your actions may cause any loss or liability to our
            Users or to us
          </li>
        </ul>
        <p>
          You are responsible for keeping your password secure. We cannot and
          will not be liable for any loss or damage from your failure to
          maintain the security of your Account and password. You acknowledge
          that you are responsible for the creation and operation of your QORAY
          account. You are responsible for all activity and content generated,
          stored, displayed, distributed, transmitted or exhibited on or in
          connection with your Account (“Materials”).
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">FORGOT PIN, PASSCODE</h3>

        <p>
          If you forget your app Passcode or log in details or want to change
          them, you can click on the 'forgot passcode' option on the login page
          of the Platform and follow the steps. To change your transaction PIN
          or if you suspect that your PIN has been compromised, you may need to
          contact us via our email below.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">IF SOMETHING GOES WRONG</h3>

        <p>
          Though we have taken extra precautions to ensure that all your use of
          the Platform and that the services or transactions are secure.
          However, we do not guarantee secure, continuous, uninterrupted access
          to any part of the QORAY services, software, or systems, including any
          networks and servers used to provide any of the services stated
          herein. Accordingly, we are not responsible for the matters, which
          include actions of hackers and other unauthorized third parties that
          breach our reasonable security procedure. No method of electronic
          transmission or storage is 100% secure. Therefore, we cannot guarantee
          absolute security of the Platform.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">IF SOMETHING GOES WRONG</h3>
        <p>
          The following services and more are available on QORAY. The Platform
          allows you to:
        </p>
        <ul>
          <li>Access vehicle catalogue and make requests</li>
          <li>Perform charge or swap transactions</li>
          <li>Monitor vehicle performance</li>
        </ul>
      </section>
      <section class="w-full">
        <h3 class="hdr">PROHIBITED USES OF THE PLATFORM</h3>

        <p>
          When using or accessing the Platform you must comply with these Terms
          and all applicable laws, guidelines and regulations. You shall not do
          any of the following:
        </p>
        <ul>
          <li>
            Gain or attempt to gain access to another User’s account or any
            non-public portions of the Platform,
          </li>
          <li>
            Use the Platform in any manner that could interfere with, disable,
            disrupt, overburden, or otherwise impair the Services on the
            Platform.
          </li>
          <li>
            Use the Services in any manner that we reasonably believe to be an
            abuse of or fraud on the Platform or the payment systems connected
            with the Platform.
          </li>
          <li>
            Upload, transmit, or distribute to or through the Services any
            viruses, worms, malicious code, or other software intended to
            interfere with the Services, including its security-related
            features.
          </li>
          <li>
            Use the Platform to violate applicable law or infringe any person’s
            or entity's intellectual property rights or any other proprietary
            rights.
          </li>
        </ul>
        <p>
          Access, search, or collect data from the Platform with intent to
          store, transfer or sell the personal data of other users without our
          prior consent.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">YOUR RESPONSIBILITY</h3>
        <p>
          We have compiled a list of things you need to do to help us keep your
          QORAY account safe.
        </p>
        <ul>
          <li>
            It is your sole responsibility to keep your QORAY account safe:
            Security Details, PIN and any part of your account security should
            not be shared with anybody else or carelessly stored or disclosed
            under any circumstance.
          </li>

          <li>
            If you leave your electronic access device without logging out,
            write your PIN down in a public space or share your details with
            anybody, we will not be liable for any errors, or results that may
            come of it.
          </li>

          <li>
            We will always use all reasonable efforts to keep the Platform and
            service safe, and your security settings will help us narrow it
            further down. However, if these issues arise due to carelessness
            with any part of your app or account security, there is very little
            we can do, and we will be relieved of any liability as regards the
            occurrence of a breach
          </li>

          <li>
            If you believe or reasonably suspect that there is a fraudulent act,
            we haven't caught (which should be easy since you get all
            notifications), it is your duty to notify us immediately. We will
            then investigate and report back to you through the most secure
            channel you provide us.
          </li>

          <li>
            You are required to change your security options if you believe any
            part of your security has been compromised.
          </li>

          <li>
            Where you notify us of your intention to change your password, PIN
            or security options, we will, with your approval, delete these
            details and allow you set up new ones.
          </li>

          <li>
            After initial registration, we will not contact you (or ask anyone
            to do so on our behalf) with a request to disclose your log in
            details or transaction password/PIN. If you receive any such request
            from anyone (even if they are using our name and logo and appear to
            be genuine) then it is likely to be fraudulent and you must not
            provide your log-in details to them under any circumstances. Also,
            you should report any such request to us immediately.
          </li>

          <li>
            Upon your registration or approval of certain services, you give us
            permission to deduct any fees we communicate or notify you on any
            transaction or service. If these fees change, you will be notified
            before consummating any transaction and given the option to opt out
            of this service.
          </li>

          <li>
            You undertake to provide all required personal information in a
            timely, complete and truthful manner. In particular, you will
            provide up-to-date information on all circumstances and restrictions
            affecting the performance of the services.
          </li>
        </ul>
      </section>
      <section class="w-full">
        <h3 class="hdr">PROHIBITIONS ON USE OF THE PLATFORM</h3>
        <p>
          Below are examples of the Platform that is illegal or prohibited. This
          list is not exhaustive, and we reserve the right to restrict access to
          and/or investigate any User who, in our sole discretion, violates any
          of the terms or conditions of these Terms.

          <br /><br />
          In view of the foregoing, you understand and agree not to:
        </p>
        <ul>
          <li>
            gain or attempt to gain access to another user’s account or any
            non-public portions of the Platform,
          </li>
          <li>
            use the Platform in any manner that could interfere with, disable,
            disrupt, overburden, or otherwise impair the Services on the
            Platform.
          </li>
          <li>
            use the Services in any manner that we reasonably believe to be an
            abuse of or fraud on the Platform or the payment systems connected
            with the Platform.
          </li>
          <li>
            upload, transmit, or distribute to or through the Services any
            viruses, worms, malicious code, or other software intended to
            interfere with the Services, including its security-related
            features.
          </li>
          <li>
            use the Platform to violate applicable law or infringe any person’s
            or entity's intellectual property rights or any other proprietary
            rights.
          </li>
          <li>
            access, search, or collect data from the Platform with intent to
            store, transfer or sell the personal data of other users without our
            prior consent.
          </li>
          <li>
            not to violate any law, regulation, industry requirement, or
            third-party guidelines or agreements by which you are bound,
            including those of payment card providers and transaction processors
            that are utilized in connection with the Products;
          </li>
          <li>
            not to interfere with or disrupt servers or networks connected to
            the Services, or disobey any requirements, procedures, policies or
            regulations of networks connected to our services;
          </li>
          <li>
            not to use another user's account or URL without permission,
            impersonate any person or entity, falsely state or otherwise
            misrepresent your affiliation with a person or entity;
          </li>
          <li>
            not to use the Platform in a way that could damage it or stop it
            from working or affect our systems or other users – for example, by
            hacking into or inserting malicious code into the Platform.
          </li>
        </ul>
        <p>Further, it is prohibited to:</p>
        <ul>
          <li>
            Use bots, crawlers, scrapers or other automated means (besides
            authorized use of our API) to access or interact with the Platform
            or generally collect data or content not in connection with your
            booking.
          </li>
          <li>
            Circumvent established security or technological barriers in place
            to protect the Platform and its content.
          </li>
          <li>
            Decrypt, decompile, reverse engineer or copy the website and
            Platform.
          </li>
          <li>
            Otherwise compromise or damage the Platform and its proper
            functioning.
          </li>
        </ul>
        <p>
          Any breach of these Terms may result in the immediate revocation of
          the access granted to you without prior notice. Should we determine at
          our sole discretion that you are in breach, we reserve the right to
          deny you access to the Platform and Services, and this is without
          prejudice to any available remedies at law or otherwise.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">MODIFICATIONS TO THESE TERMS AND CONDITIONS</h3>
        <p>
          We may change these Terms from time to time. This is solely at our
          discretion and changes will be effective when posted on the Platform
          with no other notice provided. Please check the Terms regularly for
          updates as changes shall be effective immediately, and we may indicate
          the date of such revision.
          <br /><br />
          In addition, we may change, suspend or discontinue any aspect of the
          Services at any time, including the availability of any feature, or
          content. We may also impose limits on certain features and services or
          restrict your access to parts or all of the Platform or and services
          without notice or liability.

          <br /><br />
          If you keep using the Platform after a change, you are deemed to have
          accepted the new terms.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">SUSPENSION OF YOUR QORAY ACCOUNT</h3>
        <p>
          We reserve the right to suspend your usage of the Platform or any of
          the Services immediately and without advance notice;
        </p>
        <ul>
          <li>In order to maintain security.</li>
          <li>You have breached the terms and conditions.</li>
          <li>You give us false information at any time.</li>
          <li>We suspect fraud or an attempt at fraud.</li>
          <li>If there is suspicious activity on your account</li>
          <li>
            You have not satisfied any anti-money laundering requirements.
          </li>
          <li>You are not eligible or entitled to download the Platform.</li>
          <li>You have broken the law or attempt to break the law.</li>
          <li>
            We receive notice of your mental incapacity, bankruptcy or death.
          </li>
          <li>
            There are system maintenance issues which need to be addressed
            promptly.
          </li>
          <li>New upgrades are being introduced to the Platform.</li>
          <li>
            Your mobile banking app is withdrawn by the manufacturer of your
            mobile device. operating system or if we stop supporting the
            Platform on your mobile device or operating system.
          </li>
          <li>
            If you stop holding any account, product or service in respect of
            which the Platform maybe used.
          </li>
          <li>We have legal obligations to do so.</li>
        </ul>
        <p>
          We will attempt to notify you, except it would compromise our security
          measures, or it is unlawful to do so, or impracticable within the
          circumstances.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">LIMITED LICENSE</h3>
        <p>
          The content and works (including design, text, graphics and the
          selection and arrangement) on the Platform are exclusively owned by
          Sterling. The duplication, processing, distribution and any kind of
          exploitation of the Platform’s content (to the extent not permitted by
          copyright laws) require our prior written consent. Any use of the
          Platform not expressly permitted by these Terms of Use and may violate
          our copyright, trademark and other laws. You must not use any part of
          the materials on the Platform for commercial purposes without
          obtaining a license to do so from us or our licensors.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">CLOSING YOUR ACCOUNT</h3>
        <p>
          You can deactivate or close your QORAY account any time you want. If
          you'd like to, please contact us (see how to contact us section). We
          will not charge you for cancellation and if you choose to stop using
          the Platform, you should ensure you uninstall the QORAY mobile
          application if you access the Platform via the mobile App.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">INTELLECTUAL PROPERTY AND CONTENT</h3>
        <ul>
          <li>
            We may in our sole discretion, permit you to post, upload, publish,
            submit or transmit text, graphics, images, video or other materials
            (“Content”) on the Platform. By making available any Content, you
            agree that QORAY may use the Content on the Platform, in particular
            for promotional materials and publications. You hereby grant to
            QORAY a worldwide, irrevocable, perpetual, non-exclusive,
            royalty-free license to publicly display or otherwise use the
            Content for the aforementioned purpose. QORAY does not claim any
            ownership rights in any such Content and nothing in these Terms will
            be deemed to restrict any rights that you may have to use and
            exploit the Content.
          </li>
          <li>
            You acknowledge and agree that you are solely responsible for all
            Content that you make available on the Platform. Accordingly, you
            represent and warrant that: (a) you either are the sole and
            exclusive owner of all Content or you have all rights, licenses,
            consents and releases that are necessary to grant to QORAY the
            rights in such Content, as contemplated under these terms and
            conditions; and (b) neither the Content nor your posting, uploading,
            publication, submission or transmittal of the Content or QORAY's use
            of the Content (or any portion thereof) on, through or by means of
            the Services will infringe, misappropriate or violate a third
            party's patent, copyright, trademark, trade secret, moral rights or
            other proprietary or intellectual property rights, or rights of
            publicity or privacy, or result in the violation of any applicable
            law or regulation.
          </li>
          <li>
            It is not allowed to upload or submit Content that (i) is illegal,
            pornographic, incites hate, obscene or defamatory; (ii) contains
            commercial content, especially advertisement or marketing for other
            good or services (iii) infringes intellectual property rights of
            others (iv) contains viruses, malware, trojan horses or other
            infested files. We are not obliged to check any Content ex-ante.
            Contents that violate these terms may be blocked or removed
            immediately without notice and we may suspend or terminate your use
            and access to the Platform.
          </li>
        </ul>
      </section>
      <section class="w-full">
        <h3 class="hdr">WARRANTIES & DISCLAIMERS</h3>
        <ul>
          <li>
            The Platform may be under constant upgrades, and some functions and
            features may not be fully operational during periods of upgrade or
            software maintenance.
          </li>

          <li>
            We disclaim any liability arising due to the vagaries that can occur
            in the electronic distribution of information.
          </li>

          <li>
            You acknowledge that third party services are available on the
            Platform. We may have formed partnerships or alliances with some of
            these third parties from time to time in order to facilitate the
            provision of certain services to you. However, you acknowledge and
            agree that at no time are we making any representation or warranty
            regarding any third party's services nor will we be liable to you or
            any third party for any consequences or claims arising from or in
            connection with such third party including, and not limited to, any
            liability or responsibility for, death, injury or impairment
            experienced by you or any third party. You hereby disclaim and waive
            any rights and claims you may have against us with respect to third
            party's services
          </li>

          <li>
            We do not, in any way, endorse any information or service offered or
            described on the Platform. In no event shall we be liable to you or
            any third party for any decision made, or action taken in reliance
            on such information.
          </li>

          <li>
            You assume all responsibility and risk with respect to your use of
            the Platform. The services are provided and available “as is,” and
            “as available”. You understand and agree that, to the fullest extent
            permitted by law, we disclaim all warranties, representations, and
            endorsements, express or implied, with regard to the Platform,
            including, without limitation, implied warranties of title,
            merchantability, non-infringement and fitness for a particular
            purpose.
          </li>

          <li>
            We do not warrant use of the website will be uninterrupted or
            error-free or that errors will be detected or corrected. We do not
            assume any liability or responsibility for any computer viruses,
            bugs, malicious code or other harmful components, delays,
            inaccuracies, errors or omissions, or the accuracy, completeness,
            reliability or usefulness of the information disclosed or accessed
            through the services.
          </li>

          <li>
            In no event, under no legal or equitable theory (whether tort,
            contract, strict liability or otherwise), shall we or any of our
            respective employees, directors, officers, agents or affiliates, be
            liable hereunder or otherwise for any loss or damage of any kind,
            direct or indirect, in connection with or arising from the use of
            the Platform / services or our agreement with you concerning the
            services, including, but not limited to, (i) the use of or inability
            to use the Platform, the service, or the content, (ii) any
            transaction conducted through or facilitated by the Platform; (iii)
            any claim attributable to errors, omissions, or other inaccuracies
            in the Platform, the service and/or the content, (iv) unauthorized
            access to or alteration of your transmissions or data, or (v) any
            other matter relating to the Platform, the service, or the content,
            and any compensatory, direct, consequential, incidental, indirect,
            special or punitive damages, lost anticipated profits, loss of
            goodwill, loss of data, business interruption, accuracy of results,
            or computer failure or malfunction, even if we have been advised of
            or should have known of the possibility of such damages.
          </li>
        </ul>
      </section>
      <section class="w-full">
        <h3 class="hdr">INVOLVEMENT WITH THIRD PARTIES</h3>
        <ol class="list-small-letter">
          <li>
            Links to Third Party Platforms. The Platform may allow access to
            other websites. These linked Platforms are not under our control,
            and we are not responsible for the contents of any linked Platform.
            QORAY may provide links only as a convenience, and such inclusion of
            any link does not imply endorsement by Us of the Platform or any
            part of its contents.
          </li>
          <li>
            Items of Third Parties. Some of our Services allow users to upload
            or route various information. You acknowledge that, except for Items
            clearly identified as being those of QORAY, we do not operate,
            control or endorse any Items listed on the Platform or processed by
            the Platform and have no responsibility whatsoever relating to these
            Items.
          </li>
        </ol>
      </section>
      <section class="w-full">
        <h3 class="hdr">SEVERABILITY</h3>
        <p>
          If any of these Terms should be determined to be illegal, invalid or
          otherwise unenforceable by reason of the laws of any state or country
          in which these Terms are intended to be effective, then to the extent
          and within the jurisdiction which that term is illegal, invalid or
          unenforceable, it shall be severed and deleted and the remaining of
          the Terms shall survive, remain in full force and effect and continue
          to be binding and enforceable.
        </p>
      </section>

      <!-- -------------------------------------------- -->
      <!-- -------------------------------------------- -->
      <!-- -------------------------------------------- -->
      <!-- -------------------------------------------- -->
      <!-- -------------------------------------------- -->
      <!-- -------------------------------------------- -->
      <!-- -------------------------------------------- -->
      <!-- -------------------------------------------- -->
      <!-- -------------------------------------------- -->
      <!-- -------------------------------------------- -->
      <section class="w-full">
        <h3 class="hdr upr">Waiver</h3>
        <p>
          No waiver by us of any term or condition set forth in these Terms
          shall be deemed a further or continuing waiver of such term or
          condition or a waiver of any other term or condition, and any failure
          by us to assert a right or provision under these Terms shall not
          constitute a waiver of such right or provision.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr upr">FORCE MAJEURE</h3>
        <p>
          To the fullest extent permitted under applicable law, we will be
          excused from performance under these Terms, for any period that we are
          prevented from or delayed in performing any obligations pursuant to
          this Terms, in whole or in part, as a result of a Force Majeure Event.
          For purposes of this section, “Force Majeure Event” means an event or
          series of events caused by or resulting from any of the following: (a)
          weather conditions or other elements of nature or acts of God; (b)
          acts of war, acts of terrorism, insurrection, riots, civil disorders
          or rebellion; (c) quarantines or embargoes, (d) labor strikes; (e);
          (f) unauthorized access to our information technology systems by third
          parties; or (g) other causes beyond our reasonable control and
          contemplation.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr upr">INDEMNITY</h3>
        <p>
          You agree to defend, indemnify, and hold harmless STERLING, its
          employees, officers, directors, agents, its affiliates (Indemnified
          Parties) from and against any and all claims, suits, liabilities,
          damages (actual and consequential), losses, fines, penalties, costs,
          and expenses (including reasonable attorneys’ fees) arising from or in
          any way related to your use of the Platform, violation of these Terms
          or any applicable law or any third-party rights, or any fraud or
          willful misconduct by you. Such Indemnified Parties reserve the right
          to assume the exclusive defense and control of any matter subject to
          indemnification from you, in which event you will cooperate in
          asserting any available defenses.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr upr">Dispute Resolution</h3>
        <p>
          We will use our best efforts to resolve any issue that may arise from
          the use of the Platform. However, we realize that there may be rare
          cases where we may not be able to resolve an issue to your
          satisfaction. In the event we cannot resolve a dispute between us, you
          agree that all matters related to any use or access to the Platform or
          the services shall be referred to any court of competent jurisdiction
          in Nigeria.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr upr">GOVERNING LAW</h3>
        <p>
          These Terms are governed by the laws of the Federal Republic of
          Nigeria.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr upr">COMPLAINTS OR FEEDBACK OR SUGGESTIONS</h3>
        <p>
          If you have a complaint, please contact us and we will fix the
          problem. You can also share your issues, feedback, inquiries or
          suggestions with us, by emailing us at
          <a href="mailto:info@qoraymobility.com">info@qoraymobility.com</a> and
          <a href="mailto:support@qoraymobility.com"
            >support@qoraymobility.com</a
          >
        </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p,
li {
  @apply text-base;
}
.hdr {
  @apply font-semibold underline text-lg mt-6;
}
.upr {
  @apply uppercase;
}
li {
  @apply mt-3;
}
ul {
  @apply pl-4 list-disc;
}
ol {
  @apply pl-4;
}
.list-small-letter {
  list-style-type: lower-alpha !important;
  list-style: lower-alpha !important;
}
</style>