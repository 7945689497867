<template>
  <div class="w-full">
    <section class="w-full relative overflow-hidden">
      <img
        src="@/assets/images/vehiclesPage/vehiclesBg.jpg"
        class="firstBg"
        ref="loadd"
        alt="BG"
      />
      <div
        class="absolute w-full h-full top-0 left-0 flex flex-col items-center justify-center bg-realBlack bg-opacity-35"
      >
        <div class="w-full">
          <div class="w-full flex flex-col-reverse">
            <div class="relative flex items-center justify-center mx-auto">
              <img
                ref="loadd1"
                src="@/assets/images/vehiclesPage/savannah.png"
                class="sav"
                alt=""
              />
            </div>
            <h1
              class="relative text-[3.5rem] text-white font-bold text-center font-inter"
            >
              Unleash the Power of Nature<span
                class="text-red absolute -right-15"
                >.</span
              ><br />
              Qoray
              <span class="font-cocon"
                >Savannah<span class="text-red absolute -right-15"
                  >.</span
                ></span
              >
            </h1>
          </div>
          <div
            class="flex items-center justify-between max-w-xl mx-auto w-[90%] gap-4"
          >
            <div class="w-2/5">
              <div class="w-full flex items-stretch justify-between">
                <div class="text-center text-white">
                  <p class="font-plus text-xs uppercase tracking-[1.25px]">
                    EPA Est. range up to
                  </p>
                  <p class="font-plus text-2xl mt-1">
                    516 mi <sup class="text-xs">2</sup>
                  </p>
                  <p class="font-plus text-xs mt-2">varies by trim</p>
                </div>
                <div class="border-l border-l-white"></div>
                <div class="text-center text-white">
                  <p class="font-plus text-xs uppercase tracking-[1.25px]">
                    CHARGE TO 200 MILES
                  </p>
                  <p class="font-plus text-2xl mt-1">
                    12 min <sup class="text-xs">3</sup>
                  </p>
                  <p class="font-plus text-xs mt-2">varies by trim</p>
                </div>
              </div>
            </div>
            <div class="w-1/5 flex items-center justify-center">
              <router-link :to="{ name: 'home' }">
                <button class="sec-btn font-plus font-normal text-black">
                  Order Now
                </button>
              </router-link>
            </div>
            <div class="w-2/5">
              <div class="w-full flex items-stretch justify-between">
                <div class="text-center text-white">
                  <p class="font-plus text-xs uppercase tracking-[1.25px]">
                    MAX POWER
                  </p>
                  <p class="font-plus text-2xl mt-1">
                    1,234 hp<sup class="text-xs">4</sup>
                  </p>
                  <p class="font-plus text-xs mt-2">varies by trim</p>
                </div>
                <div class="border-l border-l-white"></div>
                <div class="text-center text-white">
                  <p class="font-plus text-xs uppercase tracking-[1.25px]">
                    0 - 60 MPH
                  </p>
                  <p class="font-plus text-2xl mt-1">
                    1.89 secs <sup class="text-xs">4</sup>
                  </p>
                  <p class="font-plus text-xs mt-2">varies by trim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="w-full bg-white pt-20 pb-[5.5rem]">
      <div class="w-[90%] mx-auto max-w-xl">
        <p class="w-3/4 text-4xl font-plus">
          Rediscover the thrill of the great outdoors with the Qoray Savannah.
          Its robust design and cutting-edge technology redefine off-road
          adventures.
        </p>
      </div>
    </section>
    <section class="w-full intr">
      <img
        src="@/assets/images/vehiclesPage/savannahInt.jpg"
        alt=""
        class="w-full opacity-0"
      />
    </section>
    <section class="w-full bg-white pt-20 pb-[5.5rem]">
      <div class="mx-auto max-w-xl w-[90%] grid grid-cols-2 gap-4">
        <img
          src="@/assets/images/vehiclesPage/savannahShow1.jpg"
          alt=""
          class="w-full col-span-2"
        />
        <img
          src="@/assets/images/vehiclesPage/savannahShow2.jpg"
          alt=""
          class="w-full"
        />
        <img
          src="@/assets/images/vehiclesPage/savannahShow2.jpg"
          alt=""
          class="w-full -scale-x-100"
        />
      </div>
      <div class="max-w-xl w-[90%] mx-auto mt-24">
        <h3
          class="font-inter font-bold text-5xl md:w-[95%] w-full text-left capitalize mb-8"
        >
          Luxury Beyond Limits: <br />Indulge in next-level luxury and spacious
          comfort.
        </h3>
        <div class="w-full">
          <img
            src="@/assets/images/vehiclesPage/anotherSavannahInt.png"
            alt=""
          />
          <p class="font-inter font-bold text-2xl mt-6 mb-1">Spacious Cabin</p>
          <p class="font-plus">
            With ample legroom, everyone enjoys first-class comfort.
          </p>
        </div>
        <h4
          class="font-inter font-bold text-4xl md:w-[95%] w-full text-left capitalize mb-8 mt-[5.5rem]"
        >
          Empower Your Journey: <br />
          Go the distance with efficient charging solutions.
        </h4>
        <div class="w-full flex justify-end pb-[5.5rem]">
          <ul class="font-plus text-base w-8/12">
            <li>
              <span class="font-inter font-bold">Long-Range Capability:</span>
              With a range of up to 300 miles, take on extended off-road
              expeditions.
            </li>
            <li>
              <span class="font-inter font-bold">Qoray Charge Station:</span>
              Charges up to 100 miles of range in just 15 minutes. Compact,
              mountable indoors or outdoors, and Wi-Fi enabled.
            </li>
          </ul>
        </div>
      </div>
      <div class="w-full secSta">
        <img
          src="@/assets/images/vehiclesPage/chargeStation.jpg"
          alt=""
          class="w-full opacity-0"
        />
      </div>
      <div class="mx-auto max-w-xl w-[90%] pt-20">
        <h3
          class="font-inter font-bold text-5xl md:w-[95%] w-full text-left capitalize mb-8"
        >
          Adventure Meets Convenience: <br />
          Stay connected seamlessly with advanced features.
        </h3>
        <div class="w-full">
          <div class="w-full flex items-center justify-center">
            <img src="@/assets/images/vehiclesPage/mobileApp.png" alt="" />
          </div>
          <p class="font-inter font-bold text-2xl mt-6 mb-1">Qoray Mobile App</p>
          <p class="font-plus">
            Pair your vehicle with the digital convenience of your phone for
            ultimate control.
          </p>
        </div>
        <h3
          class="font-inter mt-20 font-bold text-5xl md:w-[95%] w-full text-left capitalize mb-8"
        >
          Shop other <br />
          Qoray Mobility products
        </h3>
        <div class="grid grid-cols-4">
          <div>
            <img src="@/assets/images/nav/seal.png" class="mx-auto" alt="" />
            <p class="text-center text-2xl font-plus tracking-[-0.24px]">
              Qoray Seal
            </p>
            <div
              class="w-full font-plus items-center justify-center flex-col flex gap-4 mt-5"
            >
              <button class="tiny upper red-btn">Order</button>
              <button class="tiny upper trans-btn text-black border-2">Explore</button>
            </div>
          </div>
          <div>
            <img src="@/assets/images/nav/atlas.png" class="mx-auto" alt="" />
            <p class="text-center text-2xl font-plus tracking-[-0.24px]">
              Qoray Atlas
            </p>
            <div
              class="w-full font-plus items-center justify-center flex-col flex gap-4 mt-5"
            >
              <button class="tiny upper red-btn">Order</button>
              <button class="tiny upper trans-btn text-black border-2">Explore</button>
            </div>
          </div>
          <div>
            <img src="@/assets/images/nav/seal.png" class="mx-auto" alt="" />
            <p class="text-center text-2xl font-plus tracking-[-0.24px]">
              Qoray Pika
            </p>
            <div
              class="w-full font-plus items-center justify-center flex-col flex gap-4 mt-5"
            >
              <button class="tiny upper red-btn">Order</button>
              <button class="tiny upper trans-btn text-black border-2">Explore</button>
            </div>
          </div>
          <div>
            <img src="@/assets/images/nav/panther.png" class="mx-auto" alt="" />
            <p class="text-center text-2xl font-plus tracking-[-0.24px]">
              Qoray Javan
            </p>
            <div
              class="w-full font-plus items-center justify-center flex-col flex gap-4 mt-5"
            >
              <button class="tiny upper red-btn">Order</button>
              <button class="tiny upper trans-btn text-black border-2">Explore</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "vehicles",
  components: { Loading },
  data() {
    return {
      l1: false,
      l2: false,
    };
  },
  methods: {
    animate() {
      gsap.to(".firstBg", {
        scale: 1,
        duration: 2.5,
        ease: "power4.out",
      });
      gsap.to(".sav", {
        y: 0,
        scale: 1,
        duration: 2.5,
        ease: "power4.out",
      });
    },
  },
  computed: {
    loaded() {
      return this.l1 && this.l2;
    },
  },
  watch: {
    loaded() {
      this.animate();
    },
  },
  mounted() {
    gsap.set(".firstBg", {
      scale: 1.6,
    });
    gsap.set(".sav", {
      scale: 0.6,
      y: "-80vh",
    });
    this.$refs.loadd.addEventListener(
      "load",
      () => {
        this.l1 = true;
      },
      false
    );
    this.$refs.loadd1.addEventListener(
      "load",
      () => {
        this.l2 = true;
      },
      false
    );
  },
};
</script>

<style>
.intr {
  background-image: url("@/assets/images/vehiclesPage/savannahInt.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;
}
.secSta {
  background-image: url("@/assets/images/vehiclesPage/chargeStation.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}
</style>