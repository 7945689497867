<template>
  <div class="w-full">
    <!-- PRELOAD -->

    <!-- v-else -->
    <img
      src="@/assets/images/savannah/hero.png"
      class="fixed w-px h-px opacity-0 -z-10"
      alt=""
      ref="heroPic"
      @load="lhero = true"
    />
    <img
      class="fixed w-px h-px opacity-0 -z-10"
      v-for="(col, index) in spectrum"
      :key="index"
      :src="col.car"
      alt=""
    />
    <!-- PRELOAD -->
    <div
      v-if="!lhero"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen"
    >
      <div
        class="bg-[#1B1B1B] absolute load1 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div
        class="bg-[#1B1B1B] absolute load2 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div class="img1">
        <img
          src="@/assets/images/miniLogo.png"
          class="z-[1] zoom img1"
          alt="Logo"
        />
      </div>
    </div>
    <div class="w-full">
      <section
        class="relative w-full heroBG md:min-h-0 min-h-screen md:max-h-none max-h-screen"
      >
        <img
          src="@/assets/images/savannah/hero.png"
          class="w-full md:block hidden"
          alt=""
        />
        <div class="absolute top-0 right-0 left-0">
          <div
            class="min-h-screen max-h-none md:w-[90%] w-[95%] max-w-xl mx-auto h-full flex flex-col items-start justify-between md:pt-[20vh] md:pl-10 pt-[15vh] pb-[10vh]"
          >
            <!-- <div class="hidden w-full md:block"></div> -->
            <div class="w-full">
              <h1 class="font-belgiano md:text-[80px] text-[36px]">
                Savannah<span class="text-green">.</span>
              </h1>
              <p class="text-xs font-inter md:text-2xl">
                Starting from ₦64 million
              </p>
              <div class="flex items-center gap-6 mt-3">
                <p class="text-xs font-inter md:text-lg">10% down payment</p>
                <div class="w-px h-4 bg-realBlack"></div>
                <p class="text-xs font-inter md:text-lg">Spread over 5 years</p>
              </div>
              <router-link :to="{ name: 'order-savannah' }">
                <button
                  class="black-btn w-[clamp(9.375rem,5.869vw+7.174rem,15.625rem)] mt-7"
                >
                  Order Now
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack">
        <div
          class="flex flex-wrap justify-between items-center md:w-[90%] w-[95%] max-w-xl mx-auto py-20 md:gap-4 gap-y-6 md:flex-nowrap"
        >
          <div class="text-center md:w-1/4 w-[50%] text-white">
            <p class="font-inter text-xs uppercase tracking-[1.25px]">
              Dimension
            </p>
            <p class="mt-1 text-sm font-inter md:text-xl">
              L:4770x W:1900 x H:1660
            </p>
          </div>
          <div class="w-px h-4 md:block hidden bg-white bg-opacity-40"></div>
          <div class="text-center md:w-1/4 w-[45%] text-white">
            <p class="font-inter text-xs uppercase tracking-[1.25px]">
              Top Speed
            </p>
            <p class="mt-1 text-sm font-inter md:text-xl">180km/H</p>
          </div>
          <div class="w-px h-4 md:block hidden bg-white bg-opacity-40"></div>
          <div class="text-center md:w-1/4 w-[45%] text-white">
            <p class="font-inter text-xs uppercase tracking-[1.25px]">Range</p>
            <p class="mt-1 text-sm font-inter md:text-xl">460km</p>
          </div>
          <div class="w-px h-4 md:block hidden bg-white bg-opacity-40"></div>
          <div class="text-center md:w-1/4 w-[45%] text-white">
            <p class="font-inter text-xs uppercase tracking-[1.25px]">
              Battery
            </p>
            <p class="mt-1 text-sm font-inter md:text-xl">68(KWh)</p>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack md:py-20 py-5 block">
        <div class="md:w-[90%] w-[97.5%] max-w-xl md:mx-auto ml-auto">
          <div
            class="md:w-[90%] w-full mx-auto flex flex-col-reverse items-stretch md:gap-11 gap-7"
          >
            <div class="w-full overflow-x-auto">
              <img
                src="@/assets/images/savannah/pilotView.png"
                class="md:w-full w-[200%] md:min-w-0 min-w-[200%]"
                alt=""
              />
            </div>
            <div class="relative md:w-1/2">
              <div class="">
                <div class="relative left-0 right-0 w-full text-white">
                  <h3
                    class="capitalize font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px]"
                  >
                    Intelligent Assist Technology
                  </h3>
                  <p class="tracking-[1.8px] mt-3 md:text-base text-xs">
                    Say hello to a safer, hands-free driving experience with the
                    Savannah's advanced full digital system, featuring automatic
                    parking assist, full voice control, and intelligent door
                    opening collision warning and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack md:py-20 py-5 block">
        <div class="md:w-[90%] w-[97.5%] max-w-xl md:mx-auto ml-auto">
          <div
            class="md:w-[90%] w-full mx-auto flex flex-col items-stretch md:gap-11 gap-7"
          >
            <div class="w-full overflow-x-auto">
              <img
                src="@/assets/images/savannah/cockpit.png"
                class="md:w-full w-[200%] md:min-w-0 min-w-[200%]"
                alt=""
              />
            </div>
            <div class="relative md:w-1/2">
              <div class="">
                <div class="relative left-0 right-0 w-full text-white">
                  <h3
                    class="capitalize font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px]"
                  >
                    In-built Entertainment System
                  </h3>
                  <p class="tracking-[1.8px] mt-3 md:text-base text-xs">
                    Get mesmerized by Savannah's in-built entertainment system,
                    featuring a stunning dual-screen display, immersive audio,
                    and a comprehensive ecosystem that lets you watch, listen,
                    sing, and play
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack md:py-20 pt-11 pb-9">
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="md:w-[95%] w-full mx-auto">
            <div class="flex items-stretch md:flex-row flex-col w-full">
              <div class="md:w-1/2 w-full">
                <img
                  src="@/assets/images/savannah/seats.png"
                  alt=""
                  class="w-full"
                />
              </div>
              <div class="md:w-1/2 w-full">
                <img
                  src="@/assets/images/savannah/topView.png"
                  alt=""
                  class="w-full"
                />
              </div>
            </div>
            <div class="md:w-full w-[95%] mx-auto">
              <div
                class="md:w-[80%] w-full mx-auto max-w-xl flex md:flex-row flex-col items-start md:gap-8 md:py-8 pt-7"
              >
                <h3
                  class="capitalize text-white font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:w-2/12 w-full"
                >
                  Sound Immersion
                </h3>
                <p
                  class="md:text-base text-xs tracking-[1.8px] text-white md:w-10/12 w-full mt-3 md:mt-0"
                >
                  Enjoy Savannah's sky sound surround system, featuring
                  independent headrest audio for both driver and passenger,
                  creating a personalized audio sanctuary that envelops you in
                  pure sonic bliss.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full relative bg-realBlack">
        <img
          class="w-full"
          src="@/assets/images/savannah/trunkView.png"
          alt=""
        />
        <div class="absolute md:bottom-20 bottom-3 left-0 w-full">
          <div class="md:w-[90%] w-[95%] max-w-xl mx-auto">
            <div class="md:w-4/5 w-full mx-auto">
              <h3
                class="capitalize text-white font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px]"
              >
                Transform Your Journey into a Luxurious Retreat
              </h3>
              <p
                class="md:text-base text-xs tracking-[1.8px] mt-3 text-white md:block hidden"
              >
                With Savannah's innovative rear seat design, effortlessly fold
                down the seats to create a spacious king-size bed, converting
                your vehicle into a cozy bedroom sanctuary, perfect for restful
                getaways or impromptu naps
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack relative">
        <div class="w-[95%] max-w-xl mx-auto md:hidden block pb-12">
          <p class="md:text-base text-xs tracking-[1.8px] text-white">
            With Savannah's innovative rear seat design, effortlessly fold down
            the seats to create a spacious king-size bed, converting your
            vehicle into a cozy bedroom sanctuary, perfect for restful getaways
            or impromptu naps
          </p>
        </div>
      </section>
      <section class="bg-white md:py-20 md:pt-9">
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="md:w-[95%] w-full mx-auto relative">
            <img
              class="w-full md:block hidden"
              src="@/assets/images/savannah/backSideView.png"
              alt=""
            />
            <img
              class="w-full md:hidden block"
              src="@/assets/images/savannah/backSideViewMob.png"
              alt=""
            />
            <div class="md:absolute md:bottom-40 left-0 w-full md:pt-0 pt-4">
              <div class="md:w-[90%] w-[95%] max-w-xl mx-auto">
                <div class="md:pl-8 md:w-1/3 w-full">
                  <h3
                    class="capitalize md:text-white text-realBlack md:text-center text-left font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px]"
                  >
                    Drive for Nature
                  </h3>
                  <p
                    class="md:text-base text-xs tracking-[1.8px] mt-3 md:text-white text-realBlack w-full"
                  >
                    Meet Savannah, the electric car inspired by the majestic
                    grasslands that once roamed free. We named our car after
                    this fragile ecosystem to honor its beauty and raise
                    awareness about its decline. Just as the savannah supports
                    diverse wildlife, our car is designed to support a
                    sustainable future. With every drive, we're one step closer
                    to preserving the balance of nature.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-white md:py-20 pt-9">
        <div class="w-full max-w-[1550px] mx-auto">
          <div
            class="md:w-[95%] w-full mx-auto flex flex-col-reverse md:flex-row items-stretch md:gap-6 gap-7"
          >
            <div class="relative w-full md:w-2/5">
              <div>
                <div class="md:w-full w-[95%] mx-auto md:px-8 md:mt-[120px]">
                  <h3
                    class="capitalize text-realBlack font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:mt-4"
                  >
                    Extended Range SUV
                  </h3>
                  <p
                    class="md:text-base text-xs tracking-[1.8px] mt-3 text-realBlack"
                  >
                    With its powerful electric motor and advanced battery
                    technology, you can venture off the beaten path without
                    worrying about running out of juice. Enjoy the thrill of the
                    open road, and the peace of mind that comes with knowing
                    you're driving a sustainable future.
                  </p>
                </div>
              </div>
            </div>
            <img
              src="@/assets/images/savannah/droneView.png"
              class="w-full md:w-2/3"
              alt=""
            />
          </div>
        </div>
      </section>
      <section class="w-[95%] max-w-[1550px] mx-auto md:pt-0 pt-8">
        <div class="w-full max-w-xl mx-auto md:pl-8">
          <h2 class="capitalize font-belgiano text-[1rem] md:text-[56px]">
            Go The Distance
          </h2>
        </div>
        <img
          src="@/assets/images/savannah/distance.png"
          alt=""
          class="w-full mt-4 md:mt-8"
        />
        <div class="w-full max-w-xl mx-auto md:pl-8">
          <p
            class="md:text-base text-xs tracking-[1.8px] md:mt-9 mt-4 md:w-2/3 w-full"
          >
            With a single charge, it can travel up to 500 km, effortlessly
            covering long routes like Lagos to Ilorin, Kwara State (301 km), and
            still having plenty of juice left to spare. Experience the freedom
            to go further, without worrying about running out of charge.
          </p>
        </div>
      </section>

      <section class="w-[95%] max-w-[1550px] mx-auto md:pt-[120px] pt-9">
        <div class="w-full max-w-xl md:pl-8">
          <h2 class="capitalize font-belgiano text-[1rem] md:text-[56px]">
            View Our Spectrum
          </h2>
        </div>
      </section>
      <section class="relative w-full overflow-hidden" ref="swipeArea">
        <div
          class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center z-[-1]"
        >
          <!-- <img
            src="@/assets/svgs/greyLogo.svg"
            class="w-full opacity-5"
            alt="Qoray"
          /> -->
          <h1
            class="w-full text-newGrey md:text-9xl text-7xl scale-125 font-belgiano font-black text-center md:scale-[2.5] opacity-5"
          >
            SAVANNAH
          </h1>
        </div>
        <div
          class="md:w-full w-[95%] mx-auto flex items-center justify-center mt-20 switchCont"
        >
          <img
            :src="spectrum[activeSpectrum].car"
            class="w-full mx-auto md:w-1/2"
            alt=""
          />
        </div>
      </section>
      <section class="w-[95%] max-w-[1550px] mx-auto md:pb-[120px] pb-9">
        <div class="w-[80%] mx-auto max-w-xl mt-9">
          <div class="flex items-center justify-center w-full">
            <div>
              <p class="text-sm text-newGrey opacity-65">Available colors</p>
              <div class="flex items-center w-full gap-3 mt-1">
                <img
                  class="w-10 h-10 overflow-hidden rounded-full cursor-pointer"
                  :class="[
                    activeSpectrum === index ? 'border-[3px] border-green' : '',
                  ]"
                  v-for="(col, index) in spectrum"
                  :key="index"
                  :src="col.icon"
                  @click="changeCarTo(index)"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-[95%] max-w-[1550px] mx-auto py-[60px]">
        <div class="md:w-[80%] w-full mx-auto max-w-xl">
          <div class="w-full">
            <div class="w-full">
              <h6 class="text-lg font-belgiano md:text-2xl mb-2">Specs</h6>
              <div
                class="flex md:grid md:grid-cols-3 items-start flex-wrap w-full justify-between gap-y-4 md:gap-8"
              >
                <div
                  v-for="spec in showAll ? specs : specs.slice(0, 6)"
                  :key="spec.title"
                  class="w-fit md:min-w-fit min-w-[50%] px-2 py-1 md:px-5 md:py-4"
                >
                  <p
                    class="w-full text-sm text-newGrey whitespace-nowrap opacity-65"
                  >
                    {{ spec.title }}
                  </p>
                  <p class="w-full whitespace-nowrap text-realBlack">
                    <!-- <br /> -->
                    {{ spec.value }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full" v-if="showAll">
              <div
                class="w-full mt-8"
                v-for="other in otherSpecs"
                :key="other.title"
              >
                <h6 class="text-lg font-belgiano md:text-2xl mb-2">
                  {{ other.title }}
                </h6>
                <div
                  class="flex flex-wrap items-start w-full gap-4 pl-2 mt-2 md:px-7 md:pl-0 md:gap-8"
                >
                  <div
                    class="vehSpec"
                    v-for="otherValue in other.values"
                    :key="otherValue"
                  >
                    <span class="bigDot"></span>
                    <p>{{ otherValue }}</p>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <div class="px-2 py-4 mt-3">
                  <p class="text-lg">
                    <span class="text-sm text-newGrey opacity-65"
                      >Warranty</span
                    >
                    <br />
                    36 Months
                  </p>
                </div>
                <div class="w-full mt-3">
                  <div
                    class="inline-block py-2 pl-3 pr-4 bg-green bg-opacity-10"
                  >
                    <p class="font-inter leading-[26px] text-green">
                      Home charging available
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full">
              <button
                v-if="!showAll"
                @click="showAll = true"
                class="black-btn long mt-7"
              >
                See More
              </button>
              <div class="flex items-center justify-between w-full" v-else>
                <router-link :to="{ name: 'order-savannah' }">
                  <button class="black-btn long mt-7">Order Now</button>
                </router-link>
                <button
                  class="white-btn long mt-7 black-border"
                  @click="showAll = false"
                >
                  See Less
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import col1 from "@/assets/images/savannah/colors/night_black.png";
import col2 from "@/assets/images/savannah/colors/cornet_white.png";
import col3 from "@/assets/images/savannah/colors/particle_blue.png";
import col4 from "@/assets/images/savannah/colors/dust-free_silver.png";
import car1 from "@/assets/images/savannah/colors/black.png";
import car2 from "@/assets/images/savannah/colors/white.png";
import car3 from "@/assets/images/savannah/colors/blue.png";
import car4 from "@/assets/images/savannah/colors/silver.png";
export default {
  data() {
    return {
      lhero: false,
      showAll: false,
      swipe: {
        touchstartX: 0,
        touchendX: 0,
      },
      specs: [
        { value: "180Km/H", title: "Top Speed" },
        { value: "460Km", title: "Range" },
        { value: "68kwh Lithium Ion", title: "Battery" },
        { value: "5-door 5-seat  SUV", title: "Body Structure" },
        { value: "4770", title: "Length(mm)" },
        { value: "1900", title: "Width(mm)" },
        { value: "1660", title: "Height (mm)" },
        { value: "2810", title: "Wheelbase(mm)" },
        { value: "≥140", title: "Full Load  Ground Clearance (mm)" },
        { value: "≤1935", title: "Curb Weight (kg)" },
        { value: "≤10.8", title: "Minimum turning diameter (m)" },
        { value: "460", title: "NEDC EV Range（km）" },
        { value: "CATL", title: "Battery Brand" },
        { value: "Lithium Iron Phosphate", title: "Battery" },
        { value: "68.09", title: "Battery capacity" },
        { value: "20 minutes", title: "DC Charging Time (30%-80%)" },
        { value: "Permanent magnet synchronous", title: "Motor Type" },
        { value: "170", title: "Rear Motor Torque (N·m)" },
        { value: "210", title: "Maximum Torque (N·m)" },
        { value: "7.8", title: "Acceleration Time (s) (0-100km/h)" },
        { value: "40", title: "Braking Distance (m) (100-0km/h)" },
        { value: "RWD", title: "Drive Type" },
        { value: "Mcpherson", title: "Front Suspension" },
        { value: "Multi-link", title: "Rear Suspension" },
        { value: "R19", title: "Tire Specifications" },
        { value: "Ventilated disc", title: "Front Brake Type" },
        { value: "Solid disc", title: "Rear Brake Type" },
        { value: "Standard/Sport/Comfort", title: "Steering Assist Modes" },
        { value: "ECO/COMFORT/SPORT", title: "Driving Mode Selection" },
        {
          value: "Front Row Passenger Seat",
          title: "Foldable Board (Second Row)",
        },
        { value: "6-way Power Adjustment", title: "Driver's Seat Adjustment" },
        {
          value: "4-way Power Adjustment",
          title: "Driver's Seat Lumbar Support",
        },
        {
          value: "4-way Power Adjustment",
          title: "Front Passenger Seat Adjustment",
        },
        {
          value: "4-way Power Adjustment",
          title: "Front Passenger Seat Leg Support",
        },
        {
          value: "4-way Power Adjustment",
          title: "Front Passenger Seat Lumbar Support",
        },
        {
          value: "Front Passenger Seat",
          title: "Front Seat Usage Status Monitoring",
        },
        { value: "Flash light", title: "Smart Car Search" },
        { value: "English", title: "Voice Control" },
        { value: "(Not hotspot)", title: "In-Car WiFi" },
        { value: "128G", title: "In-Car Memory Capacity" },
        { value: "8", title: "Number of Speakers" },
        { value: "4", title: "Around View Camera" },
      ],
      otherSpecs: [
        {
          title: "Power System",
          values: [
            "Vehicle-to-Load Function (V2L)",
            "Battery Low-Temperature Preheating System",
          ],
        },
        {
          title: "Chassis System",
          values: [
            "Electric Parking Brake (EPB)",
            "Autohold",
            "Adjustable Brake Energy Regeneration Intensity",
            "One-Pedal Driving Mode",
            "Emergency Tire Repair Kit (Tire Sealant+ Inflator)",
          ],
        },
        {
          title: "Exterior",
          values: [
            "Panoramic Sunroof",
            "Electric Sunshade",
            "Floating Roof Design",
            "Roof Spoiler",
            "Hidden Door Handles (Electric Induction)",
            "Roof Rack",
            "Intelligent Remote Key(RF)",
            "Passive Entry (PE)",
            "Passive Start (PS)",
            "Exterior Mirror Electric Adjustment",
            "Exterior Mirror Auto Folding",
            "Exterior Mirror Heating",
            "Exterior Mirror with Memory Function and Automatically Rolling Down",
            "Manual Diming Rearview Mirror",
            "Rear Window Defroster",
            "Frameless Front Wipers",
            "Rear Wiper",
            "Automatic Rain-Sensing Wipers",
            "Front Hood Storage Compartment",
          ],
        },
        {
          title: "Light",
          values: [
            "LED Headlights",
            "LED Daytime Running Lights",
            "Automatic Headlights",
            "Rear Fog Light",
            "LED Taillights",
            "LED High-Mounted Brake Light",
            "Follow Me Home Function",
            "Front Row Roof LED Reading Lights",
            "Front Row Reading Lights with Touch Control",
            "Rear Row Reading Lights with Touch Control",
            "Front Row Footwell Lights",
            "Trunk Light",
          ],
        },
        {
          title: "Interior",
          values: [
            "Electronic Column Shift",
            "Artificial Leather Steering Wheel",
            "Front 12V Power Outlet",
            "12V Trunk Power Outlet",
            "Trunk Cargo Cover",
            "USB Ports (Front Row)",
            "Type-C Ports (Front Row)",
            "USB Ports (Second Row)",
            "Type-C Ports (Second Row)",
            "Wireless Phone Charging",
            "All Windows One-Touch Up/Down with Anti-Pinch",
            "Automatic Window Lifting",
            "Sunvisors with Vanity Mirror",
            "Sunvisors with Light",
          ],
        },
        {
          title: "Seat",
          values: [
            "Artificial Leather Seats",
            "Driver's Seat with Memory Function",
            "Driver's Seat with Welcome Function",
            "Front Passenger Seat with Memory Function",
            "Front Passenger Seat with Welcome Function",
            "Front Seat Heating",
            "Front Seat Ventilation",
            "Front Seat Massage",
            "Zero gravity seat",
            "Power Adjustable Front Passenger's Seat by Rear Passenger",
            "Front Central Armrest",
            "Second-Row Seat Back Manual Adjustment",
            "Second-Row Seat Back Folding Down",
            "Second-Row Seat Central Armrest With Cup Holder",
            "Second-Row Central Headrest",
          ],
        },
        {
          title: "Air Conditioning",
          values: [
            "Automatic Air Conditioning",
            "Dual-Zone Air Conditioning",
            "Second-Row Air Conditioning Air Outlets",
            "Interior Air Adjustment/Pollen Filtration",
            "Heat Pump HEPA (N95) Filter",
          ],
        },
        {
          title: "Safety",
          values: [
            "ABS + EBD",
            "Brake Assist (EBA/BAS/BA, etc.)",
            "Electronic Stability Control (ESC/ESP, etc.)",
            "Traction Control (ASR/TCS/TRC, etc.)",
            "Limp-Home Mode",
            "Driver/Passenger Front Airbags",
            "Front Side Airbags",
            "Side Curtain Airbags",
            "Direct Tire Pressure Monitoring System",
            "Height Adjustable Front-Row Seatbelts",
            "Driver's Seatbelt Unfastened Reminder",
            "Front Passenger's Seatbelt Unfastened Reminder",
            "Second-Row Seats With ISOFIX/LATCH",
            "Mechanical Child Safety Locks",
            "Automatic Door Locking While Driving",
            "Automatic Unlocking in Case of Collision",
            "Front Parking Radar",
            "Rear Parking Radar",
            "Reverse Camera with Auxiliary Guidelines",
            "Around View Monitor (AVM)",
          ],
        },
        {
          title: "Advanced Driving Assistance System",
          values: [
            "Ultrasonic Radar",
            "Around View Camera",
            "Millimeter-Wave Radar",
            "Front ADAS Camera",
            "Forward Collision Warning (FCW)",
            "Automatic Emergency Braking (AEB)",
            "AEB with Pedestrian and Bicycle Detection",
            "Lane Departure Warning (LDW)",
            "Lane Changing Assist with BSD",
            "Door Opening Warning (DOW)",
            "Rear Cross-Traffic Alert (RCTA)",
            "Full-Speed Adaptive Cruise Control (ACC)",
            " Lane Keep Assist (LKA)",
            "Traffic Jam Assist (TJA)",
            "Intelligent Cruise Assist (ICA)",
            "Traffic Sign Recognition (TSR)",
          ],
        },
        {
          title: "Others",
          values: [
            "Central Control Screen Size（15.6 Inch）",
            "Mobile Phone Mirroring(Carplay&Android Auto)",
            "Bluetooth Music/Call",
          ],
        },
      ],
      spectrum: [
        {
          icon: col1,
          car: car1,
        },
        {
          icon: col2,
          car: car2,
        },
        {
          icon: col3,
          car: car3,
        },
        {
          icon: col4,
          car: car4,
        },
      ],
      activeSpectrum: 0,
    };
  },
  computed: {
    mobile() {
      return window.innerWidth <= 768;
    },
  },
  methods: {
    handleSwipe() {
      if (this.swipe.touchendX < this.swipe.touchstartX) {
        if (this.activeSpectrum < this.spectrum.length - 1) {
          this.changeCarTo(this.activeSpectrum + 1);
        }
      }
      if (this.swipe.touchendX > this.swipe.touchstartX) {
        if (this.activeSpectrum > 0) {
          this.changeCarTo(this.activeSpectrum - 1, true);
        }
      }
    },
    async changeCarTo(index, reverse = false) {
      const changeTo = async (index) => {
        this.activeSpectrum = index;
      };
      if (reverse) {
        if (this.activeSpectrum !== 0) {
          gsap.to(".switchCont", {
            xPercent: 110,
            duration: 0.4,
            ease: "Power4.in",
            async onComplete() {
              await changeTo(index);
              gsap.set(".switchCont", {
                xPercent: -110,
                onComplete() {
                  gsap.to(".switchCont", {
                    xPercent: 0,
                    duration: 0.6,
                    ease: "Power4.out",
                  });
                },
              });
            },
          });
        }
      } else {
        if (this.activeSpectrum !== index) {
          gsap.to(".switchCont", {
            xPercent: -110,
            duration: 0.4,
            ease: "Power4.in",
            async onComplete() {
              await changeTo(index);
              gsap.set(".switchCont", {
                xPercent: 110,
                onComplete() {
                  gsap.to(".switchCont", {
                    xPercent: 0,
                    duration: 0.6,
                    ease: "Power4.out",
                  });
                },
              });
            },
          });
        }
      }
    },
  },
  watch: {
    lhero() {
      if (this.lhero === true) {
        this.$refs.swipeArea.addEventListener("touchstart", (e) => {
          this.swipe.touchstartX = e.changedTouches[0].screenX;
        });
        this.$refs.swipeArea.addEventListener("touchend", (e) => {
          this.swipe.touchendX = e.changedTouches[0].screenX;
          this.handleSwipe();
        });
      }
    },
  },
};
</script>

<style scoped >
.heroBG {
  background-image: url("@/assets/images/savannah/hero.png");
  background-position: top right;
  background-size: cover;
}
@media (max-width: 768px) {
  .heroBG {
    background-position: 25% bottom;
    background-image: url("@/assets/images/savannah/hero.png");
  }
}
.specBack {
  background-image: url("@/assets/svgs/greyLogo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.vehSpec {
  @apply flex items-center gap-3 md:w-auto w-full;
}
.bigDot {
  @apply inline-block w-2 h-2 rounded-full bg-green bg-opacity-65;
}
</style>
