<template>
  <!-- nbg === 'dark' && !mobile ? 'bg-realBlack' : 'bg-[transparent]', -->
  <nav
    class="top-0 left-0 z-50 w-full py-4 drop nv"
    :class="[
      nbg === 'dark' && !mobile ? 'bg-realBlack' : '',
      pos === 'rel' && !mobile ? 'relative bg-white' : 'fixed',
      pos === 'relMob' && mobile ? 'relative' : 'fixed',
      mobile ? ' backdrop-blur-lg border-b border-b-[#FFFFFF80]' : '',
      !mobile && blur
        ? 'bg-white bg-opacity-5 hover:bg-opacity-100 backdrop-blur-lg'
        : '',
    ]"
    ref="nav"
    @mouseleave="backUp"
    @click="!mobile ? backUp : ''"
  >
    <!-- :class="drop ? 'bg-white' : ''" -->
    <div class="relative z-10 flex items-center justify-between contain">
      <div class="">
        <router-link :to="{ name: 'home' }">
          <img
            src="@/assets/svgs/greyLogo.svg"
            alt="Logo"
            v-if="logo === 'grey'"
            class="w-[115px]"
          />
          <img
            src="@/assets/svgs/whiteLogo.svg"
            alt="Logo"
            class="w-[115px]"
            v-else-if="logo === 'white'"
          />
          <img
            src="@/assets/svgs/logo.svg"
            alt="Logo"
            class="w-[115px]"
            v-else
          />
          <!-- PRELOAD IMAGES -->
          <div class="absolute z-0">
            <img
              src="@/assets/images/nav/javnav.png"
              class="w-0 h-0 opacity-0"
              alt=""
            />
            <img
              src="@/assets/images/nav/panther.png"
              class="w-0 h-0 opacity-0"
              alt=""
            />
            <img
              src="@/assets/images/nav/sav.png"
              class="w-0 h-0 opacity-0"
              alt=""
            />
            <img
              src="@/assets/images/nav/cass.png"
              class="w-0 h-0 opacity-0"
              alt=""
            />
            <img
              src="@/assets/images/nav/sill.png"
              class="w-0 h-0 opacity-0"
              alt=""
            />
            <img
              src="@/assets/images/nav/sable.png"
              alt=""
              class="w-0 h-0 opacity-0"
            />
            <img
              src="@/assets/images/nav/charge.png"
              class="w-0 h-0 opacity-0"
              alt=""
            />
            <img
              src="@/assets/images/nav/swap.png"
              class="w-0 h-0 opacity-0"
              alt=""
            />
            <img
              src="@/assets/images/nav/home.png"
              class="w-0 h-0 opacity-0"
              alt=""
            />
            <img
              src="@/assets/images/nav/qoreLogo.png"
              class="w-0 h-0 opacity-0"
              alt=""
            />
            <img
              src="@/assets/images/nav/resource.png"
              class="w-0 h-0 opacity-0"
              alt=""
            />
          </div>
        </router-link>
      </div>
      <div
        class="md:flex md:flex-row flex-col md:relative fixed top-0 left-0 right-0 bottom-0 md:bg-[transparent] bg-white h-screen w-full md:h-auto md:w-auto items-center md:justify-between justify-between md:py-0 gap-4 md:z-auto z-50 max-h-screen overflow-y-auto"
        :class="[navOpen ? 'flex' : 'hidden']"
        @mouseenter="!mobile ? dropDown : ''"
      >
        <div class="relative w-[90%] mx-auto pt-4 pb-8" v-if="mobile">
          <div class="flex items-center justify-between w-full mb-8">
            <div class="w-[35px]"></div>
            <div class="">
              <!-- <img
                src="@/assets/svgs/greyLogo.svg"
                alt="Logo"
                v-if="logo === 'grey'"
                class="w-[115px]"
              />
              <img
                src="@/assets/svgs/whiteLogo.svg"
                alt="Logo"
                v-else-if="logo === 'white'"
                class="w-[115px]"
              /> -->
              <!-- v-else -->
              <img src="@/assets/svgs/logo.svg" alt="Logo" class="w-[115px]" />
            </div>
            <div
              class="relative w-[35px] h-[35px] flex items-center bg-realBlack rounded-full p-2 justify-center"
              @click="navOpen = false"
            >
              <!-- <div
                class="relative inline-block w-full h-full rounded-full"
                @click="navOpen = false"
              >
                <span
                  class="inline-block absolute w-[30px] h-1 bg-realBlack top-1/2 rotate-45"
                ></span
                ><span
                  class="inline-block absolute w-[30px] h-1 bg-realBlack top-1/2 rotate-[315deg]"
                ></span>
              </div> -->
              <img src="@/assets/images/close.png" alt="Close" />
            </div>
          </div>
          <NvDrop :header="'Vehicles'">
            <Vehs />
          </NvDrop>
          <NvDrop :header="'Energy Networks'">
            <Engy />
          </NvDrop>
          <!-- <NvDrop :header="'Resources'">
            <Rscs />
          </NvDrop> -->
          <router-link
            :to="{ name: 'faq' }"
            class="block mt-4 md:cursor-pointer"
          >
            <span
              class="text-base font-medium font-plus"
              :to="{ name: 'home' }"
              :class="'text-black'"
              >Learn More</span
            >
          </router-link>
          <div class="flex items-center justify-start w-full mt-8 md:hidden">
            <span class="">
              <button class="black-btn" @click="contactUs">Contact Us</button>
            </span>
          </div>
        </div>

        <div
          class="flex-col items-center justify-between hidden gap-4 md:flex md:flex-row"
        >
          <span
            class="md:cursor-pointer"
            @mouseenter="
              !mobile ? dropDown() : '';
              activeTab = 'vehicles';
            "
            @click="
              dropDown();
              activeTab = 'vehicles';
            "
          >
            <span
              class="text-base font-medium font-plus"
              :to="{ name: 'vehicles' }"
              :class="[
                !mobile
                  ? bg === 'dark'
                    ? drop
                      ? 'text-black'
                      : 'text-white'
                    : 'text-black'
                  : 'text-black',
              ]"
              >Vehicles</span
            >
          </span>
          <span
            class="md:cursor-pointer"
            @mouseenter="
              !mobile ? dropDown() : '';
              activeTab = 'energyNet';
            "
            @click="
              dropDown();
              activeTab = 'energyNet';
            "
          >
            <span
              class="text-base font-medium font-plus"
              :to="{ name: 'home' }"
              :class="
                !mobile
                  ? bg === 'dark'
                    ? drop
                      ? 'text-black'
                      : 'text-white'
                    : 'text-black'
                  : 'text-black'
              "
              >Energy Networks</span
            >
          </span>
          <!-- activeTab = 'resources'; -->
          <!-- activeTab = 'resources'; -->
          <router-link
            :to="{ name: 'faq' }"
            class="md:cursor-pointer"
            @mouseenter="!mobile ? backUp() : ''"
            @click="backUp()"
          >
            <span
              class="text-base font-medium font-plus"
              :to="{ name: 'home' }"
              :class="
                !mobile
                  ? bg === 'dark'
                    ? drop
                      ? 'text-black'
                      : 'text-white'
                    : 'text-black'
                  : 'text-black'
              "
              >Learn More</span
            >
          </router-link>
        </div>
      </div>
      <div class="">
        <span class="hidden md:inline-block">
          <button class="black-btn" @click="contactUs">Contact Us</button>
        </span>
        <div
          class="relative inline-flex flex-col items-start justify-center p-3 bg-white rounded-md md:hidden gap-y-1"
          @click="navOpen = true"
        >
          <span class="block w-[30px] h-[2px] bg-realBlack"></span>
          <!-- :class="[bg === 'dark' || nbg === 'dark' ? 'bg-white' : 'bg-black']" -->
          <span class="block w-[30px] h-[2px] bg-realBlack"></span>
          <!-- :class="[bg === 'dark' || nbg === 'dark' ? 'bg-white' : 'bg-black']" -->
          <span class="block w-[30px] h-[2px] bg-realBlack"></span>
          <!-- :class="[bg === 'dark' || nbg === 'dark' ? 'bg-white' : 'bg-black']" -->
        </div>
      </div>
    </div>
    <div
      class="w-full md:h-auto h-screen fixed top-0 left-0 md:pt-[4.5rem] pt-4 pb-16 bg-white opacity-0 dro z-50"
      ref="dro"
      v-if="drop"
    >
      <div class="w-[90%] mx-auto max-w-xl md:block hidden">
        <div
          class="flex items-start justify-center w-full flex-col md:flex-row max-h-[95vh] overflow-y-auto"
          v-if="activeTab === 'vehicles'"
        >
          <!-- id="vehicles" -->
          <!-- md:border-r border-r-[#E2E3E3] md:pr-14 -->
          <div class="w-full mx-auto">
            <div
              class="grid w-full grid-cols-2 gap-8 lg:grid-cols-6 md:grid-cols-6"
            >
              <div class="">
                <div class="">
                  <router-link :to="{ name: 'panther' }" class="block w-full">
                    <img
                      src="@/assets/images/nav/panther.png"
                      alt=""
                      class="w-full"
                    />
                  </router-link>
                  <p
                    class="w-full text-base font-medium text-center capitalize font-plus"
                  >
                    Panther
                  </p>
                  <div class="flex items-center justify-center gap-3 mt-2">
                    <router-link
                      :to="{ name: 'panther' }"
                      class="text-xs underline text-grey font-plus"
                      >Explore</router-link
                    >
                    <router-link
                      :to="{ name: 'pantherOrderNow' }"
                      class="text-xs underline text-grey font-plus"
                      >Order</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="">
                <router-link :to="{ name: 'savannah' }" class="block w-full">
                  <img
                    src="@/assets/images/nav/sav.png"
                    alt=""
                    class="w-full"
                  />
                </router-link>
                <p
                  class="w-full text-base font-medium text-center capitalize font-plus"
                >
                  Savannah
                </p>
                <div class="flex items-center justify-center gap-3 mt-2">
                  <router-link
                    :to="{ name: 'savannah' }"
                    class="text-xs underline text-grey font-plus"
                  >
                    Explore
                  </router-link>
                  <router-link
                    :to="{ name: 'order-savannah' }"
                    class="text-xs underline text-grey font-plus"
                  >
                    Order
                  </router-link>
                </div>
              </div>
              <div class="">
                <div class="">
                  <router-link :to="{ name: 'sill' }" class="block w-full">
                    <img
                      src="@/assets/images/nav/sill.png"
                      alt=""
                      class="w-full"
                    />
                  </router-link>
                  <p
                    class="w-full text-base font-medium text-center capitalize font-plus"
                  >
                    Sill
                  </p>
                  <div class="flex items-center justify-center gap-3 mt-2">
                    <router-link
                      :to="{ name: 'sill' }"
                      class="text-xs underline text-grey font-plus"
                    >
                      Explore
                    </router-link>
                    <router-link
                      :to="{ name: 'order-sill' }"
                      class="text-xs underline text-grey font-plus"
                    >
                      Order
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="">
                <div class="">
                  <router-link :to="{ name: 'caspian' }" class="">
                    <img
                      src="@/assets/images/nav/cass.png"
                      alt=""
                      class="w-full"
                    />
                  </router-link>
                  <p
                    class="w-full text-base font-medium text-center capitalize font-plus"
                  >
                    Caspian
                  </p>
                  <div class="flex items-center justify-center gap-3 mt-2">
                    <router-link
                      :to="{ name: 'caspian' }"
                      class="text-xs underline text-grey font-plus"
                    >
                      Explore
                    </router-link>
                    <router-link
                      :to="{ name: 'order-caspian' }"
                      class="text-xs underline text-grey font-plus"
                    >
                      Order
                    </router-link>
                    <!-- <router-link
                    :to="{ name: 'vehicles' }"
                    class="text-xs underline text-grey font-plus"
                    >Order Now</router-link
                  > -->
                  </div>
                </div>
              </div>
              <div class="">
                <router-link :to="{ name: 'sable' }" class="">
                  <img
                    src="@/assets/images/nav/sable.png"
                    alt=""
                    class="w-full"
                  />
                </router-link>
                <p
                  class="w-full text-base font-medium text-center capitalize font-plus"
                >
                  Sable
                </p>
                <div class="flex items-center justify-center gap-3 mt-2">
                  <router-link
                    :to="{ name: 'sable' }"
                    class="text-xs underline text-grey font-plus"
                    >Explore</router-link
                  >
                  <router-link
                    :to="{ name: 'order-sable' }"
                    class="text-xs underline text-grey font-plus"
                    >Order</router-link
                  >
                </div>
              </div>
              <div class="">
                <router-link :to="{ name: 'javan' }" class="">
                  <img
                    src="@/assets/images/nav/javnav.png"
                    alt=""
                    class="w-full"
                  />
                </router-link>
                <p
                  class="w-full text-base font-medium text-center capitalize font-plus"
                >
                  Javan
                </p>
                <div class="flex items-center justify-center gap-3 mt-2">
                  <router-link
                    :to="{ name: 'javan' }"
                    class="text-xs underline text-grey font-plus"
                    >Explore</router-link
                  >
                  <router-link
                    :to="{ name: 'order-javan' }"
                    class="text-xs underline text-grey font-plus"
                    >Order</router-link
                  >
                </div>
              </div>

              <!-- <div class="flex items-end justify-center">
                <a
                  href="/assets/doc/Qoray Brochure.pdf"
                  download
                  class="text-sm font-bold underline text-realBlack font-inter"
                  >View All</a
                >
              </div> -->
            </div>
          </div>
          <div
            class="flex flex-row flex-wrap items-start gap-6 pt-12 pb-12 md:flex-col md:pt-3 md:pl-12 md:pb-0"
          >
            <!-- <router-link
              :to="{ name: 'home' }"
              class="block text-sm font-bold text-left text-realBlack font-inter"
              >Sedan</router-link
            >
            <span
              :to="{ name: 'home' }"
              class="block text-sm font-bold text-left cursor-not-allowed opacity-40 text-realBlack font-inter"
              >Three-wheelers <sub>Coming Soon</sub>
            </span>
            <span
              :to="{ name: 'home' }"
              class="block text-sm font-bold text-left cursor-not-allowed opacity-40 text-realBlack font-inter"
              >Two-wheelers <sub>Coming Soon</sub>
            </span> -->
            <!-- <span
              :to="{ name: 'home' }"
              class="block text-sm font-bold text-left cursor-not-allowed opacity-40 text-realBlack font-inter"
              >SUV <sub>Coming Soon</sub>
            </span>
            <span
              :to="{ name: 'home' }"
              class="block text-sm font-bold text-left cursor-not-allowed opacity-40 text-realBlack font-inter"
              >Buses <sub>Coming Soon</sub>
            </span> -->
            <!-- <router-link
              :to="{ name: 'home' }"
              class="block text-sm font-bold text-left text-realBlack font-inter"
              >Pick-up</router-link
            > -->
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center w-full gap-12 md:flex-row"
          v-if="activeTab === 'energyNet'"
        >
          <div class="">
            <router-link class="block" :to="{ name: 'charging' }">
              <img src="@/assets/images/nav/charge.png" alt="" />
              <p
                class="w-full mt-2 text-base font-medium text-center capitalize font-plus"
              >
                Charge Station Network
              </p>
              <router-link
                class="block text-sm font-bold text-center underline text-realBlack font-inter"
                :to="{ name: 'charging' }"
                >View More</router-link
              >
            </router-link>
          </div>
          <div class="">
            <div class="cursor-not-allowed opacity-40">
              <img src="@/assets/images/nav/swap.png" alt="" />
              <p
                class="w-full mt-2 text-base font-medium text-center capitalize font-plus"
              >
                Swap Station Network
              </p>
              <span
                class="block text-sm font-bold text-center underline text-realBlack font-inter"
                :to="{ name: 'swap' }"
                >Coming Soon</span
              >
            </div>
          </div>
          <div class="">
            <div class="cursor-not-allowed opacity-40">
              <img src="@/assets/images/nav/home.png" alt="" />
              <p
                class="w-full mt-2 text-base font-medium text-center capitalize font-plus"
              >
                Home Chargers
              </p>

              <span
                class="block text-sm font-bold text-center underline text-realBlack font-inter"
                :to="{ name: 'home' }"
                >Coming Soon</span
              >
            </div>
          </div>
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:justify-center w-full max-h-[95vh] md:max-h-none overflow-y-auto relative md:pb-0 pb-[10vh]"
          v-if="activeTab === 'resources'"
        >
          <!-- id="vehicles" -->
          <div
            class="relative flex flex-col items-start gap-6 pt-3 md:pr-12 md:mb-0"
          >
            <router-link
              :to="{ name: 'home' }"
              class="block text-sm font-bold text-left text-realBlack font-inter"
              >Download our brochure</router-link
            >
            <router-link
              :to="{ name: 'home' }"
              class="block text-sm font-bold text-left text-realBlack font-inter"
              >Qoray in the news</router-link
            >
            <router-link
              :to="{ name: 'home' }"
              class="block text-sm font-bold text-left text-realBlack font-inter"
              >Articles</router-link
            >
            <router-link
              :to="{ name: 'home' }"
              class="block text-sm font-bold text-left text-realBlack font-inter"
              >Media Gallery</router-link
            >
          </div>
          <div
            class="md:w-[70%] w-full md:border-l border-l-[#E2E3E3] md:pl-14"
          >
            <div
              class="grid items-end w-full grid-cols-1 gap-8 lg:grid-cols-4 md:grid-cols-3"
            >
              <div class="">
                <img
                  src="@/assets/images/nav/qoreLogo.png"
                  alt=""
                  class="w-full"
                />
                <router-link
                  :to="{ name: 'about' }"
                  class="block w-full mt-8 text-sm font-bold text-center underline capitalize text-realBlack font-inter"
                  >About Qoray</router-link
                >
              </div>
              <div class="">
                <img
                  src="@/assets/images/nav/resource.png"
                  alt=""
                  class="w-full"
                />
                <router-link
                  :to="{ name: 'tour' }"
                  class="block w-full mt-8 text-sm font-bold text-center underline capitalize text-realBlack font-inter"
                  >Tour with Qoray</router-link
                >
              </div>
              <div class="">
                <img
                  src="@/assets/images/nav/resource.png"
                  alt=""
                  class="w-full"
                />
                <router-link
                  :to="{ name: 'impact' }"
                  class="block w-full mt-8 text-sm font-bold text-center underline capitalize text-realBlack font-inter"
                  >Sustainability Impact</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import gsap from "gsap";
import NvDrop from "./NvDrop.vue";
import Vehs from "./Vehs.vue";
import Engy from "./Engy.vue";
import Rscs from "./Rscs.vue";
export default {
  components: { NvDrop, Vehs, Engy, Rscs },
  props: ["bg", "nbg", "nbgm", "pos", "logo", "blur"],
  data() {
    return {
      drop: false,
      navOpen: false,
      activeTab: "vehicles",
      lastScrollTop: 0,
      navUp: false,
    };
  },
  computed: {
    navHeight() {
      return this.$refs.nav.offsetHeight;
    },
    blurStoreVal() {
      return this.$store.state.blurNav || this.blur;
    },
    mobile() {
      return window.innerWidth <= 768;
    },
    routePath() {
      return this.$route.fullPath;
    },
  },
  methods: {
    contactUs() {
      this.$store.dispatch("contactUs");
    },
    moveNavUp(up) {
      // console.log(up ? "Push UP" : "DOWN");
      if (up) {
        if (!this.navUp) {
          gsap.to(".nv", {
            yPercent: -100,
            duration: 0.5,
            onComplete: () => {
              this.navUp = true;
            },
          });
        }
      } else {
        if (this.navUp) {
          gsap.to(".nv", {
            yPercent: 0,
            duration: 0.5,
            onComplete: () => {
              this.navUp = false;
            },
          });
        }
      }
    },
    dropDown() {
      // console.log(this.activeTab);
      if (!this.drop) {
        this.drop = true;
        // if (!this.mobile) {
        setTimeout(() => {
          gsap.set(".dro", {
            // y: this.navHeight,
            opacity: 1,
          });
          this.$refs.nav.classList.add("bg-white");
          // gsap.to(".nv", {
          //   // duration: this.mobile ? 0.3 : 0.8,
          //   // y: this.mobile ? 0 : this.navHeight,
          //   backgroundColor: "#FFFFFF",
          //   opacity: 1,
          // });
          gsap.to(".dro", {
            // duration: this.mobile ? 0.3 : 0.8,
            y: this.mobile ? 0 : this.navHeight,
            opacity: 1,
          });
        }, 50);
        // }
        // } else {
        //   setTimeout(() => {
        //     gsap.set(".dro", {
        //       // y: 0,
        //       opacity: 1,
        //     });
        //   }, 50);
        // }
      }
    },
    backUp() {
      // console.log("OUT");
      // return;
      if (this.drop) {
        gsap.to(".dro", {
          duration: 0.3,
          yPercent: -20,
          opacity: 0,
          onComplete: () => {
            this.drop = false;
          },
        });
        this.$refs.nav.classList.remove("bg-white");
      }
    },
    blurBg(blur) {
      if (blur) {
        this.$refs.nav.classList.add(
          "grad"
          // this.mobile ? "bg-black" : "bg-white",
          // "bg-opacity-30",
          // "backdrop-blur-[17.5px]"
        );
        this.$refs.nav.classList.remove("bg-[transparent]");
      } else {
        this.$refs.nav.classList.remove(
          "grad"
          // this.mobile ? "bg-black" : "bg-white",
          // "bg-opacity-30",
          // "backdrop-blur-[17.5px]"
        );
        this.$refs.nav.classList.add("bg-[transparent]");
      }
    },
  },
  mounted() {
    // console.log();
    // console.log(this.bg);
    // console.log(this.$refs.nav);
    // console.log(this.navHeight);
    // this.$refs
    // this.dropDown();
    this.$store.commit("SetNavHeight", this.navHeight);
    window.addEventListener("scroll", (evt) => {
      // console.log(evt);
      // console.log("SOMETHING");
      var ap = document.getElementById("app");
      var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > this.lastScrollTop) {
        // downscroll code
        if (!this.mobile || (this.mobile && !this.navOpen)) {
          this.moveNavUp(true);
        }
      } else if (st < this.lastScrollTop && st <= this.navHeight) {
        // upscroll code
        this.moveNavUp(false);
      } // else was horizontal scroll
      this.lastScrollTop = st <= 0 ? 0 : st;
      if (ap.getBoundingClientRect().top < -120) {
        this.$store.commit("setBlur", true);
      } else {
        this.$store.commit("setBlur", false);
      }
    });
  },
  watch: {
    blurStoreVal() {
      // console.log(this.blurStoreVal);
      this.blurBg(this.blurStoreVal);
    },
    routePath() {
      // window.scrollTo(0, 0);
      console.log(this.blur);
      console.log(this.mobile);

      this.navOpen = false;
    },
  },
};
</script>

<style lang="scss">
nav {
  /* transition: all 0.4s ease-out; */
}
.drop,
.grad {
  /* transition: all 0.4s ease-out; */
}
.hide {
  display: none;
  opacity: 0;
}
.show {
  display: block;
  opacity: 1;
}
.grad {
  background: linear-gradient(to bottom, #ffffff65, transparent);
  /* backdrop-filter: blur(3px); */
  &:hover {
    background: #ffffff;
  }
}
@media (max-width: 768px) {
  .grad {
    background: linear-gradient(to bottom, #ffffff65, transparent);
    /* backdrop-filter: blur(3px); */
    &:hover {
      background: transparent;
    }
  }
}
</style>
