<template>
  <section
    v-if="success"
    class="success fixed top-0 left-0 z-[9999999999999] w-full h-screen bg-black bg-opacity-80 backdrop-blur-[2px] flex items-center justify-center"
  >
    <div
      class="relative min-w-[300px] max-w-[350px] p-8 bg-form rounded-[8px] bg-black"
    >
      <div class="flex items-center justify-end">
        <div
          @click="closeAll"
          class="relative cursor-pointer w-[16px] h-[16px]"
        >
          <span
            class="w-full absolute top-[8px] left-0 block h-[2px] bg-white rotate-45"
          ></span>
          <span
            class="w-full absolute top-[8px] left-0 block h-[2px] bg-white -rotate-45"
          ></span>
        </div>
      </div>
      <div class="checkMark flex items-center justify-center w-full mt-12">
        <svg
          width="202"
          height="202"
          viewBox="0 0 202 202"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M91.191 27.0925C96.9656 22.6185 105.036 22.6185 110.81 27.0925L117.535 32.3027C120.477 34.5817 124.12 35.7654 127.839 35.6506L136.342 35.3882C143.643 35.1629 150.172 39.9064 152.214 46.9202L154.592 55.0881C155.632 58.6607 157.884 61.7598 160.96 63.853L167.994 68.6386C174.033 72.748 176.527 80.4232 174.056 87.2978L171.179 95.3034C169.921 98.8051 169.921 102.636 171.179 106.137L174.056 114.143C176.527 121.018 174.033 128.693 167.994 132.802L160.96 137.588C157.884 139.681 155.632 142.78 154.592 146.353L152.214 154.521C150.172 161.534 143.643 166.278 136.342 166.053L127.839 165.79C124.12 165.675 120.477 166.859 117.535 169.138L110.81 174.348C105.036 178.822 96.9656 178.822 91.191 174.348L84.4663 169.138C81.5248 166.859 77.8817 165.675 74.1625 165.79L65.6595 166.053C58.358 166.278 51.8291 161.534 49.7871 154.521L47.4091 146.353C46.369 142.78 44.1174 139.681 41.041 137.588L34.0078 132.802C27.9683 128.693 25.4744 121.018 27.945 114.143L30.8222 106.137C32.0806 102.636 32.0806 98.8051 30.8222 95.3034L27.945 87.2978C25.4744 80.4232 27.9683 72.748 34.0078 68.6386L41.041 63.853C44.1174 61.7598 46.369 58.6607 47.4091 55.0881L49.7871 46.9202C51.8291 39.9064 58.358 35.1629 65.6595 35.3882L74.1625 35.6506C77.8817 35.7654 81.5248 34.5817 84.4663 32.3027L91.191 27.0925Z"
            fill="#50AF30"
          />
          <path
            d="M131.52 80.0597C132.85 83.4903 130.988 85.8396 127.387 88.1577C124.481 90.0222 120.78 92.0483 116.859 95.5069C113.012 98.8971 109.261 102.983 105.927 107.004C103.074 110.457 100.358 114.019 97.7852 117.685C96.4987 119.521 94.6995 122.262 94.6995 122.262C94.0538 123.26 93.1646 124.076 92.1158 124.634C91.067 125.193 89.8932 125.475 88.7052 125.453C87.5179 125.446 86.3521 125.136 85.3186 124.551C84.2852 123.966 83.4186 123.127 82.801 122.113C79.6967 116.902 77.304 114.841 76.2039 114.105C73.2612 112.122 69.8057 111.837 69.8057 107.225C69.8057 103.561 72.8976 100.594 76.7104 100.594C79.4046 100.693 81.9061 101.753 84.1248 103.244C85.5418 104.195 87.0427 105.457 88.6027 107.113C90.6932 104.26 92.8673 101.47 95.1221 98.7448C98.7174 94.4099 102.962 89.7581 107.515 85.7432C111.989 81.7968 117.163 78.102 122.648 76.1505C126.222 74.8765 130.193 76.626 131.52 80.0597Z"
            fill="white"
            stroke="white"
            stroke-width="2.09753"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="w-full mt-12 text-white">
        <p
          class="text-2xl font-semibold text-center font-inter"
          style="letter-spacing: 0.02em"
        >
          {{ payload.title }}
        </p>
        <p class="text-center text-xs mt-2 font-inter">
          {{ payload.top }} <br />
          {{ payload.bottom }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    success() {
      return this.$store.state.success;
    },
    payload() {
      return this.$store.state.successPayload;
    },
    // success() {
    //   return this.$store.state.success;
    // },
  },
  methods: {
    closeAll() {
      this.$store.dispatch("closeSuccess");
    },
  },
};
</script>

<style>
</style>