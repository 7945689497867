<template>
  <div class="w-full md:py-[10vh] py-[10vh] *:font-inter">
    <div class="w-[90%] max-w-[68.3rem] mx-auto">
      <section>
        <h3 class="font-bold text-4xl no-underline mb-8">PRIVACY POLICY</h3>
        <p>
          At Qoray Mobility, we take the privacy of your personal information
          seriously and remain committed to protecting and ensuring that your
          Personal Data is treated fairly and lawfully in all our dealings with
          you. <br /><br />
          This Privacy Policy (“Policy”) has been put together to explain to you
          what we do with the personal information that you provide to us when
          you download, access and use the QORAY platform. QORAY is a clean
          energy-powered mobility solution (the “service”) that offers quality,
          cost-effective, reliable products with the primary mission to deliver
          innovative solutions through communities and partnerships.
          <br /><br />
          Throughout this document, “we”, “us”, “our” and/or “ours” refer to
          STERLING LIMITED (“STERLING”) incorporated under the laws of the
          Federal Republic of Nigeria with its registered office at 20, Marina,
          Lagos. The reference to 'you' or 'your', means you, or any person
          authorized by you to access your account. <br /><br />
          By continuing to use the QORAY platform via mobile application or
          website, you accept and consent to the provisions described in this
          Policy.
        </p>
      </section>
      <section>
        <h3 class="hdr">The information we will collect about you</h3>
        <p>
          We gather information from you for a number of reasons. We may need
          you to provide your Personal Information/Data such as your name,
          contact details, identification, work and residential addresses,
          gender, positions held, forms submitted, Bank Verification Number
          (BVN), payment details, and other enquiry details which would enhance
          your service experience. Your Personal Data we collect, fall into
          various categories, such as:
        </p>
        <ul class="pl-4 list-disc">
          <li class="mt-3">
            <strong
              >Details about your banking activities and transactions with
              us.</strong
            >
            <br />
            <p>
              This includes information on any bank accounts you use, debit card
              numbers, financial history, information you provide to deliver
              payment initiation services and account information services
              regarding accounts you hold with other providers.
            </p>
          </li>
          <li class="mt-3">
            <strong
              >Information on how you use your device / Usage Data.</strong
            >
            <br />
            <p>
              We may also collect information that your browser sends whenever
              you visit our website or when you access the QORAY platform via a
              mobile device (“Usage Data”). This Usage Data may include
              information such as your computer's Internet Protocol address
              (e.g., IP address), browser type, browser version, the pages of
              our Service that you visit, the time and date of your visit and
              the time spent on those pages amongst other diagnostic data. When
              you access the Service by or through a mobile device or use the
              mobile app, this Usage Data may include information such as the
              type of mobile device you use, your mobile device unique ID, the
              IP address of your mobile device, your mobile operating system,
              the type of mobile Internet browser you use, unique device
              identifiers and other diagnostic data.
            </p>
          </li>
          <li class="mt-3">
            <strong>Sensitive data.</strong>
            <br />
            <p>
              We may hold information about you which includes without
              limitation to health, criminal conviction information or biometric
              information used to uniquely identify you. We will only hold this
              data when we need to for the purposes of the Service we are
              provide to you, where we are required to process the data for a
              public interest purpose, or where we have a legal obligation or
              your consent to do so.
            </p>
          </li>
          <li class="mt-3">
            <strong>Information which you have consented to us using.</strong>
            <br />
            <p>
              We may collect information about your marketing preferences to
              help us share with you information about relevant services,
              products, and offers that we think may be of interest to you.
            </p>
          </li>
          <li class="mt-3">
            <strong>Tracking and Cookies Data.</strong>
            <br />
            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service and hold certain information. Cookies are
              files with small amounts of data which may include an anonymous
              unique identifier. Cookies are sent to your browser from a website
              and stored on your device. Tracking technologies also used are
              beacons, tags, and scripts to collect and track information and to
              improve and analyze our Service. You can instruct your browser to
              refuse all cookies or to indicate when a cookie is being sent. You
              can also refuse permission to read your phone data by the mobile
              application. However, if you do not accept cookies on your browser
              or allow permissions on your mobile device, our online service
              experience to you may be degraded and you may not be able to use
              some portions of our Service. We may also collect information
              about your internet browser settings and Internet Protocol (IP)
              address and other relevant information to help us identify your
              geographic location when providing you with our services.
            </p>
          </li>
        </ul>
      </section>
      <section class="w-full">
        <h3 class="hdr">How we use your personal Information</h3>
        <p>
          To the extent permissible under applicable law, we may use your
          information for the following legitimate actions:
        </p>
        <ul>
          <li>
            Determine your eligibility for any of our products and services.
          </li>
          <li>
            Verify your identity when you access your account information.
          </li>
          <li>
            Administer your accounts or other products and services that we or
            our partners/affiliates may provide to you.
          </li>
          <li>Respond to your requests and communicate with you.</li>
          <li>
            Prevention of crime, fraud, money laundering or terrorism financing
            activities
          </li>
          <li>Managing our risks whether reputational or otherwise</li>
          <li>
            For marketing the products and services of QORAY related entities
            and affiliates. We may send you marketing and promotional messages
            by post, email, telephone, text, secure messaging, Mobile app, or
            through our social media channels. You can change your mind on how
            you wish to receive marketing messages from us or opt out of
            receiving such messages at any time. However, we will continue to
            use your contact details to send you important information regarding
            your dealings with us.
          </li>
          <li>Process transactions, design products, and profile customers</li>
          <li>
            Notify you about changes to the products and services on the
            Platform.
          </li>
          <li>
            Allowing you to participate in interactive features of our Services
            when you choose to do so.
          </li>
          <li>
            For complaints resolution e.g, troubleshooting, data analysis,
            testing, security, fraud-detection, and account management.
          </li>
          <li>
            Process your information for audit, statistical or research purposes
            in order to help us understand trends in our customer behaviour and
            to understand our risks better and onboard Travel Providers suited
            or relevant to customers' needs.
          </li>
          <li>
            Monitor our conversation with you when we speak on the telephone
            (for example, to check your instructions to us, to analyse, to
            assess and improve customer service; for training and quality
            assurance purposes; for verification, fraud analysis and prevention
            purposes
          </li>
          <li>Carry out analysis to evaluate and improve our business.</li>
          <li>Monitor the usage of the Platform</li>
          <li>Detect, prevent and address technical issues.</li>
          <li>
            Prevent fraud and enhance security of your account or the Platform.
          </li>
          <li>
            Comply with and enforce applicable legal and regulatory
            requirements, relevant industry standards, contractual obligations
            and our policies.
          </li>
          <li>
            Provide you with tailored content and marketing messages such as
            which we believe you may be interested in.
          </li>
          <li>For other purposes required by law or regulation</li>
        </ul>
      </section>
      <section class="w-full">
        <h3 class="hdr">How we may share your Personal Information</h3>
        <p>We may share your personal information with:</p>
        <ul>
          <li>
            Our affiliates and/or subsidiaries we control, but only for purposes
            allowed by this document.
          </li>
          <li>
            Law enforcement, government officials, or other third parties if we
            are compelled to do so by a subpoena, court order or similar legal
            procedure, when it is necessary to do so to comply with law, or
            where the disclosure of personal information is reasonably necessary
            to prevent physical harm or financial loss, to report suspected
            illegal activity, or to investigate violations of a User of the
            Platform;
          </li>
          <li>
            Third-party service providers who assist us in providing the
            services on the Platform to you, whether payment service or
            otherwise.
          </li>
          <li>
            deliver targeted advertising, marketing (which may include
            in-product messaging) or information to you which may be useful to
            you, based on your use of our application and website.
          </li>
        </ul>
        <p>
          We may also disclose your Personal Information in the good faith and
          belief that such action is necessary in any of the following
          circumstances:
        </p>
        <ul>
          <li>We have your consent to share the information.</li>
          <li>To comply with a legal obligation.</li>
          <li>
            To bring you improved service across our array of products and
            services, when permissible under relevant laws and regulations, by
            disclosing your personal information with affiliated websites and
            businesses.
          </li>
          <li>To protect and defend our rights or property.</li>
          <li>
            To prevent or investigate possible wrongdoing in connection with the
            Service.
          </li>
          <li>
            To protect the personal safety of users of our Service or the
            public.
          </li>
          <li>To protect against legal liability.</li>
          <li>
            We find that your actions on our websites or banking applications
            violate any part of
          </li>
        </ul>
      </section>
      <section class="w-full">
        <h3 class="hdr">How we secure your Information</h3>
        <p>
          We have implemented appropriate organizational and technical measures
          to keep your personal information/data, confidential and secure. This
          includes the use of encryption, access controls and other forms of
          security to ensure that your data is protected. We require all parties
          including our staff and third-parties processing data on our behalf to
          comply with relevant policies and guidelines. Where you have a
          password which grants you access to specific areas on our site or to
          any of our services, you are responsible for keeping this password
          confidential. We request that you do not share your password or other
          authentication details (e.g. token generated codes, PIN or Passwords)
          with anyone. <br />
          <br />

          Although we have taken measures to secure and keep your information
          confidential, because the security of your data is important to us,
          please be aware that no method of transmission over the Internet, or
          method of electronic storage can always guarantee 100% security. While
          we strive to use commercially acceptable means to protect your
          personal data, we cannot guarantee its absolute security, you are
          responsible for securing and maintaining the privacy of your password
          and account/profile registration information and verifying that your
          personal information with us is valid, accurate and up to date. If we
          receive instructions using your account login information, we will
          consider that you have authorized the instructions and we will process
          the instruction accordingly, and without incurring any liability for
          doing so.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">How long do we keep your information</h3>

        <p>
          We retain your Information for as long as the purpose for which the
          information was collected continues. The information is then securely
          destroyed unless its retention is required to satisfy legal,
          regulatory, internal compliance or accounting requirements or to
          protect our interest.
          <br />
          <br />
          Please note that regulations may require us to retain your personal
          data for a specified period even after the end of your banking
          relationship with us.
        </p>

        <p></p>
      </section>
      <section class="w-full">
        <h3 class="hdr">Information from locations outside Nigeria</h3>
        <p>
          If you are located outside Nigeria and choose to provide information
          to us, please note that the data, including personal data, will be
          processed in Nigeria. Your consent to this Privacy Policy followed by
          your submission of such information represents your agreement to that
          transfer.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">Accuracy and update of your Information</h3>
        <p>
          You are responsible for making sure the information provided to us is
          accurate and should inform the Bank on any changes as it occurs, this
          will enable us to update your information with us. <br /><br />
          Any changes will affect only future uses of your Personal Information.
          Subject to applicable law, which might, from time to time, oblige us
          to store your Personal Information for a certain period, we will
          respect your wishes to correct inaccurate information. Otherwise, we
          will hold your Personal Information for as long as we believe it will
          help us achieve our objectives as detailed in this Privacy Policy.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">Your Rights</h3>

        <p>
          You have certain rights in relation to the personal data we collect as
          provided by the Nigeria Data Protection Regulation 2019 (NDPR), these
          rights include:
        </p>
        <ul>
          <li>A right to access your personal data.</li>
          <li>A right to rectify/update your information in our possession.</li>
          <li>A right to request the erasure of personal data.</li>
          <li>
            A right to withdraw your consent to the processing of personal data.
            This will however not affect the legality of processing carried out
            prior to any such withdrawal.
          </li>
          <li>
            Right to object to the processing of personal data. This will only
            be applicable where there are no legal or operational reasons.
          </li>
          <li>
            Request that your personal data be made available to you in a common
            electronic format and/or request that such data be sent to a third
            party.
          </li>
          <li>
            Request that your information be erased. We might continue to retain
            such data if there are valid legal, regulatory or operational
            reasons.
          </li>
        </ul>
        <p>
          These rights are however subject to certain limitations as provided in
          the NDPR.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">Privacy of minors</h3>
        <p>
          We do not knowingly collect names, email addresses, or any other
          personally identifiable information from children. The QORAY service
          is only available to persons who are 18years old and above, or persons
          who have attained the age of majority in their respective
          jurisdictions. <br />
          If you are a parent or guardian and you are aware that your child has
          provided us with personal data without our knowledge and without the
          requisite parental consent, please promptly contact us.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">Social Media Platforms</h3>
        <p>
          We operate and communicate through our designated pages and accounts
          on some social media platforms to communicate and engage with our
          customers. We monitor and record comments and posts made about us or
          QORAY on these channels so that we can improve the Service. The
          general public can access and read any information posted on these
          sites. Please note that any content you post to such social media
          platforms is subject to the applicable social media platform’s terms
          of use and privacy policies. We recommend that you review the
          information carefully in order to better understand your rights and
          obligations regarding such content.
          <br /><br />
          The Service may also allow you to connect with us on, share on, and
          use third-party websites, applications, and services. Please be
          mindful of your personal privacy needs and the privacy needs of
          others, as you choose whom to connect with and what to share and make
          public. We cannot control the privacy or security of information you
          choose to make public or share with others. We also do not control the
          privacy practices of third parties. Please contact those sites and
          services directly if you want to learn about their privacy practices.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">Third Party Websites</h3>
        <p>
          The QORAY website and mobile applications may have links to or from
          other websites that are not operated by us. We have no control over
          and assume no responsibility for the security, privacy practices or
          content of third-party websites or services. We recommend that you
          always read the privacy and security statements on these websites.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">Service Providers</h3>
        <p>
          We may employ third party companies and individuals to facilitate the
          Service (“Service Providers”), on our behalf, to perform specific
          Service-related roles or to assist us in analyzing how the Service is
          used. These third parties have access to your Personal Data only to
          perform these tasks on our behalf and are obligated not to disclose or
          use it for any other purpose outside of the service-specific need for
          which the data is required.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">Changes to this Policy</h3>
        <p>
          This Privacy Policy is effective as of the date stated above and will
          remain in effect except with respect to any changes in its provisions
          in the future, which will be in effect immediately after being posted
          on our website.
          <br />
          <br />
          Based on the changing nature of privacy laws, user needs and our
          business, we may modify this Privacy Policy from time to time. Any
          change to our privacy policy will be communicated on our website, via
          email or by placing a notice on our Platform and this will be
          effective as soon as published and we will need not notify you of the
          change.
          <br />
          <br />
          We encourage periodic reviews of this Privacy Policy for awareness of
          any changes that may have occurred. Your continued use of the Services
          after we post any modifications to the Privacy Policy on our website
          will constitute your acknowledgment of the modifications and your
          consent to abide and be bound by the modified Privacy Policy.
        </p>
      </section>
      <section class="w-full">
        <h3 class="hdr">Contact Us</h3>
        <p>
          We are committed to resolving your privacy complaints and concerns as
          quickly as possible and have procedures in place to help resolve any
          problems or complaints efficiently. If you have any questions about
          this Privacy Policy, please contact us at
          <a href="mailto:info@qoraymobility.com">info@qoraymobility.com</a> or
          <a href="mailto:support@qoraymobility.com"
            >support@qoraymobility.com</a
          >, or call <a href="tel:+2348151491881">+234 8151491881</a> or
          <a href="tel:+2348151491827">+234 8151491827</a>.
        </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p,
li {
  @apply text-base;
}
.hdr {
  @apply font-semibold underline text-lg mt-6;
}
.upr {
  @apply uppercase;
}
li {
  @apply mt-3;
}
ul {
  @apply pl-4 list-disc;
}
</style>