<template>
  <div class="w-full">
    <!-- PRELOAD -->
    <img
      src="@/assets/images/panther/mobHero.png"
      class="fixed w-px h-px opacity-0 -z-10"
      alt=""
      ref="heroPic"
      @load="lhero = true"
      v-if="mobile"
    />
    <img
      src="@/assets/images/panther/hero.png"
      class="fixed w-px h-px opacity-0 -z-10"
      alt=""
      ref="heroPic"
      @load="lhero = true"
      v-else
    />
    <img
      class="fixed w-px h-px opacity-0 -z-10"
      v-for="(col, index) in spectrum"
      :key="index"
      :src="col.car"
      @click="activeSpectrum = index"
      alt=""
    />
    <!-- PRELOAD -->
    <div
      v-if="!lhero"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen"
    >
      <div
        class="bg-[#1B1B1B] absolute load1 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div
        class="bg-[#1B1B1B] absolute load2 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div class="img1">
        <img
          src="@/assets/images/miniLogo.png"
          class="z-[1] zoom img1"
          alt="Logo"
        />
      </div>
    </div>
    <div class="w-full">
      <section class="relative w-full heroBG">
        <div
          class="min-h-screen max-h-screen w-[90%] max-w-xl mx-auto h-full flex flex-col items-start justify-between md:py-[5vh] pt-[15vh] pb-[10vh]"
        >
          <div class="hidden w-full md:block"></div>
          <div class="w-full">
            <h1 class="font-belgiano md:text-[80px] text-[36px]">
              Panther<span class="text-green">.</span>
            </h1>
            <p class="text-xs font-inter md:text-2xl">
              Starting from ₦70 million
            </p>
            <div class="flex items-center gap-6 mt-3">
              <p class="text-xs font-inter md:text-lg">10% down payment</p>
              <div class="w-px h-4 bg-realBlack"></div>
              <p class="text-xs font-inter md:text-lg">Spread over 5 years</p>
            </div>
            <router-link :to="{ name: 'pantherOrderNow' }">
              <button
                class="black-btn w-[clamp(9.375rem,5.869vw+7.174rem,15.625rem)] mt-7"
              >
                Order Now
              </button>
            </router-link>
          </div>
          <div
            class="flex flex-wrap justify-between w-full md:gap-4 gap-y-6 md:flex-nowrap"
          >
            <div class="text-center md:w-1/4 w-[50%] text-realBlack">
              <p class="font-inter text-xs uppercase tracking-[1.25px]">
                Dimension
              </p>
              <p class="mt-1 text-sm font-inter md:text-xl">
                L:4715 W:1979 x H:1415
              </p>
            </div>
            <div class="text-center md:w-1/4 w-[45%] text-realBlack">
              <p class="font-inter text-xs uppercase tracking-[1.25px]">
                Top Speed
              </p>
              <p class="mt-1 text-sm font-inter md:text-xl">190km/H</p>
            </div>
            <div class="text-center md:w-1/4 w-[45%] text-realBlack">
              <p class="font-inter text-xs uppercase tracking-[1.25px]">
                Range
              </p>
              <p class="mt-1 text-sm font-inter md:text-xl">580 km</p>
            </div>
            <div class="text-center md:w-1/4 w-[45%] text-realBlack">
              <p class="font-inter text-xs uppercase tracking-[1.25px]">
                Battery
              </p>
              <p class="mt-1 text-sm font-inter md:text-xl">75 (KWh)</p>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack pt-[152px] pb-[120px]">
        <div class="w-[90%] max-w-xl mx-auto">
          <div class="md:w-[90%] w-full mx-auto">
            <div class="items-stretch hidden gap-8 mx-auto md:flex">
              <div class="w-1/2 h-full">
                <img
                  src="@/assets/images/panther/midScreen.png"
                  class="w-full"
                  alt=""
                />
                <div
                  class="w-full px-10 py-9 min-h-[420px] flex items-end tb-grad mt-8 relative"
                >
                  <div class="w-full">
                    <!-- <div
                      class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full"
                    >
                      <span
                        class="w-7 h-[20px] inline-block bg-white bg-opacity-20 rounded-[100%]"
                      ></span>
                    </div> -->
                    <div class="w-full text-white">
                      <h3
                        class="capitalize font-belgiano text-[26px] tracking-[1.4px]"
                      >
                        Smooth turns, every time
                      </h3>
                      <p class="tracking-[1.8px] mt-3">
                        Every turn becomes a seamless and effortless glide,
                        thanks to our artificial leather steering and advanced
                        technology that harmonizes vehicle and driver in perfect
                        sync.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-1/2 h-full">
                <div
                  class="w-full px-10 py-9 min-h-[420px] flex items-start bt-grad relative"
                >
                  <div class="w-full">
                    <div class="w-full text-white">
                      <h3
                        class="capitalize font-belgiano text-[26px] tracking-[1.4px]"
                      >
                        Intelligent Navigation
                      </h3>
                      <p class="tracking-[1.8px] mt-3">
                        Our 17.6-inch center control and real-time navigation
                        system learns your habits and suggests the fastest
                        routes for your commute.
                      </p>
                    </div>
                    <!-- <div
                      class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-full"
                    >
                      <span
                        class="w-7 h-[20px] inline-block bg-white bg-opacity-20 rounded-[100%]"
                      ></span>
                    </div> -->
                  </div>
                </div>
                <img
                  src="@/assets/images/panther/steering.png"
                  class="w-full mt-8"
                  alt=""
                />
              </div>
            </div>
            <div class="w-full overflow-x-auto no-scrollbar">
              <div
                class="md:hidden flex w-[280%] items-start justify-between gap-4"
              >
                <div class="w-full overflow-hidden">
                  <div class="w-full relative pt-[100%] overflow-hidden">
                    <img
                      src="@/assets/images/panther/midScreen.png"
                      class="w-full absolute top-0 left-0"
                      alt=""
                    />
                  </div>
                  <div class="w-full text-white mt-7">
                    <h3
                      class="capitalize font-belgiano text-[1rem] md:text-[26px] tracking-[1.4px]"
                    >
                      Intelligent Navigation
                    </h3>
                    <p class="tracking-[1.8px] mt-3 md:text-base text-xs">
                      Our real-time navigation system learns your habits and
                      suggests the fastest routes for your commute.
                    </p>
                  </div>
                </div>
                <div class="w-full overflow-hidden">
                  <div class="w-full relative pt-[100%] overflow-hidden">
                    <img
                      src="@/assets/images/panther/steering.png"
                      class="w-full absolute top-0 left-0"
                      alt=""
                    />
                  </div>
                  <div class="w-full text-white mt-7">
                    <h3
                      class="capitalize font-belgiano text-[1rem] md:text-[26px] tracking-[1.4px]"
                    >
                      Smooth turns, every time
                    </h3>
                    <p class="tracking-[1.8px] mt-3 md:text-base text-xs">
                      Every turn becomes a seamless and effortless glide, thanks
                      to our advanced technology that harmonizes vehicle and
                      driver in perfect sync.
                    </p>
                  </div>
                </div>
                <div class="w-full overflow-hidden">
                  <div class="w-full relative pt-[100%] overflow-hidden">
                    <img
                      src="@/assets/images/panther/dashScreens.png"
                      class="w-full absolute top-0 left-0"
                      alt=""
                    />
                  </div>
                  <div class="w-full text-white mt-7">
                    <h3
                      class="capitalize font-belgiano text-[1rem] md:text-[26px] tracking-[1.4px]"
                    >
                      Intuitive Dashboard
                    </h3>
                    <p class="tracking-[1.8px] mt-3 md:text-base text-xs">
                      Stay connected and in control with our intuitive
                      dashboard, designed to put everything at your fingertips.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack md:py-[120px] md:block hidden">
        <div class="w-[90%] max-w-xl mx-auto">
          <div
            class="md:w-[90%] w-full mx-auto flex flex-col md:flex-row items-stretch md:gap-6 gap-7"
          >
            <img
              src="@/assets/images/panther/dashScreens.png"
              class="w-full md:w-3/5"
              alt=""
            />
            <div class="relative w-full md:w-2/5 tb-grad">
              <div class="md:mt-[50%]">
                <!-- <div
                  class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full"
                >
                  <span
                    class="w-7 h-[20px] inline-block bg-white bg-opacity-20 rounded-[100%]"
                  ></span>
                </div> -->
                <div
                  class="relative left-0 right-0 w-full text-white md:absolute md:top-1/2 md:px-4"
                >
                  <h3
                    class="capitalize font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:mt-4"
                  >
                    Intuitive Dashboard
                  </h3>
                  <p class="tracking-[1.8px] mt-3 md:text-base text-xs">
                    Stay connected and in control with our advanced driving
                    system and intuitive dashboard, designed to put everything
                    at your fingertips.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-white py-[120px]">
        <div class="w-full max-w-[1550px] mx-auto">
          <div
            class="md:w-[95%] w-[90%] mx-auto flex md:flex-row flex-col-reverse items-center md:gap-6 gap-7"
          >
            <div class="relative w-full md:w-2/5">
              <div>
                <div class="w-full md:px-8">
                  <h3
                    class="capitalize text-realBlack font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:mt-4"
                  >
                    Drive Immersed
                  </h3>
                  <p
                    class="md:text-base text-xs tracking-[1.8px] mt-3 text-newGrey"
                  >
                    Step inside and be enveloped by our masterful interior
                    design and craftsmanship. Featuring a 4D music cockpit, and
                    a 1.337 m2 panoramic sunroof, every detail has been
                    carefully considered to create a driving experience that's
                    as comfortable as it is exhilarating.
                  </p>
                </div>
              </div>
            </div>
            <img
              src="@/assets/images/panther/cockpit.png"
              class="w-full md:w-2/3"
              alt=""
            />
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack md:py-[120px] pt-9">
        <div class="w-full max-w-[1550px] mx-auto">
          <div
            class="md:w-[95%] w-[90%] mx-auto flex flex-col-reverse md:flex-row items-stretch md:gap-6 gap-7"
          >
            <div class="relative w-full md:w-2/5 bt-grad">
              <div>
                <div class="w-full md:px-8 md:mt-[120px]">
                  <h3
                    class="capitalize text-white font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:mt-4"
                  >
                    All-Round Comfort
                  </h3>
                  <p
                    class="md:text-base text-xs tracking-[1.8px] mt-3 text-white"
                  >
                    With Two doors and four seats Loftywide-body design ((aspect
                    ratio of 1.40), the cabin is bathed in a soothing ambiance,
                    ample leg-room with subtle lighting and intuitive controls
                    that put you firmly in command. Our interior is the perfect
                    blend of style, sophistication, and serenity
                  </p>
                </div>
              </div>
            </div>
            <img
              src="@/assets/images/panther/sideView.png"
              class="w-full md:w-2/3"
              alt=""
            />
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack md:py-[120px] pt-11 pb-9">
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="w-[90%] md:w-[95%] mx-auto">
            <img
              src="@/assets/images/panther/frontSeats.png"
              alt=""
              class="w-full"
            />
            <div class="w-full bt-grad">
              <div
                class="md:w-[80%] w-full mx-auto max-w-xl flex md:flex-row flex-col items-center md:gap-8 md:py-8 pt-7"
              >
                <h3
                  class="capitalize text-white font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:w-2/12 w-full"
                >
                  Front Seat Heating
                </h3>
                <p
                  class="md:text-base text-xs tracking-[1.8px] text-white md:w-10/12 w-full mt-3 md:mt-0"
                >
                  On a chilly morning or a cold raining's day, our advanced
                  heating system warms your seat to the perfect temperature,
                  providing soothing comfort and relief. Say goodbye to cold
                  seats and hello to a warm welcome every time you get behind
                  the wheel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full">
        <img class="w-full" src="@/assets/images/panther/seat.png" alt="" />
      </section>
      <section class="w-full bg-white md:py-[120px] pt-9 pb-6">
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="w-[90%] md:w-[95%] mx-auto relative">
            <img
              src="@/assets/images/panther/sideDoor.png"
              alt=""
              class="w-full"
            />
            <div
              class="relative left-0 right-0 w-full mt-3 bt-grad md:absolute md:bottom-0 md:mt-0"
            >
              <div
                class="md:w-[80%] w-full mx-auto max-w-xl flex flex-col md:flex-row items-start md:gap-8 gap-3 md:pt-8 md:pb-6"
              >
                <h3
                  class="capitalize md:text-white text-realBlack font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] w-full md:w-2/12"
                >
                  resilient
                </h3>
                <p
                  class="md:text-base text-xs tracking-[1.8px] md:text-white text-realBlack md:w-10/12 w-full"
                >
                  Inspired by the Amur Leopard's resilience, the Panther car
                  combines robust build and sleek design, navigating diverse
                  environments with grace and precision. Its agile performance
                  honors its feline namesake, making it a formidable presence on
                  the road.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full md:pt-[120px] py-9 md:pb-[184px]">
        <div class="md:w-full relative w-[90%] mx-auto">
          <div class="w-full relative hidden md:block">
            <img
              src="@/assets/images/panther/bonnetAndTrunk.png"
              class="w-full hidden md:block"
              alt=""
            />
            <img
              src="@/assets/images/panther/chassis.png"
              class="w-full md:hidden block mb-4"
              alt=""
            />
            <div
              class="md:absolute relative md:-top-16 left-0 right-0 md:-bottom-16 flex items-start justify-between md:flex-col flex-col-reverse md:gap-0 gap-9"
            >
              <div
                class="md:w-[95%] w-full relative flex justify-end max-w-[1550px] mx-auto"
              >
                <div class="md:w-1/2 w-full md:pl-20">
                  <h3
                    class="capitalize text-realBlack font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:mt-4"
                  >
                    Ample Trunk Space
                  </h3>
                  <p
                    class="md:text-base text-xs tracking-[1.8px] mt-3 text-newGrey"
                  >
                    Whether you're packing for a road trip, hauling gear for
                    your favorite hobby, or simply need room for groceries and
                    luggage, our ample trunk space has got you covered.
                  </p>
                </div>
              </div>
              <div
                class="md:w-[95%] w-full relative flex justify-start max-w-[1550px] mx-auto"
              >
                <div class="md:w-1/2 w-full md:pr-20">
                  <h3
                    class="capitalize text-realBlack font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:mt-4"
                  >
                    Electric Chassis
                  </h3>
                  <p
                    class="md:text-base text-xs tracking-[1.8px] mt-3 text-newGrey"
                  >
                    With its streamlined design and advanced materials, our
                    electric chassis sets a new standard for performance,
                    efficiency, and sustainability on the road.
                  </p>
                </div>
              </div>
            </div>
            <img
              src="@/assets/images/panther/trunk.png"
              class="w-full md:hidden block mt-4"
              alt=""
            />
          </div>
          <div class="w-full overflow-x-auto md:hidden block no-scrollbar">
            <div class="w-[180%] flex items-start gap-6">
              <div class="w-full">
                <img
                  src="@/assets/images/panther/chassis.png"
                  class="w-full mb-4"
                  alt=""
                />
                <div class="w-full">
                  <h3
                    class="capitalize text-realBlack font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:mt-4"
                  >
                    Electric Chassis
                  </h3>
                  <p
                    class="md:text-base text-xs tracking-[1.8px] mt-3 text-newGrey"
                  >
                    With its streamlined design and advanced materials, our
                    electric chassis sets a new standard for performance,
                    efficiency, and sustainability on the road.
                  </p>
                </div>
              </div>
              <div class="w-full">
                <div class="w-full">
                  <h3
                    class="capitalize text-realBlack font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:mt-4"
                  >
                    Ample Trunk Space
                  </h3>
                  <p
                    class="md:text-base text-xs tracking-[1.8px] mt-3 text-newGrey"
                  >
                    Whether you're packing for a road trip, hauling gear for
                    your favorite hobby, or simply need room for groceries and
                    luggage, our ample trunk space has got you covered.
                  </p>
                </div>
                <img
                  src="@/assets/images/panther/trunk.png"
                  class="w-full md:hidden block mt-4"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full">
        <img
          class="w-full"
          src="@/assets/images/panther/frontWheel.png"
          alt=""
        />
      </section>
      <section class="w-full bg-white md:py-[120px] py-9">
        <div class="w-[90%] max-w-xl mx-auto">
          <div
            class="md:w-[90%] w-full mx-auto flex flex-col md:flex-row items-end md:gap-6 gap-7"
          >
            <img
              src="@/assets/images/panther/frontAngle.png"
              class="w-full md:w-3/5"
              alt=""
            />
            <div class="relative w-full md:w-2/5">
              <div class="w-full text-realbg-realBlack m:px-4 md:pb-11">
                <h3
                  class="capitalize font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:mt-4"
                >
                  Built for performance
                </h3>
                <p class="md:text-base text-xs tracking-[1.8px] mt-3">
                  With a powerful electric motor, precision-tuned suspension,
                  and advanced aerodynamics, our vehicle is built to respond to
                  every command, hugging the road and unleashing a rush of
                  adrenaline with every acceleration
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="md:w-[95%] w-[90%] max-w-[1550px] mx-auto">
        <div class="w-full max-w-xl mx-auto md:pl-8">
          <h2 class="capitalize font-belgiano text-[1rem] md:text-[56px]">
            Go The Distance
          </h2>
        </div>
        <img
          src="@/assets/images/panther/distance.png"
          alt=""
          class="w-full mt-4 md:mt-8"
        />
        <div class="w-full max-w-xl mx-auto md:pl-8">
          <p
            class="md:text-base text-xs tracking-[1.8px] md:mt-9 mt-4 md:w-2/3 w-full"
          >
            With a single charge, it can travel up to 580 km, effortlessly
            covering long routes like Lagos to Onitsha (453 km) and still having
            plenty of juice left to spare. Experience the freedom to go further,
            without worrying about running out of charge.
          </p>
        </div>
      </section>
      <section
        class="md:w-[95%] w-[90%] max-w-[1550px] mx-auto md:pt-[120px] pt-9"
      >
        <div class="w-full max-w-xl md:pl-8">
          <h2 class="capitalize font-belgiano text-[1rem] md:text-[56px]">
            View Our Spectrum
          </h2>
        </div>
      </section>
      <section class="relative w-full overflow-hidden" ref="swipeArea">
        <div
          class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center z-[-1]"
        >
          <!-- <img
            src="@/assets/svgs/greyLogo.svg"
            class="w-full opacity-5"
            alt="Qoray"
          /> -->
          <h1
            class="w-full text-newGrey md:text-9xl text-7xl scale-125 font-belgiano font-black text-center md:scale-[2] opacity-5"
          >
            PANTHER
          </h1>
        </div>
        <div
          class="md:w-full w-[95%] mx-auto flex items-center justify-center mt-20 switchCont"
        >
          <img
            :src="spectrum[activeSpectrum].car"
            class="w-full mx-auto md:w-1/2"
            alt=""
          />
        </div>
      </section>
      <section class="w-[95%] max-w-[1550px] mx-auto md:pb-[120px] pb-9">
        <div class="w-[80%] mx-auto max-w-xl mt-9">
          <div class="flex items-center justify-center w-full">
            <div>
              <p class="text-sm text-newGrey opacity-65">Available colors</p>
              <div class="flex items-center w-full gap-3 mt-1">
                <img
                  class="w-10 h-10 overflow-hidden rounded-full cursor-pointer"
                  :class="[
                    activeSpectrum === index ? 'border-[3px] border-green' : '',
                  ]"
                  v-for="(col, index) in spectrum"
                  :key="index"
                  :src="col.icon"
                  @click="changeCarTo(index)"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="md:w-[95%] w-[90%] max-w-[1550px] mx-auto py-[60px]">
        <div class="md:w-[80%] w-full mx-auto max-w-xl">
          <div class="w-full">
            <div class="w-full">
              <h6 class="text-lg font-belgiano md:text-2xl mb-2">Specs</h6>
              <div
                class="flex md:grid md:grid-cols-3 items-start flex-wrap w-full justify-between gap-y-4 md:gap-8"
              >
                <div
                  v-for="spec in showAll ? specs : specs.slice(0, 6)"
                  :key="spec.title"
                  class="w-fit md:min-w-fit min-w-[50%] px-2 py-1 md:px-5 md:py-4"
                >
                  <p
                    class="w-full text-sm text-newGrey whitespace-nowrap opacity-65"
                  >
                    {{ spec.title }}
                  </p>
                  <p class="w-full whitespace-nowrap text-realBlack">
                    <!-- <br /> -->
                    {{ spec.value }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full" v-if="showAll">
              <div
                class="w-full mt-8"
                v-for="other in otherSpecs"
                :key="other.title"
              >
                <h6 class="text-lg font-belgiano md:text-2xl mb-2">
                  {{ other.title }}
                </h6>
                <div
                  class="flex flex-wrap items-start w-full gap-4 pl-2 mt-2 md:px-7 md:pl-0 md:gap-8"
                >
                  <div
                    class="vehSpec"
                    v-for="otherValue in other.values"
                    :key="otherValue"
                  >
                    <span class="bigDot"></span>
                    <p>{{ otherValue }}</p>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <div class="px-2 py-4 mt-3">
                  <p class="text-lg">
                    <span class="text-sm text-newGrey opacity-65"
                      >Warranty</span
                    >
                    <br />
                    36 Months
                  </p>
                </div>
                <div class="w-full mt-3">
                  <div
                    class="inline-block py-2 pl-3 pr-4 bg-green bg-opacity-10"
                  >
                    <p class="font-inter leading-[26px] text-green">
                      Home charging available
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full">
              <button
                v-if="!showAll"
                @click="showAll = true"
                class="black-btn long mt-7"
              >
                See More
              </button>
              <div class="flex items-center justify-between w-full" v-else>
                <router-link :to="{ name: 'pantherOrderNow' }">
                  <button class="black-btn long mt-7">Order Now</button>
                </router-link>
                <button
                  class="white-btn long mt-7 black-border"
                  @click="showAll = false"
                >
                  See Less
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import col1 from "@/assets/images/panther/colors/1.png";
import col2 from "@/assets/images/panther/colors/2.png";
import col3 from "@/assets/images/panther/colors/3.png";
import col4 from "@/assets/images/panther/colors/4.png";
import col5 from "@/assets/images/panther/colors/5.png";
import car1 from "@/assets/images/panther/colors/1car.png";
import car2 from "@/assets/images/panther/colors/2car.png";
import car3 from "@/assets/images/panther/colors/3car.png";
import car4 from "@/assets/images/panther/colors/4car.png";
import car5 from "@/assets/images/panther/colors/5car.png";
export default {
  data() {
    return {
      lhero: false,
      showAll: false,
      swipe: {
        touchstartX: 0,
        touchendX: 0,
      },
      specs: [
        { title: "Top Speed", value: "190km/H" },
        { title: "Range", value: "580km" },
        { title: "Battery", value: "Lithium 60v100ah" },
        { title: "Charging", value: "AC/DC" },
        { title: "Length(mm)", value: "4715" },
        { title: "Width(mm)", value: "1979 " },
        { title: "Height (mm)", value: "1415" },
        { title: "Wheelbase(mm)", value: "2770" },
        { title: "Full load Ground Clearance (mm)", value: "≥135" },
        { title: "Onboard Charger", value: "6.6kW" },
        { title: "AC Charging Time (0-100%)", value: "9 hours" },
        { title: "DC Charging Time (30%-80%)", value: "30 minutes" },
        { title: "Motor Type", value: "Permanent magnet synchronous" },
        { title: "Maximum Power (kW)", value: "120" },
        { title: "Maximum Torque (N·m)", value: "210" },
        { title: "Acceleration Time (s) (0-100km/h)", value: "≤9.5" },
        { title: "Charging Port", value: "European Standards" },
        { title: "Drive Type", value: "Front-wheel drive" },
        { title: "Front Suspension", value: "MacPherson" },
        { title: "Rear Suspension", value: "Multi-link" },
        { title: "Tire Specifications", value: "225/60R18" },
        { title: "Front Brake Type", value: "Ventilated disc" },
        { title: "Rear Brake Type", value: "Solid disc" },
        { title: "Steering Assist Modes", value: "Standard/Sport" },
        { title: "Driving Mode Selection", value: "ECO/SPORT/COMFORT" },
        { title: "Manual Adjustable Steering Wheel", value: "4 directions" },
        { title: "Driver's Seat Adjustment", value: "Electric 6-direction" },
        {
          title: "Front Passenger Seat Adjustment",
          value: "Electric 6-direction",
        },
        { title: "Front-Row Seatbelts", value: "Pre-tensioning force limit" },
        {
          title: "Second-Row Seatbelts",
          value: "Pre-tensioning force limit (left/right)",
        },
        {
          title: "Front Seat Usage Status Monitoring",
          value: "Front passenger seat",
        },
        { title: "Central Control Screen Size（Inch）", value: "15.6" },
        { title: "Voice Control", value: "English" },
        { title: "In-Car WiFi", value: "(Connect only)" },
        { title: "Mobile Phone Mirroring**", value: "CarPlay" },
        { title: "Number of Speakers", value: "8" },
      ],
      otherSpecs: [
        {
          title: "Power System",
          values: [
            "Vehicle-to-Load Function (V2L)",
            "Battery Low-Temperature Preheating System",
            "Battery Intelligent Thermal Management System",
          ],
        },
        {
          title: "Chassis System",
          values: [
            "Electric Power Steering (EPS)",
            "Electric Parking Brake (EPB)",
            "Autohold",
            "Adjustable Brake Energy Regeneration Intensity",
            "One-Pedal Driving Mode",
            "Emergency Tire Repair Kit (Tire Sealant)",
          ],
        },
        {
          title: "Exterior",
          values: [
            "Panoramic Sunroof",
            "Electric Sunshade",
            "Roof Rack",
            "Intelligent Remote Key",
            "Smartphone App Key",
            "Passive Entry (PE)",
            "Passive Start (PS)",
            "Exterior Mirror Electric Adjustment",
            "Exterior Mirror Auto Folding",
            "Exterior Mirror Heating",
            "Exterior Mirror with Turning Signal",
            "Manual Diming Rearview Mirror",
            "Rear Window Defroster",
            "Frameless Front Wipers",
            "Rear Wiper",
            "Automatic Rain-Sensing Wipers",
          ],
        },
        {
          title: "Light",
          values: [
            "LED Headlights",
            "LED Taillights",
            "LED Daytime Running Lights",
            "Automatic Headlights",
            "Rear Fog Light",
            "LED High-Mounted Brake Light",
            "Follow Me Home Function",
            "Front Row Roof LED Reading Lights",
            "Second Row Roof LED Reading Lights",
            "Front Row Reading Lights with Touch Control",
            "Second Row Reading Lights with Touch Control",
            "Trunk Light",
          ],
        },
        {
          title: "Interior",
          values: [
            "Electronic Column Shift",
            "Artificial Leather Steering Wheel",
            "Front 12V Power Outlet",
            "USB Ports (Front Row)",
            "Type-C Ports (Front Row)",
            "USB Ports (Second Row)",
            "Type-C Ports (Second Row)",
            "Wireless Phone Charging",
            "Trunk 12V Power Outlet",
            "All Windows One-Touch Up/Down with Anti-Pinch",
            "Sunvisors with Vanity Mirror",
            "Sunvisors with Light",
          ],
        },
        {
          title: "Seat",
          values: [
            "Artificial Leather Seats",
            "Front Seat Heating",
            "Front Central Armrest",
            "Second-Row Seat Back Folding Down",
            "Second-Row Seat Central Armrest",
            "Second-Row Central Headrest",
          ],
        },
        {
          title: "Air Conditioning",
          values: [
            "Automatic Air Conditioning",
            "Second-Row Air Conditioning Air Outlets",
            "Interior Air Adjustment/Pollen Filtration",
            "HEPA (N95) Filter",
          ],
        },
        {
          title: "Safety",
          values: [
            "ABS + EBD",
            "Brake Assist (EBA/BAS/BA, etc.)",
            "Electronic Stability Control (ESC/ESP, etc.)",
            "Traction Control (ASR/TCS/TRC, etc.)",
            "Limp-Home Mode",
            "Hill Hold Control (HHC)",
            "Hill Descent Control (HDC)",
            "Driver/Passenger Front Airbags",
            "Front Side Airbags",
            "Side Curtain Airbags",
            "Direct Tire Pressure Monitoring System",
            "Height Adjustable Front-Row Seatbelts",
            "Driver's Seatbelt Unfastened Reminder",
            "Front Passenger's Seatbelt Unfastened Reminder",
            "Second-Row Seatbelt Unfastened Reminder",
            "Second-Row Seat Usage Status Monitoring",
            "Second-Row Seats With ISOFIX/LATCH",
            "Automatic Unlocking in Case of Collision",
            "Rear Parking Radar",
            "Reverse Camera with Auxiliary Guidelines",
            "Around View Monitor (AVM)",
            "Transparent Chassis*",
          ],
        },
        {
          title: "Advanced Driving Assistance System",
          values: [
            "Cruise Control (CC)",
            "Intelligent Connection",
            "Segmented Digital Instrument Panel",
            "Real-Time Navigation System",
            "Location Services",
            "OTA Online Upgrade",
            "APP Car Search",
            "Pedestrian Warning Sound at Low-Speed",
            "Online Music",
            "FM/AM",
            "Bluetooth Music/Call",
            "APP Control",
          ],
        },
      ],
      spectrum: [
        {
          icon: col1,
          car: car1,
        },
        {
          icon: col2,
          car: car2,
        },
        {
          icon: col3,
          car: car3,
        },
        {
          icon: col4,
          car: car4,
        },
        {
          icon: col5,
          car: car5,
        },
      ],
      activeSpectrum: 0,
    };
  },
  computed: {
    mobile() {
      return window.innerWidth <= 768;
    },
  },
  methods: {
    handleSwipe() {
      if (this.swipe.touchendX < this.swipe.touchstartX) {
        if (this.activeSpectrum < this.spectrum.length - 1) {
          this.changeCarTo(this.activeSpectrum + 1);
        }
      }
      if (this.swipe.touchendX > this.swipe.touchstartX) {
        if (this.activeSpectrum > 0) {
          this.changeCarTo(this.activeSpectrum - 1, true);
        }
      }
    },
    changeCarTo(index, reverse = false) {
      const changeTo = (index) => {
        this.activeSpectrum = index;
      };
      if (reverse) {
        if (this.activeSpectrum !== 0) {
          gsap.to(".switchCont", {
            xPercent: 110,
            duration: 0.4,
            ease: "Power4.in",
            onComplete() {
              changeTo(index);
              gsap.set(".switchCont", {
                xPercent: -110,
                onComplete() {
                  gsap.to(".switchCont", {
                    xPercent: 0,
                    duration: 0.6,
                    ease: "Power4.out",
                  });
                },
              });
            },
          });
        }
      } else {
        if (this.activeSpectrum !== index) {
          gsap.to(".switchCont", {
            xPercent: -110,
            duration: 0.4,
            ease: "Power4.in",
            onComplete() {
              changeTo(index);
              gsap.set(".switchCont", {
                xPercent: 110,
                onComplete() {
                  gsap.to(".switchCont", {
                    xPercent: 0,
                    duration: 0.6,
                    ease: "Power4.out",
                  });
                },
              });
            },
          });
        }
      }
    },
  },
  watch: {
    lhero() {
      if (this.lhero === true) {
        this.$refs.swipeArea.addEventListener("touchstart", (e) => {
          this.swipe.touchstartX = e.changedTouches[0].screenX;
        });
        this.$refs.swipeArea.addEventListener("touchend", (e) => {
          this.swipe.touchendX = e.changedTouches[0].screenX;
          this.handleSwipe();
        });
      }
    },
  },
};
</script>

<style scoped  >
.heroBG {
  background-image: url("@/assets/images/panther/hero.png");
  background-position: bottom right;
  background-size: cover;
}
@media (max-width: 768px) {
  .heroBG {
    background-image: url("@/assets/images/panther/mobHero.png");
  }
}
.specBack {
  background-image: url("@/assets/svgs/greyLogo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.tb-grad {
  /* background: linear-gradient(180deg, #111111 0%, rgba(0, 0, 0, 0) 100%); */
}
.bt-grad {
  /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #111111 100%); */
}
.vehSpec {
  @apply flex items-center gap-3 md:w-auto w-full;
}
.bigDot {
  @apply inline-block w-2 h-2 rounded-full bg-green bg-opacity-65;
}
</style>
