<template>
  <div class="w-[60%]">
    <div class="">
      <img src="@/assets/images/nav/qoreLogo.png" alt="" class="w-full" />
      <router-link
        :to="{ name: 'about' }"
        class="block w-full mt-8 text-sm font-bold text-center underline capitalize text-realBlack font-inter"
        >About Qoray</router-link
      >
    </div>
    <div class="">
      <img src="@/assets/images/nav/resource.png" alt="" class="w-full" />
      <router-link
        :to="{ name: 'tour' }"
        class="block w-full mt-8 text-sm font-bold text-center underline capitalize text-realBlack font-inter"
        >Tour with Qoray</router-link
      >
    </div>
    <div class="">
      <img src="@/assets/images/nav/resource.png" alt="" class="w-full" />
      <router-link
        :to="{ name: 'impact' }"
        class="block w-full mt-8 text-sm font-bold text-center underline capitalize text-realBlack font-inter"
        >Sustainability Impact</router-link
      >
    </div>
    <hr class="border-t-[#E2E3E3] border-t my-8" />
    <div class="flex flex-col items-start gap-6 pt-3 md:pr-12 md:mb-0 relative">
      <router-link
        :to="{ name: 'home' }"
        class="block text-sm font-bold text-left text-realBlack font-inter"
        >Download our brochure</router-link
      >
      <router-link
        :to="{ name: 'home' }"
        class="block text-sm font-bold text-left text-realBlack font-inter"
        >Qoray in the news</router-link
      >
      <router-link
        :to="{ name: 'home' }"
        class="block text-sm font-bold text-left text-realBlack font-inter"
        >Articles</router-link
      >
      <router-link
        :to="{ name: 'home' }"
        class="block text-sm font-bold text-left text-realBlack font-inter"
        >Media Gallery</router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>