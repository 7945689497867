<template>
  <div
    class="fixed top-0 left-0 w-full h-screen flex items-center justify-center z-50"
  >
    <div class="bg-[#1B1B1B] absolute top-0 left-0 w-full h-screen z-0"></div>
    <div class="bg-[#1B1B1B] absolute top-0 left-0 w-full h-screen z-0"></div>
    <img src="@/assets/images/miniLogo.png" class="z-[1] zoom" alt="Logo" />
    <!-- <div class="flex flex-col items-center justify-between gap-4"> -->
    <!-- <span
        class="inline-flex items-center w-[30px] h-[30px] bg-green rounded-full rott"
      >
        <span class="bg-[#1B1B1B] w-[15px] h-[2px]"></span>
      </span> -->
    <!-- </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped >
.zoom {
  animation: zoom 2.5s linear infinite;
}
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
  /* 100% {
    transform: scale(0.6);
  } */
}
</style>