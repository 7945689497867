<template>
  <div class="w-full">
    <!-- PRELOAD -->

    <!-- v-else -->
    <img
      src="@/assets/images/caspian/hero.png"
      class="fixed w-px h-px opacity-0 -z-10"
      alt=""
      ref="heroPic"
      @load="lhero = true"
    />
    <img
      class="fixed w-px h-px opacity-0 -z-10"
      v-for="(col, index) in spectrum"
      :key="index"
      :src="col.car"
      alt=""
    />
    <!-- PRELOAD -->
    <div
      v-if="!lhero"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen"
    >
      <div
        class="bg-[#1B1B1B] absolute load1 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div
        class="bg-[#1B1B1B] absolute load2 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div class="img1">
        <img
          src="@/assets/images/miniLogo.png"
          class="z-[1] zoom img1"
          alt="Logo"
        />
      </div>
    </div>
    <div class="w-full">
      <section
        class="relative w-full heroBG md:min-h-0 min-h-screen md:max-h-none max-h-screen"
      >
        <img
          src="@/assets/images/caspian/hero.png"
          class="w-full md:block hidden"
          alt=""
        />
        <div class="absolute top-0 right-0 left-0">
          <div
            class="min-h-screen max-h-none md:w-[90%] w-[95%] max-w-xl mx-auto h-full flex flex-col items-start justify-between md:pt-[20vh] md:pl-10 pt-[15vh] pb-[10vh]"
          >
            <!-- <div class="hidden w-full md:block"></div> -->
            <div class="w-full">
              <h1 class="font-belgiano md:text-[80px] text-[36px]">
                Caspian<span class="text-green">.</span>
              </h1>
              <p class="text-xs font-inter md:text-2xl">
                Starting from ₦34 million
              </p>
              <div class="flex items-center gap-6 mt-3">
                <p class="text-xs font-inter md:text-lg">10% down payment</p>
                <div class="w-px h-4 bg-realBlack"></div>
                <p class="text-xs font-inter md:text-lg">Spread over 5 years</p>
              </div>
              <router-link :to="{ name: 'order-caspian' }">
                <button
                  class="black-btn w-[clamp(9.375rem,5.869vw+7.174rem,15.625rem)] mt-7"
                >
                  Order Now
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="ovl absolute bottom-0 left-0 right-0 h-[12vh]"></div>
      </section>
      <section class="w-full bg-realBlack">
        <div
          class="flex flex-wrap justify-between items-center md:w-[90%] w-[95%] max-w-xl mx-auto py-20 md:gap-4 gap-y-6 md:flex-nowrap"
        >
          <div class="text-center md:w-1/4 w-[50%]">
            <p
              class="font-inter text-white text-xs uppercase tracking-[1.25px]"
            >
              Dimension
            </p>
            <p class="mt-1 text-sm font-inter text-white md:text-xl">
               L:4070 x W:1690 x H:1540
            </p>
          </div>
          <!-- <div class="w-px h-4 md:block hidden bg-white bg-opacity-40"></div> -->
          <div class="text-center md:w-1/4 w-[45%]">
            <p
              class="font-inter text-white text-xs uppercase tracking-[1.25px]"
            >
              Top Speed
            </p>
            <p class="mt-1 text-sm font-inter text-white md:text-xl">101Km/H</p>
          </div>
          <!-- <div class="w-px h-4 md:block hidden bg-white bg-opacity-40"></div> -->
          <div class="text-center md:w-1/4 w-[45%]">
            <p
              class="font-inter text-white text-xs uppercase tracking-[1.25px]"
            >
              Range
            </p>
            <p class="mt-1 text-sm font-inter text-white md:text-xl">401Km</p>
          </div>
          <!-- <div class="w-px h-4 md:block hidden bg-white bg-opacity-40"></div> -->
          <div class="text-center md:w-1/4 w-[45%]">
            <p
              class="font-inter text-white text-xs uppercase tracking-[1.25px]"
            >
              Battery
            </p>
            <p class="mt-1 text-sm font-inter text-white md:text-xl">
              37.27(KWh)
            </p>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack text-white">
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="md:w-[70%] w-full mx-auto">
            <img
              src="@/assets/images/caspian/cockpit.png"
              alt=""
              class="w-full"
            />
            <div class="w-full max-w-[1550px] mx-auto">
              <div class="md:w-full w-[95%] mx-auto">
                <div class="w-full md:pt-5 pt-3 pb-4 md:pb-8">
                  <h3
                    class="capitalize font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] w-full"
                  >
                    Drive Harmoniously
                  </h3>
                  <p class="md:text-base text-xs tracking-[1.8px] w-full mt-3">
                    Experience the perfect blend of technology and sound in the
                    Caspian. Enjoy crystal-clear visuals on the class-leading
                    14.6” hi-res touchscreen, immersive audio from the 6-speaker
                    system, and added safety with the rear parking camera. Every
                    drive is a harmonious fusion of innovation and
                    entertainment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack block">
        <div class="w-full overflow-x-auto">
          <img
            src="@/assets/images/caspian/trunk.png"
            class="md:w-full w-[200%] md:min-w-0 min-w-[200%]"
            alt=""
          />
        </div>
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="md:w-[95%] w-full mx-auto">
            <div class="md:w-full w-[95%] py-20 mx-auto">
              <div class="md:w-1/2 w-full max-w-xl">
                <h3
                  class="capitalize text-white font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] w-full"
                >
                  Park Your Dreams
                </h3>
                <p
                  class="md:text-base text-xs tracking-[1.8px] text-white w-full mt-3"
                >
                  Caspian's spacious trunk and flexible seating let you bring
                  everything you need for your next adventure. With 335L of
                  cargo space that expands to 588L when the rear seats are
                  folded down, you'll have room for all your gear - and your
                  imagination.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-white">
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="md:w-[95%] w-full mx-auto">
            <div class="md:w-full w-[95%] md:py-20 py-12 mx-auto">
              <div class="w-full grid grid-cols-2 gap-4">
                <div>
                  <img
                    src="@/assets/images/caspian/tyre.png"
                    alt=""
                    class="w-full"
                  />
                </div>
                <div>
                  <img
                    src="@/assets/images/caspian/charger.png"
                    alt=""
                    class="w-full"
                  />
                </div>
                <div class="col-span-2">
                  <img
                    src="@/assets/images/caspian/plateView.png"
                    class="w-full"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack block">
        <img
          src="@/assets/images/caspian/backView.png"
          alt=""
          class="w-full md:block hidden"
        />
        <img
          src="@/assets/images/caspian/backView.png"
          alt=""
          class="w-full block md:hidden"
        />
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="md:w-[95%] w-full mx-auto">
            <div class="md:w-full w-[95%] md:py-20 py-10 mx-auto">
              <div class="md:w-3/4 w-full max-w-xl">
                <h3
                  class="capitalize text-white font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] w-full"
                >
                  Weather-Proof Performance
                </h3>
                <p
                  class="md:text-base text-xs tracking-[1.8px] text-white w-full mt-3"
                >
                  The Caspian's HEPT 3.0 system keeps batteries at a consistent
                  temperature, no matter the climate. It maintains a 95%
                  temperature ratio, with cooling and heating functions for
                  optimal performance, range, and longevity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack block">
        <img
          src="@/assets/images/caspian/parkView.png"
          alt=""
          class="w-full hidden md:block"
        />
        <img
          src="@/assets/images/caspian/parkViewMob.png"
          alt=""
          class="md:hidden block w-full"
        />
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="md:w-[95%] w-full mx-auto">
            <div class="md:w-full w-[95%] md:py-20 py-8 mx-auto">
              <div class="md:w-3/4 w-full max-w-xl">
                <h3
                  class="capitalize text-white font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] w-full"
                >
                  Sleek. Sophisticated. Electrifying
                </h3>
                <p
                  class="md:text-base text-xs tracking-[1.8px] text-white w-full mt-3"
                >
                  The Caspian's aerodynamic design is inspired by the dolphin's
                  streamlined physique, reducing wind resistance and maximizing
                  efficiency. The double waistline design adds a touch of
                  sophistication, while the sporty rear spoiler hints at the
                  car's impressive performance capabilities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack">
        <div class="xl:w-[90%] lg:w-[95%] mx-auto md:pt-0 pt-8">
          <div class="md:w-full w-[95%] mx-auto md:pl-8">
            <h2
              class="capitalize font-belgiano text-[1rem] md:text-[56px] text-white"
            >
              Go The Distance
            </h2>
          </div>
          <img
            src="@/assets/images/caspian/distance.png"
            alt=""
            class="w-full mt-4 md:mt-8"
          />
          <div class="md:w-full w-[95%] mx-auto md:pl-8 pb-10">
            <p
              class="md:text-base text-white text-xs tracking-[1.8px] md:mt-9 mt-4 md:w-2/3 w-full"
            >
              With a single charge, it can travel up to 401km, effortlessly
              covering long routes like Lagos to Benin, Edo State (321.1 km) and
              still having plenty of juice left to spare. Experience the freedom
              to go further, without worrying about running out of charge.
            </p>
          </div>
        </div>
      </section>
      <section class="w-[95%] max-w-[1550px] mx-auto md:pt-[120px] pt-9">
        <div class="w-full max-w-xl md:pl-8">
          <h2 class="capitalize font-belgiano text-[1rem] md:text-[56px]">
            View Our Spectrum
          </h2>
        </div>
      </section>
      <section class="relative w-full overflow-hidden" ref="swipeArea">
        <div
          class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center z-[-1]"
        >
          <!-- <img
            src="@/assets/svgs/greyLogo.svg"
            class="w-full opacity-5"
            alt="Qoray"
          /> -->
          <h1
            class="w-full text-newGrey md:text-9xl text-7xl scale-125 font-belgiano font-black text-center md:scale-[2.5] opacity-5"
          >
            CASPIAN
          </h1>
        </div>
        <div
          class="md:w-full w-[95%] mx-auto flex items-center justify-center mt-20 switchCont"
        >
          <img
            :src="spectrum[activeSpectrum].car"
            class="w-full mx-auto md:w-1/2"
            alt=""
          />
        </div>
      </section>
      <section class="w-[95%] max-w-[1550px] mx-auto md:pb-[120px] pb-9">
        <div class="w-[80%] mx-auto max-w-xl mt-9">
          <div class="flex items-center justify-center w-full">
            <div>
              <p class="text-sm text-newGrey opacity-65">Available colors</p>
              <div class="flex items-center w-full gap-3 mt-1">
                <img
                  class="w-10 h-10 overflow-hidden rounded-full cursor-pointer"
                  :class="[
                    activeSpectrum === index ? 'border-[3px] border-green' : '',
                  ]"
                  v-for="(col, index) in spectrum"
                  :key="index"
                  :src="col.icon"
                  @click="changeCarTo(index)"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full">
        <div class="w-[95%] max-w-[1550px] mx-auto py-[60px]">
          <div class="md:w-[80%] w-full mx-auto max-w-xl">
            <div class="w-full">
              <div class="w-full">
                <h6 class="text-lg font-belgiano md:text-2xl mb-2">Specs</h6>
                <div
                  class="flex md:grid md:grid-cols-3 items-start flex-wrap w-full justify-between gap-y-4 md:gap-8"
                >
                  <div
                    v-for="spec in showAll ? specs : specs.slice(0, 6)"
                    :key="spec.title"
                    class="w-fit md:min-w-fit min-w-[50%] px-2 py-1 md:px-5 md:py-4"
                  >
                    <p class="w-full text-sm whitespace-nowrap">
                      {{ spec.title }}
                    </p>
                    <p class="w-full whitespace-nowrap">
                      <!-- <br /> -->
                      {{ spec.value }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full" v-if="showAll">
                <div
                  class="w-full mt-8"
                  v-for="other in otherSpecs"
                  :key="other.title"
                >
                  <h6 class="text-lg font-belgiano md:text-2xl mb-2">
                    {{ other.title }}
                  </h6>
                  <div
                    class="flex flex-wrap items-start w-full gap-4 pl-2 mt-2 md:px-7 md:pl-0 md:gap-8"
                  >
                    <div
                      class="vehSpec"
                      v-for="otherValue in other.values"
                      :key="otherValue"
                    >
                      <span class="bigDot"></span>
                      <p>{{ otherValue }}</p>
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="px-2 py-4 mt-3">
                    <p class="text-lg">
                      <span class="text-sm">Warranty</span>
                      <br />
                      36 Months
                    </p>
                  </div>
                  <div class="w-full mt-3">
                    <div
                      class="inline-block py-2 pl-3 pr-4 bg-green bg-opacity-10"
                    >
                      <p class="font-inter leading-[26px] text-green">
                        Home charging available
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <button
                  v-if="!showAll"
                  @click="showAll = true"
                  class="black-btn long mt-7"
                >
                  See More
                </button>
                <div class="flex items-center justify-between w-full" v-else>
                  <router-link :to="{ name: 'order-caspian' }">
                    <button class="black-btn long mt-7">Order Now</button>
                  </router-link>
                  <button
                    class="white-btn long mt-7 black-border"
                    @click="showAll = false"
                  >
                    See Less
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import col1 from "@/assets/images/caspian/colors/gray.png";
import col2 from "@/assets/images/caspian/colors/blue.png";
import col3 from "@/assets/images/caspian/colors/cyan.png";
import col4 from "@/assets/images/caspian/colors/white.png";
import car1 from "@/assets/images/caspian/colors/Midnight_Gray_Spectrum.png";
import car2 from "@/assets/images/caspian/colors/Sky_Blue.png";
import car3 from "@/assets/images/caspian/colors/Glaze_Cyan.png";
import car4 from "@/assets/images/caspian/colors/Storm_White.png";
export default {
  data() {
    return {
      lhero: false,
      showAll: false,
      swipe: {
        touchstartX: 0,
        touchendX: 0,
      },
      specs: [
        { title: "Body Structure ", value: "5-door, 5-seat SUV" },
        { title: "Length(mm)", value: "4070" },
        { title: "Width(mm)", value: "1690" },
        { title: "Height (mm)", value: "1540" },
        { title: "Wheelbase(mm)", value: "2420" },
        { title: "Full Loaded Ground Clearance (mm", value: "≥120" },
        { title: "Vehicle warranty", value: "5years or 150000km" },
        { title: "EIC system warranty", value: "8years or 180000km" },
        { title: "WLTP  Range(km)", value: "401" },
        { title: "Battery brand", value: "CATL" },
        { title: "Battery", value: "Lithium Iron Sulfate Battery" },
        { title: "Battery capacity (kWh) 1C", value: "40.7" },
        { title: "AC slow charging time (0~100%)", value: "6-8h" },
        { title: "DC fast charging time (30%~80%)", value: "30min" },
        {
          title: " Electric Motor type",
          value: "Permanent magnet synchronization",
        },
        { title: "Electric Motor brand", value: "CRRC" },
        { title: "Motor power (kW)", value: "70" },
        { title: "Motor torque (N·m)", value: "150" },
        { title: "Acceleration(S) (0-50km/h)", value: "≤4.1" },
        { title: "Maximum speed (km/h)", value: "≥101" },
        { title: "Drive Type", value: "FWD" },
        { title: "Front suspension type", value: "Mcpherson" },
        { title: "Rear suspension type", value: "Trailing Arm" },
        { title: "Tire Size", value: "185/55 R16" },
        { title: "Front brake disc type", value: "Solid discs" },
        { title: "Rear brake disc type", value: "Solid discs" },
        { title: "Driving mode", value: "Sport/Standard" },
        { title: "Front windshield", value: "Green glass" },
        { title: "Rear windshield", value: "Green glass" },
        { title: "Front Seat Belts", value: "Emergency Locking Retractor" },
        { title: "Second Row Seat Belt", value: "Emergency Locking Retractor" },
        { title: "Front Seat Status Monitoring", value: " Passenger Seat" },
        { title: "Automotive Center Control Display", value: "14.6" },
        { title: "Car Memory Capacity", value: "64G" },
        { title: "Number of Speakers", value: "6" },
        { title: "Automotive Center Control Display", value: "14.6" },
      ],
      otherSpecs: [
        {
          title: "Powertrain",
          values: [
            "Lithium Iron Sulfate Battery",
            "V2L",
            "12V Battery Auto Charging",
          ],
        },
        {
          title: "Chassis System",
          values: [
            "Front floating caliper",
            "Rear floating caliper",
            "Electronic Power Steering (EPS)",
            "Electronic braking system",
            "AUTOHOLD",
            "One-pedal",
          ],
        },
        {
          title: "Exterior",
          values: [
            "Interior central locking",
            "Remote Control Smart Key (RF)",
            "Keyless entry",
            "Keyless start",
            "Eletric adjustable exterior rearview mirror",
            " Heated exterior mirrors",
            "Manual anti-glare interior mirrors",
            "Defrost the rear windshield",
            "Forecabin Decorative cover",
          ],
        },
        {
          title: "Light",
          values: [
            "LED front combination light",
            "Integrated LED rear taillights throughout",
            "Daytime running lights",
            "Automatic Headlights",
            "Rear fog lights",
            "Charging port indicator",
            "Headlight automatic delay function",
            "LED reading lights Front reading light touch controls",
            "Luggage compartment lights",
            "Door and window keys backlight",
          ],
        },
        {
          title: "Interior",
          values: [
            "Leather steering wheel",
            "Multifunctional steering wheel",
            "Front Row 12V power",
            "USB (Front row)",
            "USB (2nd row)",
            "Wireless power charging",
            "Power windows",
            "Front  Sun Visor with Vanity Mirror",
          ],
        },
        {
          title: "Seat",
          values: [
            "PVC Leather Seats",
            "Driver Seat Adjustment",
            "Passenger Seat Adjustment",
            "Heated Front Seats",
            "Second Row Seat Backrest Folding",
          ],
        },
        {
          title: "Air Conditioning",
          values: ["Automatic Air Conditioning"],
        },
        {
          title: "Safety",
          values: [
            "Antilock Brake System+Electronic Brake force Distribution(ABS+EBD)",
            "Braking Assist (EBA/BAS/BA, etc.)",
            "Body Stability Control (ESC/ESP, etc.)",
            "Traction Control (ASR/TCS/TRC, etc.)",
            "Hill hold control (HHC)",
            "Hill descent control (HDC)",
            "Airbags (Front)",
            " ITPMS",
            "Driver Seat Belt Un-Fastened Reminder",
            "Co-driver Seat Belt Un-Fastened Reminder",
            "Second Row Seat Belt Unbuckled Alert",
            "Second Row Seat  Status Monitoring",
            "Second Row Child Seat Interface (ISOFIX/LATCH compatible)",
            "Mechanical Children Safety Lock",
            "Speed Sensitive Control Locking",
            "Automatic Collision Unlock",
            "Rear Parking Radar",
            "Reverse camera with dynamic auxiliary line",
          ],
        },
        {
          title: "Advanced Driving Assistance System",
          values: [
            "Cruise Control (CC)",
            "Segment Code Instrument Panel",
            "Low-speed Pedestrian Beep",
            "Local FM Radio",
            "CarPlay/AndroidAuto",
            "Bluetooth Music/Phone",
          ],
        },
      ],
      spectrum: [
        {
          icon: col1,
          car: car1,
        },
        {
          icon: col2,
          car: car2,
        },
        {
          icon: col3,
          car: car3,
        },
        {
          icon: col4,
          car: car4,
        },
      ],
      activeSpectrum: 0,
    };
  },
  computed: {
    mobile() {
      return window.innerWidth <= 768;
    },
  },
  methods: {
    handleSwipe() {
      if (this.swipe.touchendX < this.swipe.touchstartX) {
        if (this.activeSpectrum < this.spectrum.length - 1) {
          this.changeCarTo(this.activeSpectrum + 1);
        }
      }
      if (this.swipe.touchendX > this.swipe.touchstartX) {
        if (this.activeSpectrum > 0) {
          this.changeCarTo(this.activeSpectrum - 1, true);
        }
      }
    },
    async changeCarTo(index, reverse = false) {
      const changeTo = async (index) => {
        this.activeSpectrum = index;
      };
      if (reverse) {
        if (this.activeSpectrum !== 0) {
          gsap.to(".switchCont", {
            xPercent: 110,
            duration: 0.4,
            ease: "Power4.in",
            async onComplete() {
              await changeTo(index);
              gsap.set(".switchCont", {
                xPercent: -110,
                onComplete() {
                  gsap.to(".switchCont", {
                    xPercent: 0,
                    duration: 0.6,
                    ease: "Power4.out",
                  });
                },
              });
            },
          });
        }
      } else {
        if (this.activeSpectrum !== index) {
          gsap.to(".switchCont", {
            xPercent: -110,
            duration: 0.4,
            ease: "Power4.in",
            async onComplete() {
              await changeTo(index);
              gsap.set(".switchCont", {
                xPercent: 110,
                onComplete() {
                  gsap.to(".switchCont", {
                    xPercent: 0,
                    duration: 0.6,
                    ease: "Power4.out",
                  });
                },
              });
            },
          });
        }
      }
    },
  },
  watch: {
    lhero() {
      if (this.lhero === true) {
        this.$refs.swipeArea.addEventListener("touchstart", (e) => {
          this.swipe.touchstartX = e.changedTouches[0].screenX;
        });
        this.$refs.swipeArea.addEventListener("touchend", (e) => {
          this.swipe.touchendX = e.changedTouches[0].screenX;
          this.handleSwipe();
        });
      }
    },
  },
};
</script>

<style scoped >
.heroBG {
  background-image: url("@/assets/images/caspian/hero.png");
  background-position: top right;
  background-size: cover;
}
.ovl {
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}
@media (max-width: 768px) {
  .heroBG {
    background-position: 13% bottom;
    background-image: url("@/assets/images/caspian/hero.png");
  }
}
.specBack {
  background-image: url("@/assets/svgs/greyLogo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.vehSpec {
  @apply flex items-center gap-3 md:w-auto w-full;
}
.bigDot {
  @apply inline-block w-2 h-2 rounded-full bg-green bg-opacity-65;
}
</style>
