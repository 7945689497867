<template>
  <div
    class="md:relative absolute bottom-[1vh] flex w-full justify-center"
  >
    <div
      class="flex items-stretch justify-between md:py-0 py-2 max-w-xl md:w-[80%] w-[80%] md:bg-[#F8F8F8] bg-[#f8f8f8f1] md:-mt-[7vh] shad flex-wrap md:flex-nowrap px-2 md:px-0 rounded-lg overflow-hidden"
    >
      <a
        class="md:py-8 block md:pr-0 pr-2 md:pb-8 pb-2 md:w-[25%] w-1/2 md:border-none border-black border-r border-b"
        href="https://drive.google.com/drive/folders/1NBC7aGx7jbyNA0p0zAel1Q8raau9fjMu?usp=sharing"
        target="_blank"
      >
        <div
          class="flex flex-col gap-y-1 md:gap-y-5 items-center justify-center h-full"
        >
          <div
            class="w-9 h-9 md:h-auto md:block flex items-center justify-center md:w-auto overflow-hidden"
          >
            <svg
              class="w-full md:w-auto"
              width="74"
              height="65"
              viewBox="0 0 74 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M61.1028 10.4166H12.7478C11.9561 10.4166 11.3142 11.048 11.3142 11.8268V51.2058C11.3142 51.9847 11.9561 52.6161 12.7478 52.6161H61.1028C61.8945 52.6161 62.5363 51.9847 62.5363 51.2058V11.8268C62.5363 11.048 61.8945 10.4166 61.1028 10.4166Z"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
              <path
                d="M11.4801 42.3685L25.6762 28.4059L43.3821 45.827"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
              <path
                d="M38.9838 41.4099L50.0314 30.5417L62.6311 42.9367"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
              <path
                d="M21.9028 21.3605H51.9448"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
            </svg>
          </div>
          <div class="">
            <p class="w-full text-center text-realBlack md:text-base text-xs">
              View Brochure
            </p>
          </div>
        </div>
      </a>
      <div class="w-[1px] self-center h-[70%] bg-black md:block hidden"></div>
      <a
        class="md:py-8 block md:pl-0 pl-2 md:pb-8 pb-2 md:w-[25%] w-1/2 md:border-none border-black border-b"
        href="https://forms.office.com/r/gP5HDQ0eH8"
        target="_blank"
      >
        <div
          class="flex flex-col gap-y-1 md:gap-y-5 items-center justify-center h-full"
        >
          <div
            class="w-9 h-9 md:h-auto md:block flex items-center justify-center md:w-auto overflow-hidden"
          >
            <svg
              class="w-full md:w-auto"
              width="83"
              height="75"
              viewBox="0 0 83 75"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.538 30.5397C16.1972 30.8337 17.863 31.1044 19.5289 31.3483C36.9738 33.8975 54.673 33.6235 72.633 30.5397"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
              <path
                d="M19.5256 31.345L25.9215 18.3181C26.741 16.6509 28.437 15.5952 30.2969 15.5952H55.8304C57.6535 15.5952 59.326 16.6108 60.1623 18.2313L66.9262 31.3516"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
              <path
                d="M31.5748 45.9054H54.8603"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
              <path
                d="M19.4754 47.2218C22.2798 47.2218 24.5533 44.9511 24.5533 42.1501C24.5533 39.349 22.2798 37.0783 19.4754 37.0783C16.6709 37.0783 14.3975 39.349 14.3975 42.1501C14.3975 44.9511 16.6709 47.2218 19.4754 47.2218Z"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
              <path
                d="M14.3975 42.1501V49.9147C14.3975 52.0998 16.1704 53.8706 18.3581 53.8706H43.8548"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
              <path
                d="M28.8853 53.8538V60.0615C28.8853 60.8066 28.2832 61.408 27.5372 61.408H20.5124C19.7664 61.408 19.1643 60.8066 19.1643 60.0615V53.5565"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
              <path
                d="M67.709 47.2218C70.5134 47.2218 72.7869 44.9511 72.7869 42.1501C72.7869 39.349 70.5134 37.0783 67.709 37.0783C64.9045 37.0783 62.631 39.349 62.631 42.1501C62.631 44.9511 64.9045 47.2218 67.709 47.2218Z"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
              <path
                d="M72.7869 42.1501V49.9147C72.7869 52.0998 71.014 53.8706 68.8262 53.8706H43.3296"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
              <path
                d="M58.2991 53.8538V60.0615C58.2991 60.8066 58.9012 61.408 59.6472 61.408H66.672C67.4179 61.408 68.02 60.8066 68.02 60.0615V53.5565"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
            </svg>
          </div>
          <div class="">
            <p class="w-full text-center text-realBlack md:text-base text-xs">
              Pre-Order
            </p>
          </div>
        </div>
      </a>
      <div class="w-[1px] self-center h-[70%] bg-black md:block hidden"></div>
      <a
        class="md:py-8 block md:pr-0 pr-2 md:pt-8 pt-2 md:w-[25%] w-1/2 border-black md:border-none border-r"
        href="https://forms.office.com/r/aFyGbMdsSx"
        target="_blank"
      >
        <div
          class="flex flex-col gap-y-1 md:gap-y-5 items-center justify-center h-full"
        >
          <div
            class="w-9 h-9 md:h-auto md:block flex items-center justify-center md:w-auto overflow-hidden"
          >
            <svg
              class="w-full md:w-auto"
              width="82"
              height="75"
              viewBox="0 0 82 75"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.9451 65.9205C56.7281 65.9205 69.5228 53.157 69.5228 37.4124C69.5228 21.6678 56.7281 8.9043 40.9451 8.9043C25.162 8.9043 12.3673 21.6678 12.3673 37.4124C12.3673 53.157 25.162 65.9205 40.9451 65.9205Z"
                stroke="#4D4D4D"
                stroke-width="1.81027"
                stroke-miterlimit="10"
              />
              <path
                d="M64.3786 32.099C64.3587 31.9571 64.3322 31.8118 64.2991 31.6731C61.7204 21.1984 52.243 13.4283 40.9483 13.4283C29.6536 13.4283 19.8022 21.5088 17.4552 32.2972C17.4254 32.4425 17.3989 32.5878 17.3823 32.7331V32.7397C17.3161 33.3044 17.3492 33.8789 17.5148 34.4238C17.7763 35.2758 18.3787 36.2665 19.7856 36.1046C23.0429 35.7348 30.4845 30.088 40.4584 29.9394H41.4151C51.8226 29.7842 58.8338 35.7348 62.0879 36.1046C63.3061 36.2433 63.9251 35.4871 64.2429 34.7045C64.4945 34.0837 64.5573 33.4001 64.4647 32.7364L64.3753 32.0957L64.3786 32.099Z"
                stroke="#4D4D4D"
                stroke-width="1.81027"
                stroke-miterlimit="10"
              />
              <path
                d="M34.7349 60.5874C35.1752 60.703 35.6221 60.4091 35.6817 59.96C36.0922 56.813 34.6853 52.0512 33.9934 50.0335C33.1162 47.4644 31.087 45.3047 29.316 43.3333C28.3626 42.27 26.9855 41.6888 25.5588 41.7548C23.993 41.8275 21.944 41.9464 20.6232 42.0025C19.2097 42.0619 18.6304 43.0955 18.3986 44.0069C18.2166 44.7169 18.2762 45.4665 18.5443 46.1468L18.6668 46.4539C21.4838 53.3489 27.4225 58.6424 34.7316 60.5841L34.7349 60.5874Z"
                stroke="#4D4D4D"
                stroke-width="1.81027"
                stroke-miterlimit="10"
              />
              <path
                d="M63.2829 46.3053C63.465 45.8232 63.5345 45.3047 63.4716 44.7929C63.3392 43.7263 62.8526 42.0751 61.1279 42.0025C59.8071 41.9464 57.758 41.8275 56.1923 41.7548C54.7655 41.6888 53.3884 42.27 52.4351 43.3333C50.6674 45.3047 48.6382 47.4644 47.7576 50.0335C47.0658 52.0545 45.6556 56.8361 46.0694 59.9864C46.129 60.4322 46.5692 60.7261 47.0062 60.6171L47.4167 60.5181C54.6596 58.5037 60.5221 53.1937 63.2796 46.3086L63.2829 46.3053Z"
                stroke="#4D4D4D"
                stroke-width="1.81027"
                stroke-miterlimit="10"
              />
            </svg>
          </div>
          <div class="">
            <p class="w-full text-center text-realBlack md:text-base text-xs">
              Test Drive
            </p>
          </div>
        </div>
      </a>
      <div class="w-[1px] self-center h-[70%] bg-black md:block hidden"></div>
      <a
        class="md:py-8 block md:pl-0 pl-2 md:pt-8 pt-2 md:w-[25%] w-1/2"
        href="https://forms.office.com/Pages/ResponsePage.aspx?id=ep-KTPwRwk-QmQto9yoZfsbgO-WthXFMk4vBhda4wlhUQURERFRGNk0wNU02VDA5WE9PTUwwM1BMWC4u"
        target="_blank"
      >
        <div
          class="flex flex-col gap-y-1 md:gap-y-5 items-center justify-center h-full"
        >
          <div
            class="w-9 h-9 md:h-auto md:block flex items-center justify-center md:w-auto overflow-hidden"
          >
            <svg
              class="w-full md:w-auto"
              width="80"
              height="72"
              viewBox="0 0 80 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M39.7449 53.2275H23.0173L22.9147 58.933H11.3868V37.3565C11.3868 35.1265 12.3966 32.9889 14.1886 31.6478C18.9203 28.1117 21.8215 23.5338 23.4693 18.2488C24.29 15.6174 26.7007 13.7952 29.4705 13.7793L39.7449 13.7092"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
              <path
                d="M39.5623 53.2275H56.2899L56.3925 58.933H67.9204V37.3565C67.9204 35.1265 66.9106 32.9889 65.1186 31.6478C60.3869 28.1117 57.4856 23.5338 55.8379 18.2488C55.0172 15.6174 52.6065 13.7952 49.8367 13.7793L39.5623 13.7092"
                stroke="#4D4D4D"
                stroke-width="2.13785"
                stroke-miterlimit="10"
              />
              <path
                d="M17.5002 28.4303L14.5284 28.048C13.7174 27.9428 13.1436 27.2101 13.2429 26.4042C13.3423 25.5982 14.0796 25.0216 14.8907 25.114L20.1097 25.7288"
                fill="#4D4D4D"
              />
              <path
                d="M62.1211 28.4303L65.0928 28.048C65.9039 27.9428 66.4777 27.2101 66.3783 26.4042C66.279 25.5982 65.5416 25.0216 64.7306 25.114L59.0564 25.7702"
                fill="#4D4D4D"
              />
              <path
                d="M33.2437 22.8235C36.6002 22.8235 38.8378 25.1108 38.8378 28.5386C38.8378 31.9664 36.6002 34.3174 33.2437 34.3174C29.8873 34.3174 27.6849 31.9982 27.6849 28.5386C27.6849 25.0789 29.9226 22.8235 33.2437 22.8235ZM33.2437 32.6704C35.4172 32.6704 36.8855 30.9884 36.8855 28.5418C36.8855 26.0952 35.4172 24.445 33.2437 24.445C31.0702 24.445 29.634 26.127 29.634 28.5418C29.634 30.9565 31.1023 32.6704 33.2437 32.6704ZM31.8043 46.2286L46.538 23.0783H48.8077L34.074 46.2286H31.8043ZM46.8265 35.0182C50.1509 35.0182 52.3565 37.3374 52.3565 40.7652C52.3565 44.193 50.1509 46.544 46.8265 46.544C43.5022 46.544 41.2325 44.2248 41.2325 40.7652C41.2325 37.3056 43.5022 35.0182 46.8265 35.0182ZM46.8265 44.897C48.968 44.897 50.4362 43.215 50.4362 40.7684C50.4362 38.3218 48.968 36.6398 46.8265 36.6398C44.6851 36.6398 43.1848 38.3536 43.1848 40.7684C43.1848 43.1831 44.6851 44.897 46.8265 44.897Z"
                fill="#4D4D4D"
              />
            </svg>
          </div>
          <div class="">
            <p class="w-full text-center text-realBlack md:text-base text-xs">
              EV Financing
            </p>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.shad {
  box-shadow: 7.54px 16.03px 37.43px -15.09px #00000040;
}
a {
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: transform 3s ease-out;
  &:before {
    transition: all 0.5s ease-out;
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: #4f4f4f4d;
    top: 0;
    left: 50%;
    z-index: -1;
  }
  // &::after {
  //   content: "";
  //   @apply absolute top-0 left-0 w-full h-full;
  //   z-index: -2;
  // }
  &:hover {
    &::before {
      left: 0;
      border-radius: 0;
      width: 100%;
    }
  }
}
</style>