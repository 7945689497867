<template>
  <div class="w-full">
    <div class="block w-full">
      <div class="flex items-center justify-start gap-4">
        <router-link :to="{ name: 'panther' }" class="block w-1/2">
          <img src="@/assets/images/nav/panther.png" alt="" class="w-full" />
        </router-link>
        <div>
          <p
            class="w-full text-base font-medium text-left capitalize font-plus"
          >
            Panther
          </p>
          <div class="flex items-start justify-start gap-3 mt-2">
            <router-link
              :to="{ name: 'panther' }"
              class="text-xs underline text-grey font-plus"
              >Explore</router-link
            >
            <router-link
              :to="{ name: 'pantherOrderNow' }"
              class="text-xs underline text-grey font-plus"
              >Order</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full">
      <div class="flex items-center justify-start gap-4">
        <router-link :to="{ name: 'savannah' }" class="block w-1/2">
          <img src="@/assets/images/nav/sav.png" alt="" class="w-full" />
        </router-link>
        <div>
          <p
            class="w-full text-base font-medium text-left capitalize font-plus"
          >
            Savannah
          </p>
          <div class="flex items-start justify-start gap-3 mt-2">
            <router-link
              :to="{ name: 'savannah' }"
              class="text-xs underline text-grey font-plus"
            >
              Explore
            </router-link>
            <router-link
              :to="{ name: 'order-savannah' }"
              class="text-xs underline text-grey font-plus"
            >
              Order
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="flex items-center justify-start gap-4">
        <router-link :to="{ name: 'sill' }" class="block w-1/2">
          <img src="@/assets/images/nav/sill.png" alt="" class="w-full" />
        </router-link>
        <div>
          <p
            class="w-full text-base font-medium text-left capitalize font-plus"
          >
            Sill
          </p>
          <div class="flex items-start justify-start gap-3 mt-2">
            <router-link
              :to="{ name: 'sill' }"
              class="text-xs underline text-grey font-plus"
            >
              Explore
            </router-link>
            <router-link
              :to="{ name: 'order-sill' }"
              class="text-xs underline text-grey font-plus"
            >
              Order
            </router-link>
            <!-- <router-link
                    :to="{ name: 'vehicles' }"
                    class="text-xs underline text-grey font-plus"
                    >Order Now</router-link
                  > -->
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="flex items-center justify-start gap-4">
        <router-link :to="{ name: 'caspian' }" class="block w-1/2">
          <img src="@/assets/images/nav/cass.png" alt="" class="w-full" />
        </router-link>
        <div>
          <p
            class="w-full text-base font-medium text-left capitalize font-plus"
          >
            Casipan
          </p>
          <div class="flex items-start justify-start gap-3 mt-2">
            <router-link
              :to="{ name: 'caspian' }"
              class="text-xs underline text-grey font-plus"
            >
              Explore
            </router-link>
            <router-link
              :to="{ name: 'order-caspian' }"
              class="text-xs underline text-grey font-plus"
            >
              Order
            </router-link>
            <!-- <router-link
                    :to="{ name: 'vehicles' }"
                    class="text-xs underline text-grey font-plus"
                    >Order Now</router-link
                  > -->
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="flex items-center justify-start gap-4">
        <router-link :to="{ name: 'sable' }" class="block w-1/2">
          <img src="@/assets/images/nav/sable.png" alt="" class="w-full" />
        </router-link>
        <div>
          <p
            class="w-full text-base font-medium text-left capitalize font-plus"
          >
            Sable
          </p>
          <div class="flex items-start justify-start gap-3 mt-2">
            <router-link
              :to="{ name: 'sable' }"
              class="text-xs underline text-grey font-plus"
            >
              Explore
            </router-link>
            <router-link
              :to="{ name: 'order-sable' }"
              class="text-xs underline text-grey font-plus"
            >
              Order
            </router-link>
            <!-- <router-link
                    :to="{ name: 'vehicles' }"
                    class="text-xs underline text-grey font-plus"
                    >Order Now</router-link
                  > -->
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-start gap-4">
      <router-link :to="{ name: 'javan' }" class="block w-1/2">
        <img src="@/assets/images/nav/javnav.png" alt="" class="w-full" />
      </router-link>
      <div>
        <p class="w-full text-base font-medium text-left capitalize font-plus">
          Javan
        </p>
        <div class="flex items-start justify-start gap-3 mt-2">
          <router-link
            :to="{ name: 'javan' }"
            class="text-xs underline text-grey font-plus"
            >Explore</router-link
          >
          <router-link
            :to="{ name: 'order-javan' }"
            class="text-xs underline text-grey font-plus"
            >Order</router-link
          >
        </div>
      </div>
    </div>

    <!-- <div class="flex items-end justify-start mt-8">
      <a
        href="/assets/doc/Qoray Brochure.pdf"
        download
        class="text-sm font-bold underline text-realBlack font-inter"
        >View All</a
      >
    </div> -->
    <div
      class="flex md:flex-col flex-row flex-wrap items-start gap-6 md:pt-3 pt-12 md:pl-12 md:pb-0 pb-12"
    >
      <!-- <router-link
        :to="{ name: 'home' }"
        class="block text-sm font-bold text-left text-realBlack font-inter"
        >Sedan</router-link
      >
      <span
        :to="{ name: 'home' }"
        class="cursor-not-allowed block text-sm font-bold opacity-40 text-left text-realBlack font-inter"
        >Three-wheelers <sub>Coming Soon</sub>
      </span>
      <span
        :to="{ name: 'home' }"
        class="cursor-not-allowed block text-sm opacity-40 font-bold text-left text-realBlack font-inter"
        >Two-wheelers <sub>Coming Soon</sub>
      </span> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>