<template>
  <div class="w-full">
    <router-link class="block w-full" :to="{ name: 'charging' }">
      <div class="w-full flex items-center justify-start gap-4">
        <img class="w-1/2" src="@/assets/images/nav/charge.png" alt="" />
        <div>
          <p
            class="w-full mt-2 text-base font-medium text-left capitalize font-plus"
          >
            Charge Station
            <!-- Network -->
          </p>
          <router-link
            class="block text-sm font-bold text-left underline text-realBlack font-inter"
            :to="{ name: 'charging' }"
            >View More</router-link
          >
        </div>
      </div>
    </router-link>
    <div class="w-full">
      <div
        class="flex items-center justify-start gap-4 opacity-40 cursor-not-allowed"
      >
        <img class="w-1/2" src="@/assets/images/nav/swap.png" alt="" />
        <div>
          <p
            class="w-full mt-2 text-base font-medium text-left capitalize font-plus"
          >
            Swap Station
            <!-- Network -->
          </p>
          <span
            class="block text-sm font-bold text-left underline text-realBlack font-inter"
            :to="{ name: 'swap' }"
            >Coming Soon</span
          >
        </div>
      </div>
    </div>
    <div class="w-full">
      <div
        class="flex items-center justify-start gap-4 opacity-40 cursor-not-allowed"
      >
        <img class="w-1/2" src="@/assets/images/nav/home.png" alt="" />
        <div>
          <p
            class="w-full mt-2 text-base font-medium text-left capitalize font-plus"
          >
            Home Chargers
          </p>

          <span
            class="block text-sm font-bold text-left underline text-realBlack font-inter"
            :to="{ name: 'home' }"
            >Coming Soon</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>