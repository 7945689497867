<template>
  <div
    class="fixed w-full h-screen min-h-screen max-h-screen *:font-inter bg-black bg-opacity-80 backdrop-blur-[2px] left-0 right-0 top-0 bottom-0 z-[9999999999999999]"
    v-if="modalOpen"
  >
    <div class="relative w-full h-full">
      <div class="absolute right-0 top-0 min-w-[300px] max-w-[350px]">
        <div class="bg-red text-white flex items-start justify-between p-6">
          <div>
            <h2 class="font-semibold font-inter md:text-2xl text-xl">
              {{ title }}
            </h2>
            <p class="italic font-light text-sm">{{ sub }}</p>
          </div>
          <div class="min-w-[30px] cursor-pointer" @click="closeModal">
            <img src="@/assets/images/overlay.png" class="w-full" alt="Close" />
          </div>
        </div>
        <div class="w-full pb-6 bg-black px-6 max-h-[75vh] overflow-y-auto">
          <order v-if="activeModal === 'order'" />
          <contact v-if="activeModal === 'contact'" />
          <partner v-if="activeModal === 'partner'" />
          <schedule v-if="activeModal === 'schedule'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from "./Forms/Contact.vue";
import Order from "./Forms/Order.vue";
import Partner from "./Forms/Partner.vue";
import Schedule from "./Forms/Schedule.vue";

export default {
  // data() {
  //   return { activeModal: "order" };
  // },
  computed: {
    title() {
      return this.$store.state.ttl;
    },
    sub() {
      return this.$store.state.sub;
    },
    activeModal() {
      return this.$store.state.active;
    },
    modalOpen() {
      return this.$store.state.modalOpen;
    },
  },
  components: { Order, Contact, Partner, Schedule },
  methods: {
    closeModal() {
      this.$store.dispatch("closeModal");
    },
  },
};
</script>

<style>
</style>