import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/typeFaces/inter.css"
import "./assets/css/typeFaces/helvetica.css"
import "./assets/css/typeFaces/plusJakartaSans.css"
import "./assets/css/typeFaces/verdana.css"
import "./assets/css/typeFaces/nunito-sans.css"
import "./assets/css/typeFaces/clash.css"
import "./assets/css/typeFaces/cocon.css"
import "./assets/css/typeFaces/belgiano.css"
import "./assets/css/tailwind.css"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
