<template>
  <div class="w-full">
    <slot />
    <div
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen"
    >
      <div
        class="bg-[#1B1B1B] absolute load1 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div
        class="bg-[#1B1B1B] absolute load2 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div class="img1">
        <img
          src="@/assets/images/miniLogo.png"
          class="z-[1] zoom img1"
          alt="Logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>