import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Vehicles from "../views/Vehicles.vue";
import Javan from "../views/vehicles/Javan.vue";
import Sill from "../views/vehicles/Sill.vue";
import Panther from "../views/vehicles/Panther.vue";
import Savannah from "../views/vehicles/Savannah.vue";
import Sable from "../views/vehicles/Sable.vue";
import Caspian from "../views/vehicles/Caspian.vue";
import pantherOrderNow from "../views/vehicles/orderNow/Panther.vue";
import savannahOrder from "../views/vehicles/orderNow/Savannah.vue";
import sillOrder from "../views/vehicles/orderNow/Sill.vue";
import sableOrder from "../views/vehicles/orderNow/Sable.vue";
import caspianOrder from "../views/vehicles/orderNow/Caspian.vue";
import javanOrder from "../views/vehicles/orderNow/Javan.vue";
import Charging from "../views/ChargingStation.vue";
import Swap from "../views/SwapStation.vue";
import Impact from "../views/SustainabilityImpact.vue";
import Tour from "../views/Tour.vue";
import FAQs from "../views/FAQs.vue";
import Privacy from "../views/Privacy.vue";
import Terms from "../views/Terms.vue";
import SterlingHuman from '../views/SterlingHuman.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      // bg: 'dark',
      // nbg: 'dark',
    },
  },
  {
    path: "/vehicles",
    name: "vehicles",
    component: Vehicles,
    meta: {
      bg: "light",
    },
  },
  {
    path: '/sterling-human',
    name: 'sterling-human',
    component: SterlingHuman,
    meta: {
      noNav: true,
      noFoot: true
    }
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: Privacy,
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
  },
  {
    path: "/javan",
    name: "javan",
    component: Javan,
    meta: {
      bg: 'dark',
      logo: "white"
      // pos: "relMob"
    },
  },
  {
    path: "/sill",
    name: "sill",
    component: Sill,
    meta: {
      // bg: 'dark',
      // logo: "white"
      // pos: "relMob"
    },
  },
  {
    path: "/panther",
    name: "panther",
    component: Panther,
    meta: {
      // bg: 'dark',
      // pos: "relMob"
    },
  },
  {
    path: "/savannah",
    name: "savannah",
    component: Savannah,
    meta: {
      blur: true
      // bg: 'dark',
      // logo: "white"
      // pos: "relMob"
    },
  },
  {
    path: "/sable",
    name: "sable",
    component: Sable,
    meta: {
      // blur: true
      // bg: 'dark',
      // logo: "white"
      // pos: "relMob"
    },
  },
  {
    path: "/caspian",
    name: "caspian",
    component: Caspian,
    meta: {
      // blur: true
      // bg: 'dark',
      // logo: "white"
      // pos: "relMob"
    },
  },
  {
    path: "/panther/order",
    name: "pantherOrderNow",
    component: pantherOrderNow,
  },
  {
    path: "/savannah/order",
    name: "order-savannah",
    component: savannahOrder,
  },
  {
    path: "/sill/order",
    name: "order-sill",
    component: sillOrder,
  },
  {
    path: "/sable/order",
    name: "order-sable",
    component: sableOrder,
  },
  {
    path: "/caspian/order",
    name: "order-caspian",
    component: caspianOrder,
  },
  {
    path: "/javan/order",
    name: "order-javan",
    component: javanOrder,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQs,
    meta: {
      bg: 'light',
      pos: 'rel',
      logo: "grey"
    }
  },
  {
    path: "/charging-stations",
    name: "charging",
    component: Charging,
  },
  {
    path: "/swap-station",
    name: "swap",
    component: Swap,
  },
  {
    path: "/sustainabity-impact",
    name: "impact",
    component: Impact,
  },
  {
    path: "/tour",
    name: "tour",
    component: Tour,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    // component: About
  },
  {
    path: "/demo",
    name: "demo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DemoScroll.vue"),
    // component: About
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  //   // console.log("home");
  //   // if (from.name === 'home') {
  //   //   window.location.reload()
  //   // }
  window.scrollTo(0, 0);
});

export default router;
