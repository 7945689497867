import { valid } from 'joi';
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    success: false,
    successPayload: {
      title: '',
      top: '',
      bottom: ''
    },
    blurNav: false,
    modalOpen: false,
    selectedVehicle: '',
    ttl: '',
    sub: '',
    active: '',
    mail_templates: { ...JSON.parse(process.env.VUE_APP_MAIL_TEMPLATES) },
    navHeight: 1
  },
  getters: {
    mailTemplate: state => form => {
      return state.mail_templates[form] || null;
    }
  },
  mutations: {
    setBlur(state, value) {
      state.blurNav = value;
    },
    setModal(state, value) {
      state.modalOpen = value;
    },
    setVehicle(state, value) {
      state.selectedVehicle = value;
    },
    setTtl(state, value) {
      state.ttl = value;
    },
    setSub(state, value) {
      state.sub = value;
    },
    setActive(state, value) {
      state.active = value;
    },
    setSuccess(state, value) {
      state.success = value;
    },
    setSuccessP(state, value) {
      state.successPayload = value;
    },
    SetNavHeight(state, value) {
      state.navHeight = value;
    },
  },
  actions: {
    async sendMail({ getters }, { form, payload }) {
      // console.log(process.env.VUE_APP_MAIL_TEMPLATES);
      // return
      var template = getters.mailTemplate(form);
      // console.log(template);
      // console.log(payload);
      var body = {
        service_id: form === "panther_order" ? process.env.VUE_APP_SALES_SERVICE_ID : process.env.VUE_APP_SERVICE_ID,
        template_id: template,
        user_id: process.env.VUE_APP_PUBLIC_KEY,
        template_params: JSON.parse(JSON.stringify(payload)),
      }
      // console.log(body);
      await fetch(process.env.VUE_APP_MAIL_URL, {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      })
        // .then((res) => res.json())
        .then((resp) => {
          return true;
        }).catch(err => {
          console.log(err);
          return false
        })
    },
    orderNow({ commit }, veh) {
      commit('setVehicle', veh);
      commit('setActive', 'order');
      commit('setTtl', `Order a ${veh} Now`);
      commit('setSub', "Requires a minimum of 60 days to deliver");
      commit('setModal', true);
    },
    scheduleNow({ commit }) {
      commit('setVehicle', '');
      commit('setActive', 'schedule');
      commit('setTtl', "Shedule Charge");
      commit('setSub', "");
      commit('setModal', true);
    },
    contactUs({ commit }) {
      commit('setVehicle', '');
      commit('setActive', 'contact');
      commit('setTtl', "Contact Us");
      commit('setSub', "");
      commit('setModal', true);
    },
    partnerNow({ commit }) {
      commit('setVehicle', '');
      commit('setActive', 'partner');
      commit('setTtl', "Become a Charge Partner");
      commit('setSub', "");
      commit('setModal', true);
    },
    closeModal({ commit }) {
      commit('setVehicle', '');
      commit('setActive', '');
      commit('setTtl', "");
      commit('setSub', "");
      commit('setModal', false);
    },
    modalSuccess(context, payload) {
      context.commit('setSuccessP', payload);
      context.dispatch('closeModal');
      context.commit('setSuccess', true);
    },
    closeSuccess(context) {
      context.commit('setSuccess', false)
      context.commit('setSuccessP', {
        title: '',
        top: '',
        bottom: ''
      });
      context.dispatch('closeModal');
    }
  },
  modules: {
  }
})
